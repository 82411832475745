import { match } from 'ts-pattern';

export type NumberFormatType = 'price' | 'amount' | 'qty' | 'percentage' | 'duration';

export function formatNumber(value: number, format: NumberFormatType, digits?: number): string {
  return match(format)
    .with('price', () => asPrice(value))
    .with('amount', () => asAmount(value, digits))
    .with('qty', () => asQty(value))
    .with('percentage', () => asPercentage(value))
    .with('duration', () => asDuration(value))
    .exhaustive();
}

// be careful NOT to round values here
function fmtAndTruncate(num: number, digits: number, trimZeros: boolean = true): string {
  /**
   * all this fuss to avoid floating number issues...
   * the previous method was : truncated = Math.floor(num * 10 ** digits) / 10 ** digits;
   * the problem is with floating numbers
   * for exemple 0.0024 * 10**4 is 23.999999999999996 in js
   * which would result in 0.0023  for 4 digits which is not the real value
   * so we use a string representation of the number to achieve truncation without floating number issues
   */
  const numStr = num.toString();
  let decimalPlaces = 0;
  if (numStr.includes('.')) {
    decimalPlaces = numStr.split('.')[1].length;
  }

  const intNumber = Number(numStr.replace('.', ''));
  const diff = decimalPlaces - digits;
  const truncated = Math.trunc(intNumber / 10 ** diff) / 10 ** digits;
  const fmt = trimZeros
    ? Intl.NumberFormat('en-US', { maximumFractionDigits: digits + 1 })
    : Intl.NumberFormat('en-US', { maximumFractionDigits: digits + 1, minimumFractionDigits: digits });
  return fmt.format(truncated);
}

function asPrice(value: number): string {
  if (value < 0.001) {
    const arr = value.toExponential().split('e');
    if (arr && arr.length === 2) {
      const [rawMantissa, rawExponent] = arr;
      const digits = parseInt(rawMantissa.replace(/\D/, '').slice(0, 4).replace(/0+$/, ''), 10);
      const exp = parseInt(rawExponent.replace(/\D/, ''), 10);
      if (exp >= 4) {
        const subExp = (exp - 2).toString().replaceAll(/\d/g, m => '₀₁₂₃₄₅₆₇₈₉'[parseInt(m, 10)]);
        return `0.0${subExp}${digits}`;
      }
    }
    return '0';
  }
  if (value < 0.01) {
    return fmtAndTruncate(value, 6);
  }
  if (value < 0.1) {
    return fmtAndTruncate(value, 5);
  }
  if (value < 1) {
    return fmtAndTruncate(value, 4);
  }
  if (value < 10) {
    return fmtAndTruncate(value, 2, false);
  }
  if (value < 100_000) {
    return fmtAndTruncate(value, 2);
  }
  return fmtAndTruncate(value, 1);
}

function asAmount(value: number, digits?: number): string {
  if (value <= 0.005) {
    return '0.00';
  }
  if (value <= 0.01) {
    return '0.01';
  }
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits !== undefined ? digits : 2,
    maximumFractionDigits: digits !== undefined ? digits : 2,
  }).format(value);
}

function asQty(value: number): string {
  if (value < 0.00000001) {
    const arr = value.toExponential().split('e');
    if (arr && arr.length === 2) {
      const [rawMantissa, rawExponent] = arr;
      const digits = parseInt(rawMantissa.replace(/\D/, '').slice(0, 4).replace(/0+$/, ''), 10);
      const exp = parseInt(rawExponent.replace(/\D/, ''), 10);
      if (exp >= 4) {
        const subExp = (exp - 2).toString().replaceAll(/\d/g, m => '₀₁₂₃₄₅₆₇₈₉'[parseInt(m, 10)]);
        return `0.0${subExp}${digits}`;
      }
    }
    return '0';
  }
  if (value < 1) {
    return fmtAndTruncate(value, 8);
  }
  if (value < 10) {
    return Intl.NumberFormat('en-US', { maximumFractionDigits: 5 }).format(value);
  }
  if (value < 100) {
    return Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(value);
  }
  if (value < 100_000) {
    return Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  }
  if (value < 1e6) {
    const res = Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / 1e3);
    return `${res}K`;
  }
  if (value < 1e9) {
    const res = Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / 1e6);
    return `${res}M`;
  }
  if (value < 1e12) {
    const res = Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / 1e9);
    return `${res}B`;
  }
  if (value < 1e15) {
    const res = Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / 1e12);
    return `${res}T`;
  }
  const res = Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / 1e15);
  return `${res}Q`;
}

function asPercentage(value: number): string {
  return Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(value / 100);
}

export function cleanNumericString(inputString: string): string {
  // Remove non-numeric characters
  const cleanedString = inputString.replace(/[^0-9.,]/g, '');

  // Replace any commas with periods
  const stringWithPeriods = cleanedString.replace(/,/g, '.');

  // Remove any additional periods (if they were not replaced earlier)
  const finalString = stringWithPeriods.replace(/\.(?=.*\.)/g, '');

  return finalString;
}

function asDuration(value: number): string {
  if (value < 60) {
    return `${value} s`;
  }

  if (value < 60 * 60) {
    const minutes = Math.floor(value / 60);
    return `${minutes} min`;
  }

  if (value < 60 * 60 * 24) {
    const hours = Math.floor(value / (60 * 60));
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  if (value < 60 * 60 * 24 * 7) {
    const days = Math.floor(value / (60 * 60 * 24));
    return `${days} ${days === 1 ? 'day' : 'days'}`;
  }

  if (value < 60 * 60 * 24 * 30) {
    const weeks = Math.floor(value / (60 * 60 * 24 * 7));
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
  }

  if (value < 60 * 60 * 24 * 365) {
    const months = Math.floor(value / (60 * 60 * 24 * 30));
    return `${months} ${months === 1 ? 'month' : 'months'}`;
  }

  const years = Math.floor(value / (60 * 60 * 24 * 365));
  return `${years} ${years === 1 ? 'year' : 'years'}`;
}
