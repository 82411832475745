import cls from 'classnames';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import BudgetInput from '@components/NumberInputs/BudgetInput';
import { ApproximateIcon, SpotIcon, WalletIcon, WarningIcon } from '@ui-kit/Icons';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Chip } from '@ui-kit/molecules/Chip';
import { SimpleEntry as Entry } from '@ui-kit/organisms/Entry';
import { AssetLogo } from '@ui-kit/organisms/TokenLogo';
import { Budget, BudgetWithQuote, ICoin, Loadable, Loader, OneOf, isCoin, toNumber, Cls, truncBudget } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

type hintValue = OneOf<
[
  { usdValue: number },
  { message: string },
  { errorMessage: string },
  { marketRate: { value: number; source: ICoin; target: ICoin; translationKey?: string } },
]
>;

export type BudgetInputHint = hintValue | hintValue[];

export type BudgetInputEntryProps = {
  budget: Loadable<BudgetWithQuote<ICoin>>;
  onChange?: (budget: Budget<ICoin>, isMax?: boolean) => void;
  maxBudget?: Loadable<bigint>;
  hint?: Loadable<BudgetInputHint>;
  disabled?: boolean;
  disabledSelectToken?: boolean;
  interactive?: boolean;
  onSelectCoin?: VoidFunction;
  label?: string;
  bottomClassName?: string;
  budgetInputClassName?: string;
  type?: 'wallet' | 'spot';
  autofocus?: boolean;
  onDelete?: (budget: Budget<ICoin>) => void;
  customAction?: React.ReactNode;
  skeletonClassName?: string;
  hasWarning?: boolean;
} & Cls;

const BudgetInputEntry = forwardRef(
  (
    {
      budget,
      onChange,
      maxBudget,
      hint,
      interactive = true,
      disabled,
      disabledSelectToken,
      onSelectCoin,
      label,
      className,
      bottomClassName,
      budgetInputClassName,
      type = 'wallet',
      onDelete,
      customAction,
      autofocus = true,
      skeletonClassName,
      hasWarning,
    }: BudgetInputEntryProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const budget$ = Loader.useWrap(budget);
    const noFlickeringBudget$ = budget$.noFlickering();
    const hintArray$ = Loader.useWrap(hint).map(h => (Array.isArray(h) ? h : [h]));

    const maxBudget$ = Loader.useWrap(maxBudget).noFlickering();
    const hintRender = hintArray$
      .noFlickering()
      .match.loadingOrSkipped(() => <Skeleton className="w-[30%] h-4" />)
      .error(() => 'something went wrong')
      .ok(_hintArray => {
        return (
          <span className="flex flex-row gap-1">
            {_hintArray.map(_hint =>
              match(_hint)
                .with(undefined, () => <span key="no-hint" />)
                .when(
                  h => 'usdValue' in h,
                  ({ usdValue }) => (
                    <span className="text-font-variant flex gap-1" key="usd-value">
                      <ApproximateIcon width={12} height={12} className="my-auto inline" />
                      $&nbsp;{formatNumber(usdValue || 0, 'price')}
                    </span>
                  ),
                )
                .when(
                  h => 'message' in h,
                  ({ message }) => (
                    <span className="text-font-variant" key="message">
                      {message}
                    </span>
                  ),
                )
                .when(
                  h => 'errorMessage' in h,
                  ({ errorMessage }) => (
                    <span className="text-danger select-none" key="error">
                      {errorMessage}
                    </span>
                  ),
                )
                .when(
                  h => 'marketRate' in h,
                  ({ marketRate }) => {
                    if (!marketRate) return null;
                    const { value, source, target, translationKey } = marketRate;
                    if (!value || !source || !target) return null;
                    return (
                      <span className="text-font-variant select-none" key="market-rate">
                        {t((translationKey as any) || 'Transactions.BudgetInput.marketRate', {
                          rate: '' + formatNumber(value, 'qty'),
                          sourceSymbol: source.symbol,
                          targetSymbol: target.symbol,
                        })}
                      </span>
                    );
                  },
                )
                .run(),
            )}
          </span>
        );
      });

    const errorClassName = hintArray$.match
      .notOk(() => '')
      .ok(_hintArray => (_hintArray.some(h => !!h?.errorMessage) ? 'border !border-danger caret-outline' : ''));

    return Loader.array([noFlickeringBudget$, maxBudget$] as const)
      .match.loadingOrSkipped(() => <Skeleton className={cls('w-full h-[80px]', skeletonClassName)} />)
      .error(() => null)
      .ok(([_budget, _maxBudget]) => (
        <Skeleton asOverlay={budget$.isLoading} className={skeletonClassName}>
          <Entry
            isCard
            className={cls(className, errorClassName, '!bg-surface-muted !rounded-2xl', {
              '!cursor-default': !interactive,
              '!border-outline caret-outline': isFocused && !errorClassName,
            })}
            content={{
              top: (
                <div className="flex justify-between items-center">
                  <div>
                    {label && <div className="text-sm text-font-variant h-4">{label}</div>}
                    <BudgetInput
                      autofocus={autofocus}
                      value={_budget}
                      onChange={b => isCoin(b.token) && onChange?.(b as Budget<ICoin>)}
                      disabled={!interactive || disabled}
                      ref={ref}
                      className={cls(budgetInputClassName, {
                        'text-font-disabled': budget$.isLoading || disabled,
                        '!caret-danger': isFocused && errorClassName,
                      })}
                      onFocus={() => {
                        setIsFocused(true);
                      }}
                      onBlur={() => {
                        setIsFocused(false);
                      }}
                    />
                  </div>
                  <Chip
                    label={_budget.token.symbol}
                    leadingItem={
                      _budget.token.id
                        ? {
                          Icon: (
                            <AssetLogo
                              asset={_budget.token}
                              withBadge={false}
                              className={cls('w-4 h-4', { 'filter grayscale': budget$.isLoading })}
                            />
                          ),
                        }
                        : undefined
                    }
                    onClick={!budget$.isLoading && !disabledSelectToken ? onSelectCoin : undefined}
                    state={budget$.isLoading ? 'disabled' : 'default'}
                    className="h-8 min-w-[56px]"
                    trailingItem={hasWarning ? { Icon: <WarningIcon className="text-warning h-6 w-6" /> } : undefined}
                  />
                </div>
              ),
              bottom: (
                <div className={cls('flex justify-between mt-2 text-sm', bottomClassName)} data-cy="hint">
                  {hintRender}
                  {customAction || (
                    <span className="flex gap-1 items-center" data-cy="max-budget">
                      {type === 'wallet' ? <WalletIcon height={12} width={12} /> : <SpotIcon height={12} width={12} />}
                      {formatNumber(toNumber(_maxBudget || 0n, _budget.token.decimals), 'qty')}
                      {interactive && (
                        <>
                          <span
                            className="text-accent select-none"
                            role="button"
                            onClick={() => {
                              if (disabled) return;
                              onChange?.(
                                toNumber(_maxBudget && _maxBudget > 0n ? _maxBudget : 0n, _budget.token.decimals) <
                                  0.0000001
                                  ? {
                                    ..._budget,
                                    amtBase: _maxBudget && _maxBudget > 0n ? _maxBudget : 0n,
                                  }
                                  : truncBudget(
                                    {
                                      ..._budget,
                                      amtBase: _maxBudget && _maxBudget > 0n ? _maxBudget : 0n,
                                    },
                                    8,
                                  ),
                                true,
                              );
                            }}
                            tabIndex={0}
                          >
                            {t('Transactions.BudgetInput.max')}
                          </span>
                          {onDelete && (
                            <span className="select-none">
                              |&nbsp;
                              <span
                                className="text-danger"
                                onClick={() => onDelete(_budget)}
                                role="button"
                                tabIndex={0}
                              >
                                {t('Transactions.BudgetInput.delete')}
                              </span>
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  )}
                </div>
              ),
            }}
          />
        </Skeleton>
      ));
  },
);

BudgetInputEntry.displayName = 'BudgetInputEntry';

export default BudgetInputEntry;
