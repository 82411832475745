import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { Button, ButtonVariant } from '@ui-kit/atoms/Button';
import { ISingleTxReviewContent, TxSpeed } from '@ui-kit/organisms/SingleTxReviewContent';
import { BudgetWithQuote, Loadable, Loader } from '@utils';

import { UnfairLevel } from '../types';

type SingleTxProps = { hasMultipleTxs?: false; txData: ISingleTxReviewContent };
type ManyTxsProps = {
  hasMultipleTxs: true;
  txDataList: ISingleTxReviewContent[];
  networkFees?: Loadable<BudgetWithQuote>;
  txSpeed: TxSpeed;
  onSelectTxSpeed: VoidFunction;
};
export type SendTxButtonProps = {
  label?: string;
  disabled?: boolean;
  sendTx: Loadable<() => any>;
  dataCy?: string;
  unfairLevel?: UnfairLevel;
  isLoading?: boolean;
  variant?: ButtonVariant | null;
} & (SingleTxProps | ManyTxsProps);

export function SendTxButton({
  label,
  disabled: forceDisabled,
  sendTx,
  dataCy,
  unfairLevel = UnfairLevel.LOW,
  isLoading,
  variant,
  ...oneOrManyTxs
}: SendTxButtonProps) {
  const { t } = useTranslation();

  const disabled = !Loader.useWrap(sendTx).isOk || forceDisabled;

  const isCtaDisabled =
    match(oneOrManyTxs)
      .with(
        { hasMultipleTxs: false } || { hasMultipleTxs: undefined },
        ({ txData: { inputToken, outputToken, fees } }) =>
          Loader.array([inputToken, outputToken, fees] as const)
            .noFlickering()
            .match.loadingOrSkipped(() => true)
            .error(() => true)
            .ok(([_inputToken, _outputToken]) => !_inputToken.amtBase || !_outputToken.amtBase),
      )
      .otherwise(() => false) || disabled;

  return (
    <Button
      disabled={isCtaDisabled}
      isLoading={isLoading}
      label={
        label ||
        match(unfairLevel)
          .with(UnfairLevel.MEDIUM, () => t('Common.confirmAnyway'))
          .with(UnfairLevel.HIGH, () => t('Common.confirmAnyway'))
          .otherwise(() => t('Common.confirm'))
      }
      variant={
        variant ||
        (match(unfairLevel)
          .with(UnfairLevel.MEDIUM, () => 'warning')
          .with(UnfairLevel.HIGH, () => 'danger')
          .otherwise(() => 'accent') as ButtonVariant)
      }
      fullWidth
      size="l"
      onClick={sendTx}
      dataCy={dataCy}
    />
  );
}
