import BudgetInputEmptyEntry from '@transactions/components/ui/BudgetInputEmptyEntry';
import BudgetInputEntry from '@transactions/components/ui/BudgetInputEntry';
import { VerticalProcessLayout } from '@transactions/components/ui/VerticalProcessLayout';
import { getBudgetUSDvalue } from '@transactions/utils';
import { Budget, BudgetWithQuote, ICoin, Loadable, Loader } from '@utils';

export type SwapUIProps = {
  sourceBudget: Loadable<BudgetWithQuote<ICoin> | nil>;
  sourceBalance: Loadable<bigint>;
  onSourceChange: (budget: Budget<ICoin>, isMax?: boolean) => void;
  targetBudget: Loadable<BudgetWithQuote<ICoin> | nil>;
  targetBalance?: Loadable<bigint>;
  price: Loadable<number>;
  onSourceCoinSelect: VoidFunction;
  onTargetCoinSelect: VoidFunction;
  inputRef?: React.RefObject<HTMLInputElement>;
  onSwitchTokens?: VoidFunction;
  sourceInputError?: string;
  isOperationLoading?: boolean;
  isInputTokenUsedInLimitOrders?: Loadable<boolean>;
};

export function SwapUI({
  sourceBudget,
  sourceBalance,
  onSourceChange,
  targetBudget,
  targetBalance,
  price,
  onTargetCoinSelect,
  onSourceCoinSelect,
  inputRef,
  onSwitchTokens,
  sourceInputError,
  isOperationLoading,
  isInputTokenUsedInLimitOrders,
}: SwapUIProps) {
  const sourceBudget$ = Loader.useWrap(sourceBudget);
  const targetBudget$ = Loader.useWrap(targetBudget);
  const isInputTokenUsedInLimitOrders$ = Loader.useWrap(isInputTokenUsedInLimitOrders);

  const price$ = Loader.useWrap(price)
    .mapNotLoaded('error', () => null)
    .mapNotLoaded('skipped', () => null);
  const marketRate$ = Loader.array([sourceBudget$, targetBudget$, price$] as const).map(
    ([_sourceBudget, _targetBudget, _price]) => {
      if (!_targetBudget) return Loader.loading;
      return _sourceBudget
        ? {
          marketRate: {
            value: _price || _sourceBudget.quote / _targetBudget.quote,
            source: _sourceBudget.token,
            target: _targetBudget.token,
          },
        }
        : Loader.skipped;
    },
  );
  const sourceHint$ = sourceBudget$.map([sourceInputError], _sourceBudget => {
    if (sourceInputError) return { errorMessage: sourceInputError };
    if (!_sourceBudget) return Loader.loading;
    return {
      usdValue: getBudgetUSDvalue(_sourceBudget),
    };
  });

  const sourceBudgetInput = Loader.array([sourceBudget$, isInputTokenUsedInLimitOrders$] as const)
    .match.skipped(() => <BudgetInputEmptyEntry onSelect={onSourceCoinSelect} />)
    .loading(() => <BudgetInputEntry budget={Loader.loading} hint={sourceHint$} type="spot" />)
    .error(() => null)
    .ok(([_sourceBudget, _isInputTokenUsedInLimitOrders]) =>
      _sourceBudget ? (
        <BudgetInputEntry
          budget={_sourceBudget}
          onChange={onSourceChange}
          maxBudget={sourceBalance}
          hint={sourceHint$}
          onSelectCoin={onSourceCoinSelect}
          ref={inputRef}
          type="spot"
          disabled={isOperationLoading}
          hasWarning={_isInputTokenUsedInLimitOrders}
        />
      ) : (
        <BudgetInputEmptyEntry onSelect={onSourceCoinSelect} />
      ),
    );
  const targetBudgetInput = targetBudget$.match
    .skipped(() => <BudgetInputEmptyEntry onSelect={onTargetCoinSelect} />)
    .loading(() => (
      <BudgetInputEntry
        budget={Loader.loading}
        hint={marketRate$}
        maxBudget={targetBalance}
        type="spot"
        interactive={false}
      />
    ))
    .error(() => null)
    .ok(_targetBudget =>
      _targetBudget ? (
        <BudgetInputEntry
          budget={_targetBudget}
          maxBudget={targetBalance}
          hint={marketRate$}
          interactive={false}
          onSelectCoin={onTargetCoinSelect}
          ref={inputRef}
          type="spot"
        />
      ) : (
        <BudgetInputEmptyEntry onSelect={onTargetCoinSelect} />
      ),
    );

  return (
    <div>
      <VerticalProcessLayout
        onArrowClick={onSwitchTokens}
        steps={[
          {
            key: 'in',
            item: sourceBudgetInput,
          },
          {
            key: 'out',
            item: targetBudgetInput,
          },
        ]}
      />
    </div>
  );
}
