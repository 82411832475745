import { useState, useRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { IEntryHistory } from '@components/entries/EntryHistory';
import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { GetAllPendingOrders, GetTransactions_transactions_results as Transaction, getAllLimitOrders } from '@gql';
import { Tabs } from '@ui-kit/organisms/Tabs';
import { Loadable, Loader, groupBy } from '@utils';
import { TransactionState } from '@utils/uiKitUtils/statusTransactionUtils';
import { theme } from 'theme';

import { fromLimitOrderToTransaction, fromPendingOrderToTransaction } from './Transactions.utils';
import { LoadingList, ListErrorEntry, TransactionManagerList as List, EmptyList } from './TransactionsManagerList';

export type TransactionManagerTab = TransactionState.Completed | TransactionState.Pending;

export type TransactionManagerUIProps = {
  isAuthed?: Loadable<boolean>;
  transactions: Loadable<Transaction[]>;
  moreLoading?: boolean;
  Filters: ReactElement;
  onListRetry: VoidFunction;
  onSelectTransaction?: (txId: TxId) => void;
  limitOrders?: Loadable<getAllLimitOrders>;
  pendingOrders?: Loadable<GetAllPendingOrders>;
  onGoBack?: () => void;
  defaultTab?: TransactionManagerTab;
};
export const SCROLL_ID = 'scrollable-transactions-list';

const groupByDate = (x: Transaction) => x.txTimestamp?.toString().split('T')[0] ?? '';

export function TransactionManagerUI({
  isAuthed,
  transactions,
  moreLoading,
  Filters,
  limitOrders,
  pendingOrders,
  defaultTab = TransactionState.Completed,
  onListRetry,
  onSelectTransaction,
  onGoBack,
}: TransactionManagerUIProps) {
  const { t } = useTranslation();
  const isConnected = Loader.useWrap(isAuthed)
    .match.notOk(() => false)
    .ok(authed => authed);

  const [currentTab, setCurrentTab] = useState<TransactionManagerTab>(defaultTab);
  const headerRef = useRef<HTMLDivElement>(null);
  const headerHeight = headerRef.current?.offsetHeight ?? 0;

  const transactions$ = Loader.useWrap(transactions);
  const limitOrders$ = Loader.useWrap(limitOrders);
  const pendingOrders$ = Loader.useWrap(pendingOrders);

  const transactionsRecap$ = Loader.array([transactions$, limitOrders$, pendingOrders$] as const).map(
    ([_transactions, _limitOrders, _pendingOrders]) => {
      const completedTxs = _transactions.filter(tx => tx.processedAt);
      const pendingTxs = _transactions.filter(tx => !tx.processedAt);

      const limitOrdersList = _limitOrders?.limitOrders?.results || [];
      const limitOrdersListAsTx = limitOrdersList.map(fromLimitOrderToTransaction);

      const pendingOrdersList = _pendingOrders?.pendingOrders?.results || [];
      const pendingOrdersListAsTx = pendingOrdersList.map(fromPendingOrderToTransaction);

      return {
        list: _transactions,
        completed: groupBy<string, Transaction>(completedTxs, groupByDate),
        completedCount: completedTxs.length,
        pending: groupBy<string, Transaction>(
          [...pendingTxs, ...limitOrdersListAsTx, ...pendingOrdersListAsTx],
          groupByDate,
        ),
        pendingCount: pendingTxs.length + limitOrdersListAsTx.length + pendingOrdersListAsTx.length,
      };
    },
  );

  const handleSelect = transactionsRecap$.makeCallback((_txs, entryHistory: IEntryHistory) => {
    onSelectTransaction?.(entryHistory.tx.id);
  });

  const emptyList = <EmptyList message={t('Transactions.TransactionManager.empty')} />;

  const list = transactionsRecap$
    .noFlickering()
    .match.loadingOrSkipped(() => <LoadingList />)
    .error(() => <ListErrorEntry onListRetry={onListRetry} />)
    .ok(({ completed, completedCount, pending, pendingCount }) => {
      const { content, count } = match(currentTab)
        .with(TransactionState.Completed, () => ({ count: completedCount, content: completed }))
        .with(TransactionState.Pending, () => ({ count: pendingCount, content: pending }))
        .run();
      return count ? <List transactions={content} onSelect={handleSelect} /> : emptyList;
    });

  const isListLoading = transactionsRecap$.isLoading;

  const isListEmpty = Loader.array([currentTab, transactionsRecap$] as const)
    .noFlickering()
    .match.notOk(() => false)
    .ok(([tab, _txs]) =>
      match(tab)
        .with(TransactionState.Completed, () => !_txs.completedCount)
        .with(TransactionState.Pending, () => !_txs.pendingCount)
        .exhaustive(),
    );

  const hasError = transactionsRecap$.isError;

  const spacing = theme.spacing[6]; // gap-6 equivalent

  return (
    <div className="h-full flex flex-col w-full gap-6">
      <div ref={headerRef} className="flex flex-col w-full gap-6">
        <SideContentHeader
          title={t('Transactions.TransactionManager.header.title')}
          onGoBack={onGoBack}
          alignment="left"
        />
        <Tabs
          tabs={[
            {
              id: TransactionState.Completed,
              label: t('Transactions.TransactionManager.status.completed'),
            },
            {
              id: TransactionState.Pending,
              label: t('Transactions.TransactionManager.status.pending'),
              badge: transactionsRecap$.match
                .notOk(() => undefined)
                .ok(({ pendingCount }) =>
                  pendingCount
                    ? {
                      content: pendingCount.toString(),
                      style: 'accent',
                      variant: 'heavy',
                      isLoading: isListLoading,
                    }
                    : undefined,
                ),
            },
          ]}
          onChange={tabId => setCurrentTab(tabId as TransactionManagerTab)}
          currentTab={currentTab}
        />
        {isConnected && (!hasError || isListEmpty) ? Filters : null}
      </div>
      {isConnected ? (
        <div style={{ height: `calc(100% - ${headerHeight}px - ${spacing}px)` }}>
          <div
            id={SCROLL_ID}
            className="overflow-y-auto hide-scrollbars flex flex-col gap-6 h-full pb-[72px]"
            key="transactions"
          >
            {list}
            {moreLoading && <LoadingList count={1} />}
          </div>
        </div>
      ) : (
        emptyList
      )}
    </div>
  );
}
