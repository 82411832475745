import { atom } from 'recoil';

export const LimitOrderRefreshAtom = atom({
  key: 'limitOrderRefreshAtom',
  default: 0,
});

export const PendingOrdersRefreshAtom = atom({
  key: 'PendingOrdersRefreshAtom',
  default: 0,
});
