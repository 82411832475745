import cls from 'classnames';

import BudgetInput from '@components/NumberInputs/BudgetInput';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry } from '@ui-kit/organisms/Entry';
import { Loader, Budget, Loadable } from '@utils';

export interface RateInputProps {
  rate: Loadable<Budget>;
  setRate: (value: bigint) => void;
  indication: string;
  isLoading?: boolean;
  hasError?: boolean;
}

export function RateInput({ rate, setRate, indication, isLoading, hasError }: RateInputProps) {
  const rate$ = Loader.useWrap(rate);

  const label = (
    <div className="flex justify-between text-sm text-font-variant font-medium select-none">{indication}</div>
  );

  return rate$.match
    .notOk(status => (
      <Skeleton noAnimation={status === 'error'} className="z-0 !min-w-0 w-full !block">
        <Entry
          isCard
          isActive={false}
          disabled={rate$.isLoading || isLoading}
          className={cls(hasError ? 'border-danger' : '!border-none', 'border h-full overflow-visible')}
          content={{
            className: 'gap-2',
            top: label,
            bottom: (
              <div className="relative w-full font-youth-medium flex">
                <BudgetInput
                  value={null}
                  disabled
                  onChange={() => {}}
                  className={cls({
                    'text-font-disabled': rate$.isLoading,
                    'border !border-danger caret-outline': hasError,
                  })}
                />
              </div>
            ),
          }}
        />
      </Skeleton>
    ))
    .ok(_rate => (
      <Entry
        isCard
        isActive={false}
        disabled={isLoading}
        className="border h-full"
        content={{
          className: 'gap-2',
          top: label,
          bottom: (
            <div className="relative w-full font-youth-medium flex">
              <BudgetInput
                value={_rate}
                onChange={b => setRate(b.amtBase)}
                disabled={isLoading}
                className={cls('text-font', {
                  'text-font-disabled': rate$.isLoading,
                  'border !border-danger caret-outline': hasError,
                })}
              />
            </div>
          ),
        }}
      />
    ));
}

// export default function EmptyRateInput({
//   label,
//   status,
// }: { label: string; status?: string } & Cls) {
//   return (
//     <Skeleton noAnimation={status === 'error'}>
//       <BudgetInputEntry
//         budget={{ amtBase: 0n, quote: 0, token: emptyCoin }}
//         onChange={() => {}}
//         label={label}
//       />
//     </Skeleton>
//   );
// }
// const emptyCoin: ICoin = {
//   id: '' as ChainAddress,
//   decimals: 0,
//   logo: '',
//   name: '',
//   symbol: '',
// };
