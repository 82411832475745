import { useSetRecoilState } from 'recoil';

import { Chain, refreshLimitOrder, refreshLimitOrderVariables } from '@gql';
import { REFRESH_PENDING_ORDERS } from '@queries/pending-orders.queries';
import { PendingOrdersRefreshAtom } from '@recoil-atoms/limit-orders';
import { fetchApolloAsync } from '@utils/apollo-utils';
import { allNetworks } from '@utils/networks';

export const usePendingOrdersRefresh = () => {
  const setPendingOrdersRefreshAtom = useSetRecoilState(PendingOrdersRefreshAtom);

  const availableChaines = allNetworks.filter(n => !n.disabled).map(n => n.chain);

  async function refreshPendingOrdersForChain(chain: Chain, refreshAtom: boolean = true) {
    await fetchApolloAsync<refreshLimitOrder, refreshLimitOrderVariables>(REFRESH_PENDING_ORDERS, {
      chain,
    });

    if (refreshAtom) setPendingOrdersRefreshAtom(prev => prev + 1);
  }

  async function refreshPendingOrdersAllChains() {
    await Promise.all(availableChaines.map(chain => refreshPendingOrdersForChain(chain, false))).then(() => {
      setPendingOrdersRefreshAtom(prev => prev + 1);
    });
  }

  return {
    refreshPendingOrdersForChain,
    refreshPendingOrdersAllChains,
  };
};
