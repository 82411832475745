import cls from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import { TooltipIcon } from '@ui-kit/Icons';
import { Alert } from '@ui-kit/atoms/Alert';
import { Cls, Elt } from '@utils/utils';

export type ITooltip = {
  tooltipContent: Elt;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  iconClassName?: string;
  tooltipClassName?: string;
  direction?: 'top' | 'bottom' | 'right' | 'left';
  children?: React.ReactNode;
  alertClassName?: string;
  isAlert?: boolean;
} & Cls;

export function Tooltip({
  tooltipContent,
  className,
  onClick,
  direction = 'top',
  iconClassName,
  tooltipClassName,
  children,
  alertClassName,
  isAlert = true,
}: ITooltip) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: direction,
    interactive: true,
    offset: [0, 8],
  });

  return (
    <div ref={setTriggerRef} className={cls('w-max', className)} onClick={onClick}>
      {children || <TooltipIcon className={cls('w-4 h-4', iconClassName)} />}
      {visible && tooltipContent && (
        <div
          data-chromatic="ignore"
          ref={setTooltipRef}
          {...getTooltipProps({
            className: cls('z-50', tooltipClassName),
          })}
        >
          {isAlert ? <Alert content={tooltipContent} isFixed className={alertClassName} /> : tooltipContent}
        </div>
      )}
    </div>
  );
}
