import * as Sentry from '@sentry/react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { match } from 'ts-pattern';

import { DEFAULT_CHAIN, defaultTokens } from '@constants';
import { GetSpotAccountHoldings, GetSpotAccountHoldingsVariables, ITokenSummary, Timeframe } from '@gql';
import { useLimitOrdersRefresh } from '@hooks/useLimitOrdersRefresh';
import { refreshMainVaultDataAtom, refreshMainVaultData } from '@hooks/useMainVault';
import { getTokenInfo } from '@hooks/useTokensInfo';
import { useTxManagerAlerts } from '@hooks/useTxManagerAlerts';
import { GET_CURRENT_ACCOUNT_HOLDINGS } from '@queries/account.queries';
import { getUserAggregatedHoldings } from '@queries/user.queries';
import { useCreateOrCallVault } from '@tetris/tx';
import { useLimitOrder } from '@tetris/useLimitOrder';
import { AlertLink, IAlert } from '@ui-kit/atoms/Alert';
import {
  Loader,
  chainOf,
  useTokenQuote,
  holdingQuote,
  TokenBudget,
  parseNum,
  safeMult,
  ICoin,
  convertAmt,
  getTransactionDetailsUrl,
  makeChainAddress,
} from '@utils';
import { useWallet } from '@wallet-hooks';

import { LimitOrdersUI } from './LimitOrdersUI';
import { Expiration } from './enum';
import { useChangeCoin } from '../../../hooks/useChangeCoin';
import { useTransactionFlow } from '../../../hooks/useTransactionFlow';
import { LimitOrdersProps } from '../../../types';

export const LimitOrders = ({ prefillInputTokenId, prefillOutputTokenId }: LimitOrdersProps) => {
  const wallet$ = useWallet(true);
  const changeSourceCoin = useChangeCoin(false, { onlyOwned: true });
  const changeTargetCoin = useChangeCoin(false);
  const [expiration, setExpiration] = useState<Expiration>(Expiration.Never);
  const [targetPrice, setTargetPrice] = useState(0);
  const { currentChain, resetScroll } = useTransactionFlow();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const refreshWhenChanges = useRecoilValue(refreshMainVaultDataAtom);
  const currentChain$ = Loader.useWrap(currentChain);
  const { refreshLimitOrdersForChain } = useLimitOrdersRefresh();
  const [alert, setAlert] = useState<IAlert | null>(null);
  const { lastTransaction } = useTxManagerAlerts();

  const { t } = useTranslation();

  // build the input token state
  const [inputBudget$, setInputBudget] = Loader.useWrap(prefillInputTokenId)
    // if we have a specified input, then skip the balance getting
    .map(x => (x ? Loader.skipped : true))
    // wait current chain
    .combine(currentChain$, (_, chain) => chain)
    .map(getUserAggregatedHoldings)
    // fetch the highest balance token
    .map<ITokenSummary | nil>(x => {
    const tokenWithBalance = x.mainVault.spot
      .map(balance => ({
        ...balance,
        value: balance.qtyNum * balance.token.quote,
      }))
      .sort((a, b) => b.value - a.value);

    return tokenWithBalance && tokenWithBalance.length ? tokenWithBalance[0]?.token : null;
  })
    // in case we have a prefilled token, then get its details
    .mapNotLoaded('skipped', () => null)
    .combine(
      currentChain$,
      (x, chain) => x || getTokenInfo(prefillInputTokenId ?? defaultTokens[chain || DEFAULT_CHAIN]),
    )
    .map(x => x || Loader.error('No token found'))
    // build a budget out of it
    .map<TokenBudget>(token => ({ token, amtBase: 0n }))
    .asState<TokenBudget | nil>();

  const inputTokenId = inputBudget$.match.notOk(() => '').ok(budget => budget?.token?.id || '') as ChainAddress;

  // build the output token state
  const [outputToken$, setOutputToken] = Loader.useWrap(prefillOutputTokenId)
    .map(outputTokId => (outputTokId ? getTokenInfo(outputTokId) : null))
    .asState<ICoin | nil>();

  const outputTokenId = outputToken$.match.notOk(() => '').ok(token => token?.id || '') as ChainAddress;

  // get quotes of budgets
  const inputQuote$ = useTokenQuote(inputTokenId);
  const outputQuote$ = useTokenQuote(outputTokenId);

  const accountHoldings$ = wallet$
    .query<GetSpotAccountHoldings, GetSpotAccountHoldingsVariables>(
    [refreshWhenChanges],
    GET_CURRENT_ACCOUNT_HOLDINGS,
    () => ({
      frame: Timeframe.p1d,
    }),
  )
    .map(({ mainVault }) => mainVault.spot.map(({ token, qty }) => ({ token, qty })) || [])
    .map(holdings => holdings.sort((a, b) => holdingQuote(b) - holdingQuote(a)));

  const inputBalance$ = accountHoldings$.combine(inputBudget$, (holdings, inp) => {
    const q = holdings.find(tok => tok.token.id === inp?.token?.id)?.qty;
    return parseNum(q) ?? 0n;
  });

  const outputBudgetWithQuote$ = Loader.array([outputToken$, outputQuote$, inputBudget$, targetPrice] as const).map(
    ([out, quote, inp, tp]) => {
      if (!out) return null;

      const decimalsConverted = !inp
        ? 0n
        : (inp.amtBase * 10n ** BigInt(out.decimals)) / 10n ** BigInt(inp.token.decimals);

      const amtBase = safeMult(decimalsConverted, tp);

      return {
        token: out,
        amtBase,
        quote: quote ?? 0,
      };
    },
  );

  const inputBudgetWithQuote$ = Loader.array([inputBudget$, inputQuote$] as const).map(([inp, quote]) => {
    if (!inp) return null;
    return {
      ...inp,
      quote: quote ?? 0,
    };
  });

  // ---------------------- PREPARE TRANSACTION ----------------------

  const expirationInHour = useMemo(() => {
    return match(expiration)
      .with(Expiration.Never, () => undefined)
      .with(Expiration.OneDay, () => 24)
      .with(Expiration.OneWeek, () => 7 * 24)
      .with(Expiration.OneMonth, () => 30 * 24)
      .with(Expiration.OneYear, () => 365 * 24)
      .exhaustive();
  }, [expiration]);

  const orderLimit = useLimitOrder({
    input: inputBudget$,
    output: outputBudgetWithQuote$,
    expirationInHour,
  });

  const chain$ = inputBudget$.map(x => chainOf(x?.token?.id));
  const { sendTx, operation } = useCreateOrCallVault(chain$, [orderLimit]);

  const resetBudget = inputBudget$.makeCallback(input => {
    if (input) setInputBudget({ ...input, amtBase: 0n });
    setOutputToken(null);
    setTargetPrice(0);
    setExpiration(Expiration.Never);
  });

  operation.onOk(() => {
    try {
      resetBudget();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refreshLimitOrdersForChain(chainOf(inputTokenId));
      refreshMainVaultData();
    } catch (e) {
      // TODO HANDLE - ERROR SYLVAIN
    }
  });

  operation.onError(error => {
    const sentTx = lastTransaction?.hash;

    const failedLinks: AlertLink[] = [];

    const sendReportLink: AlertLink = {
      label: t('TransactionDetails.error.sendReport'),
      onClick: () => Sentry.captureException(error),
    };

    failedLinks.push(sendReportLink);

    if (sentTx && currentChain) {
      const explorerLink: AlertLink = {
        label: t('Transactions.ToastActions.explorer'),
        isExternal: true,
        url: getTransactionDetailsUrl(makeChainAddress(currentChain, sentTx)),
      };
      failedLinks.push(explorerLink);
    }

    setAlert({
      variant: 'error',
      title: t('Transactions.errors.limitOrder.title'),
      content: t('Transactions.errors.limitOrder.description'),
      links: failedLinks,
    });
  });

  return (
    <LimitOrdersUI
      inputRef={inputRef}
      inputBudgetWithQuote={inputBudgetWithQuote$}
      outputBudgetWithQuote={outputBudgetWithQuote$}
      expiration={expiration}
      inputAvailable={inputBalance$}
      setInputBudget={budget => {
        setAlert(null);
        setInputBudget(budget);
      }}
      setOutputToken={token => {
        setAlert(null);
        setOutputToken(token);
      }}
      setTargetPrice={setTargetPrice}
      setExpiration={setExpiration}
      onSourceCoinSelect={() =>
        changeSourceCoin(async coin => {
          if (!coin) return;
          setAlert(null);
          resetScroll();
          setInputBudget(prev => ({
            token: coin,
            amtBase: convertAmt(prev?.amtBase || 0n, prev?.token?.decimals || coin.decimals, coin.decimals),
          }));
          setTimeout(() => inputRef.current?.focus(), 100);
        })
      }
      onTargetCoinSelect={() =>
        changeTargetCoin(async coin => {
          if (!coin) return;
          setAlert(null);
          resetScroll();
          setOutputToken(coin);
        })
      }
      sendTx={sendTx}
      isOperationLoading={operation.isLoading}
      alert={alert}
      setAlert={setAlert}
    />
  );
};
