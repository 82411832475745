import { useTranslation } from 'react-i18next';

import BudgetInputEntry from '@transactions/components/ui/BudgetInputEntry';
import { VerticalProcessLayout } from '@transactions/components/ui/VerticalProcessLayout';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry } from '@ui-kit/organisms/Entry';
import EntryGroup from '@ui-kit/organisms/EntryGroup';
import {
  Budget,
  BudgetWithQuote,
  ICoin,
  Loadable,
  Loader,
  budgetNumValue,
  toNumber,
  withoutChain,
  shortenAddress,
} from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

export type WithdrawUIProps = {
  vaultAddress?: Loadable<ChainAddress | null>;
  balanceQty: Loadable<{ value: bigint; decimals: number }>;
  sourceBudget: Loadable<BudgetWithQuote<ICoin>>;
  sourceBalance: Loadable<bigint>;
  onSourceChange: (budget: Budget<ICoin>, isMax?: boolean) => void;
  onSourceCoinSelect: VoidFunction;
  onSwitchToDeposit?: VoidFunction;
  disabled?: boolean;
  isInputTokenUsedInLimitOrders?: Loadable<boolean>;
};

export function WithdrawUI({
  vaultAddress,
  balanceQty,
  sourceBudget,
  sourceBalance,
  onSourceChange,
  onSourceCoinSelect,
  onSwitchToDeposit,
  disabled,
  isInputTokenUsedInLimitOrders,
}: WithdrawUIProps) {
  const { t } = useTranslation();

  const vaultAddress$ = Loader.useWrap(vaultAddress);
  const balanceQty$ = Loader.useWrap(balanceQty);
  const sourceBudget$ = Loader.useWrap(sourceBudget);
  const sourceBalance$ = Loader.useWrap(sourceBalance);
  const isInputTokenUsedInLimitOrders$ = Loader.useWrap(isInputTokenUsedInLimitOrders);

  const hint$ = Loader.array([sourceBudget$, sourceBalance$] as const).map(([_sourceBudget, _sourceBalance]) => {
    if (_sourceBudget.amtBase > _sourceBalance) {
      return { errorMessage: t('Common.Errors.insufficientBalance') };
    }
    return { usdValue: budgetNumValue(_sourceBudget) };
  });

  return (
    <VerticalProcessLayout
      onArrowClick={onSwitchToDeposit}
      steps={[
        {
          key: 'in',
          item: (
            <EntryGroup
              isError={hint$.match.notOk(() => false).ok(({ errorMessage }) => Boolean(errorMessage))}
              entries={[
                <Entry
                  key="spot-account"
                  isCard
                  disabled
                  noMinHeight
                  content={{
                    top: (
                      <div className="flex justify-between select-none">
                        <span className="text-base font-medium">{t('Transactions.Withdraw.from')}</span>
                        <span className="text-font-variant text-sm">
                          {vaultAddress$.match
                            .notOk(() => <Skeleton className="w-20 h-4" />)
                            .ok(_vaultAddress => {
                              if (!_vaultAddress) return null;
                              const address = withoutChain(_vaultAddress);
                              return shortenAddress(address, true);
                            })}
                        </span>
                      </div>
                    ),
                  }}
                />,
                <BudgetInputEntry
                  key="budgetInput"
                  type="spot"
                  budget={sourceBudget$}
                  onChange={onSourceChange}
                  maxBudget={sourceBalance$}
                  hint={hint$}
                  onSelectCoin={onSourceCoinSelect}
                  disabled={disabled}
                  hasWarning={isInputTokenUsedInLimitOrders$.match.notOk(() => false).ok(v => v)}
                />,
              ]}
            />
          ),
        },
        {
          key: 'out',
          item: (
            <Entry
              isCard
              disabled
              noMinHeight
              content={{
                top: (
                  <div className="flex justify-between">
                    <span className="text-base font-medium select-none">{t('Transactions.Withdraw.to')}</span>
                    <span className="text-font-variant text-sm">
                      {balanceQty$.match
                        .notOk(() => <Skeleton className="w-20 h-4" />)
                        .ok(({ value, decimals }) => formatNumber(toNumber(value, decimals), 'qty'))}
                    </span>
                  </div>
                ),
              }}
            />
          ),
        },
      ]}
    />
  );
}
