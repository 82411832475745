/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccSummary
// ====================================================

export interface GetAccSummary_myUser {
  readonly __typename: "MassUser";
  readonly walletValue: number;
}

export interface GetAccSummary {
  /**
   * Get my main vault on the given chain
   */
  readonly myUser: GetAccSummary_myUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsChart
// ====================================================

export interface GetCoinDetailsChart_tokenAggregated_curves {
  readonly __typename: "HistoryFrame";
  /**
   * History, as OHLC values
   */
  readonly ohlc: OHLCArray;
}

export interface GetCoinDetailsChart_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Quote graph
   */
  readonly curves: GetCoinDetailsChart_tokenAggregated_curves | null;
}

export interface GetCoinDetailsChart {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsChart_tokenAggregated | null;
}

export interface GetCoinDetailsChartVariables {
  readonly id: AggregatedTokenId;
  readonly timeframe?: Timeframe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsVariation
// ====================================================

export interface GetCoinDetailsVariation_tokenAggregated_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetCoinDetailsVariation_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Quote graph
   */
  readonly curves: GetCoinDetailsVariation_tokenAggregated_curves | null;
}

export interface GetCoinDetailsVariation {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsVariation_tokenAggregated | null;
}

export interface GetCoinDetailsVariationVariables {
  readonly id: AggregatedTokenId;
  readonly timeframe?: Timeframe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsSocialLinks
// ====================================================

export interface GetCoinDetailsSocialLinks_tokenAggregated_links {
  readonly __typename: "TokenLinks";
  /**
   * Link to Bitcointalk page of this token
   */
  readonly bitcointalk: string | null;
  /**
   * Link to coingecko page of this token
   */
  readonly coingecko: string | null;
  /**
   * Link to official website of this token
   */
  readonly officialWebsite: string | null;
  /**
   * Link to Facebook page of this token
   */
  readonly facebook: string | null;
  /**
   * Link to Reddit page of this token
   */
  readonly reddit: string | null;
  /**
   * Link to Telegram page of this token
   */
  readonly telegram: string | null;
  /**
   * Link to Twitter page of this token
   */
  readonly twitter: string | null;
}

export interface GetCoinDetailsSocialLinks_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  /**
   * Useful links for this token
   */
  readonly links: GetCoinDetailsSocialLinks_tokenAggregated_links;
}

export interface GetCoinDetailsSocialLinks {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsSocialLinks_tokenAggregated | null;
}

export interface GetCoinDetailsSocialLinksVariables {
  readonly id: AggregatedTokenId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsSummary
// ====================================================

export interface GetCoinDetailsSummary_tokenAggregated_oneDay {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetCoinDetailsSummary_tokenAggregated_oneWeek {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetCoinDetailsSummary_tokenAggregated_oneMonth {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetCoinDetailsSummary_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly circulatingSupply: number | null;
  readonly marketCap: number | null;
  readonly totalSupply: number | null;
  readonly fullyDillutedCap: number | null;
  /**
   * All time high percentage change of this token
   */
  readonly ath_change_ratio: number | null;
  /**
   * Quote graph
   */
  readonly oneDay: GetCoinDetailsSummary_tokenAggregated_oneDay | null;
  /**
   * Quote graph
   */
  readonly oneWeek: GetCoinDetailsSummary_tokenAggregated_oneWeek | null;
  /**
   * Quote graph
   */
  readonly oneMonth: GetCoinDetailsSummary_tokenAggregated_oneMonth | null;
}

export interface GetCoinDetailsSummary {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsSummary_tokenAggregated | null;
}

export interface GetCoinDetailsSummaryVariables {
  readonly id: AggregatedTokenId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsLiquidity
// ====================================================

export interface GetCoinDetailsLiquidity_tokenAggregated_liquidityByChain {
  readonly __typename: "GChainLiquidity";
  readonly chain: Chain;
  readonly liquidity: number;
}

export interface GetCoinDetailsLiquidity_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  /**
   * Liquidity of this token on this chain
   */
  readonly liquidity: number | null;
  /**
   * Gives liquidity for this token on every chain
   */
  readonly liquidityByChain: ReadonlyArray<GetCoinDetailsLiquidity_tokenAggregated_liquidityByChain>;
}

export interface GetCoinDetailsLiquidity {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsLiquidity_tokenAggregated | null;
}

export interface GetCoinDetailsLiquidityVariables {
  readonly id: AggregatedTokenId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsMarketData
// ====================================================

export interface GetCoinDetailsMarketData_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  /**
   * On chain buy trading volume for this token
   */
  readonly buyVolume: number | null;
  /**
   * On chain sell trading volume for this token
   */
  readonly sellVolume: number | null;
  /**
   * On chain average trade size for this token
   */
  readonly averageTradeSize: number | null;
  /**
   * On chain average trade price for this token
   */
  readonly averagePrice: number | null;
  /**
   * Number of known onchain trades for this token
   */
  readonly tradeCount: number | null;
  /**
   * On chain trading volume for this token
   */
  readonly volume: number | null;
}

export interface GetCoinDetailsMarketData_token {
  readonly __typename: "TokenInfo";
  /**
   * On chain buy trading volume for this token
   */
  readonly buyVolume: number | null;
  /**
   * On chain sell trading volume for this token
   */
  readonly sellVolume: number | null;
  /**
   * On chain average trade size for this token
   */
  readonly averageTradeSize: number | null;
  /**
   * On chain average trade price for this token
   */
  readonly averagePrice: number | null;
  /**
   * Number of known onchain trades for this token
   */
  readonly tradeCount: number | null;
  /**
   * On chain trading volume for this token
   */
  readonly volume: number | null;
}

export interface GetCoinDetailsMarketData {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsMarketData_tokenAggregated | null;
  /**
   * Get info about a token
   */
  readonly token: GetCoinDetailsMarketData_token | null;
}

export interface GetCoinDetailsMarketDataVariables {
  readonly id: AggregatedTokenId;
  readonly isAggregated: boolean;
  readonly chainTokenId?: ERC20 | null;
  readonly timeframe?: Timeframe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsSwapActivity
// ====================================================

export interface GetCoinDetailsSwapActivity_tokenAggregated_swapActivity_inToken {
  readonly __typename: "TokenInfo";
  readonly symbol: string;
  readonly name: string;
}

export interface GetCoinDetailsSwapActivity_tokenAggregated_swapActivity_outToken {
  readonly __typename: "TokenInfo";
  readonly symbol: string;
  readonly name: string;
}

export interface GetCoinDetailsSwapActivity_tokenAggregated_swapActivity {
  readonly __typename: "SwapActivity";
  /**
   * Type of the swap activity, can be buy or sell
   */
  readonly type: TokenActivityType;
  /**
   * Name of the protocol of the swap
   */
  readonly protocol: string;
  /**
   * Token entering the pool (sold)
   */
  readonly inToken: GetCoinDetailsSwapActivity_tokenAggregated_swapActivity_inToken;
  /**
   * Token exiting the pool (bought)
   */
  readonly outToken: GetCoinDetailsSwapActivity_tokenAggregated_swapActivity_outToken;
  readonly chain: Chain;
  /**
   * Usd quote of the token provided in filter at the time of the swap (inToken for type sell or outToken for type buy)
   */
  readonly price: number;
  /**
   * Quantity of the out token expressed in regular number
   */
  readonly outQty: number;
  /**
   * Quantity of the in token expressed in regular number
   */
  readonly inQty: number;
  /**
   * Usd value swapped
   */
  readonly usdValue: number;
  /**
   * Date of the swap
   */
  readonly date: DateTime;
  /**
   * Swap transaction
   */
  readonly tx: ChainAddress;
}

export interface GetCoinDetailsSwapActivity_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  /**
   * On chain swap activity
   */
  readonly swapActivity: ReadonlyArray<GetCoinDetailsSwapActivity_tokenAggregated_swapActivity>;
}

export interface GetCoinDetailsSwapActivity {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsSwapActivity_tokenAggregated | null;
}

export interface GetCoinDetailsSwapActivityVariables {
  readonly id: AggregatedTokenId;
  readonly limit?: number | null;
  readonly endDate?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNonce
// ====================================================

export interface getNonce_auth {
  readonly __typename: "AuthQueries";
  /**
   * Get a nonce string to sign
   */
  readonly nonce: string | null;
}

export interface getNonce {
  readonly auth: getNonce_auth;
}

export interface getNonceVariables {
  readonly address: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: authenticate
// ====================================================

export interface authenticate_auth_authenticate {
  readonly __typename: "AuthToken";
  readonly token: string;
}

export interface authenticate_auth {
  readonly __typename: "AuthQueries";
  /**
   * Authenticate with a private key signed message that must contain the user's wallet address and a nonce provided by the server
   */
  readonly authenticate: authenticate_auth_authenticate | null;
}

export interface authenticate {
  readonly auth: authenticate_auth;
}

export interface authenticateVariables {
  readonly address: UserId;
  readonly signature: HexString;
  readonly message: string;
  readonly magicAuth?: MagicAuth | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyInfo
// ====================================================

export interface getMyInfo_myUser {
  readonly __typename: "MassUser";
  readonly id: UserId;
  /**
   * Get a user setting by its id
   */
  readonly onboarding: JSON | null;
}

export interface getMyInfo {
  /**
   * Get my main vault on the given chain
   */
  readonly myUser: getMyInfo_myUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTokensList
// ====================================================

export interface GetTokensList_tokensAggregated_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
}

export interface GetTokensList_tokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  readonly chainIds: ReadonlyArray<ERC20>;
  /**
   * Coingecko ranking of this token
   */
  readonly cgRanking: number | null;
  readonly marketCap: number | null;
  /**
   * Checks if the token is on my favorites
   */
  readonly isFavorite: boolean;
  /**
   * Quote graph
   */
  readonly curves: GetTokensList_tokensAggregated_curves | null;
}

export interface GetTokensList {
  /**
   * Get the aggregated token list
   */
  readonly tokensAggregated: ReadonlyArray<GetTokensList_tokensAggregated>;
  /**
   * Get the aggregated token list count
   */
  readonly tokensAggregatedCount: number;
}

export interface GetTokensListVariables {
  readonly limit?: number | null;
  readonly offset?: number | null;
  readonly isFavorite?: boolean | null;
  readonly order?: TokenOrderFilter | null;
  readonly search?: string | null;
  readonly chains?: ReadonlyArray<Chain> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTokenSearch
// ====================================================

export interface GetTokenSearch_tokensAggregated_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
}

export interface GetTokenSearch_tokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Coingecko ranking of this token
   */
  readonly cgRanking: number | null;
  readonly marketCap: number | null;
  /**
   * Checks if the token is on my favorites
   */
  readonly isFavorite: boolean;
  /**
   * Quote graph
   */
  readonly curves: GetTokenSearch_tokensAggregated_curves | null;
}

export interface GetTokenSearch {
  /**
   * Get the aggregated token list
   */
  readonly tokensAggregated: ReadonlyArray<GetTokenSearch_tokensAggregated>;
  /**
   * Get the aggregated token list count
   */
  readonly tokensAggregatedCount: number;
}

export interface GetTokenSearchVariables {
  readonly limit?: number | null;
  readonly search?: string | null;
  readonly chains?: ReadonlyArray<Chain> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFavoriteTokens
// ====================================================

export interface GetFavoriteTokens_tokensAggregated_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetFavoriteTokens_tokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Checks if the token is on my favorites
   */
  readonly isFavorite: boolean;
  /**
   * Quote graph
   */
  readonly curves: GetFavoriteTokens_tokensAggregated_curves | null;
}

export interface GetFavoriteTokens {
  /**
   * Get the aggregated token list
   */
  readonly tokensAggregated: ReadonlyArray<GetFavoriteTokens_tokensAggregated>;
}

export interface GetFavoriteTokensVariables {
  readonly isFavorite: boolean;
  readonly limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTransactions
// ====================================================

export interface GetTransactions_transactions_results_inputs_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
  readonly decimals: number;
}

export interface GetTransactions_transactions_results_inputs {
  readonly __typename: "QuotedTokenQty";
  /**
   * Token
   */
  readonly token: GetTransactions_transactions_results_inputs_token;
  /**
   * Token quantity
   */
  readonly qty: HexNumber;
  /**
   * Quote of the token at time of transaction
   */
  readonly quote: number;
  /**
   * USD value of this qty, quoted at spending date (null if no quote found)
   */
  readonly usdValue: number | null;
}

export interface GetTransactions_transactions_results_outputs_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
  readonly decimals: number;
}

export interface GetTransactions_transactions_results_outputs {
  readonly __typename: "QuotedTokenQty";
  /**
   * Token
   */
  readonly token: GetTransactions_transactions_results_outputs_token;
  /**
   * Token quantity
   */
  readonly qty: HexNumber;
  /**
   * Quote of the token at time of transaction
   */
  readonly quote: number;
  /**
   * USD value of this qty, quoted at spending date (null if no quote found)
   */
  readonly usdValue: number | null;
}

export interface GetTransactions_transactions_results {
  readonly __typename: "GProcessedTx";
  /**
   * ID of the transaction
   */
  readonly id: TxId;
  /**
   * Type of the transaction
   */
  readonly type: TransactionType | null;
  /**
   * Inputs of the transaction
   */
  readonly inputs: ReadonlyArray<GetTransactions_transactions_results_inputs>;
  /**
   * Outputs of the transaction
   */
  readonly outputs: ReadonlyArray<GetTransactions_transactions_results_outputs>;
  /**
   * Date at which the transaction was sent
   */
  readonly txTimestamp: DateTime;
  /**
   * Date at which the transaction processed
   */
  readonly processedAt: DateTime | null;
}

export interface GetTransactions_transactions {
  readonly __typename: "TxPaginator";
  readonly results: ReadonlyArray<GetTransactions_transactions_results>;
  readonly totalCount: number;
}

export interface GetTransactions {
  /**
   * Get user transactions
   */
  readonly transactions: GetTransactions_transactions;
}

export interface GetTransactionsVariables {
  readonly filters?: TxFilter | null;
  readonly pagination?: PaginationArgs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTransaction
// ====================================================

export interface GetTransaction_transaction_inputs_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
  readonly decimals: number;
}

export interface GetTransaction_transaction_inputs {
  readonly __typename: "QuotedTokenQty";
  /**
   * Token
   */
  readonly token: GetTransaction_transaction_inputs_token;
  /**
   * Token quantity
   */
  readonly qty: HexNumber;
  /**
   * Quote of the token at time of transaction
   */
  readonly quote: number;
  /**
   * USD value of this qty, quoted at spending date (null if no quote found)
   */
  readonly usdValue: number | null;
}

export interface GetTransaction_transaction_outputs_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
  readonly decimals: number;
}

export interface GetTransaction_transaction_outputs {
  readonly __typename: "QuotedTokenQty";
  /**
   * Token
   */
  readonly token: GetTransaction_transaction_outputs_token;
  /**
   * Token quantity
   */
  readonly qty: HexNumber;
  /**
   * Quote of the token at time of transaction
   */
  readonly quote: number;
  /**
   * USD value of this qty, quoted at spending date (null if no quote found)
   */
  readonly usdValue: number | null;
}

export interface GetTransaction_transaction {
  readonly __typename: "GProcessedTx";
  /**
   * ID of the transaction
   */
  readonly id: TxId;
  /**
   * Type of the transaction
   */
  readonly type: TransactionType | null;
  /**
   * Inputs of the transaction
   */
  readonly inputs: ReadonlyArray<GetTransaction_transaction_inputs>;
  /**
   * Outputs of the transaction
   */
  readonly outputs: ReadonlyArray<GetTransaction_transaction_outputs>;
  /**
   * Date at which the transaction was sent
   */
  readonly txTimestamp: DateTime;
  /**
   * Date at which the transaction processed
   */
  readonly processedAt: DateTime | null;
}

export interface GetTransaction {
  /**
   * Get a transaction by its id
   */
  readonly transaction: GetTransaction_transaction | null;
}

export interface GetTransactionVariables {
  readonly hash: TxId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WatchFutureTransfer
// ====================================================

export interface WatchFutureTransfer {
  readonly watchFutureTransfer: boolean;
}

export interface WatchFutureTransferVariables {
  readonly chain: Chain;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PriceImpactQuery
// ====================================================

export interface PriceImpactQuery {
  readonly getPriceImpact: number;
}

export interface PriceImpactQueryVariables {
  readonly inputToken: ERC20;
  readonly inputAmount: string;
  readonly outputToken: ERC20;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchTokens
// ====================================================

export interface SearchTokens_tokensAggregated_oneDayPerf {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface SearchTokens_tokensAggregated_oneWeekPerf {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface SearchTokens_tokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly logo: string | null;
  readonly symbol: string;
  readonly name: string;
  readonly chainIds: ReadonlyArray<ERC20>;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly price: number;
  /**
   * On chain trading volume for this token
   */
  readonly oneDayVolume: number | null;
  /**
   * Quote graph
   */
  readonly oneDayPerf: SearchTokens_tokensAggregated_oneDayPerf | null;
  /**
   * Quote graph
   */
  readonly oneWeekPerf: SearchTokens_tokensAggregated_oneWeekPerf | null;
}

export interface SearchTokens {
  /**
   * Get the aggregated token list
   */
  readonly tokensAggregated: ReadonlyArray<SearchTokens_tokensAggregated>;
}

export interface SearchTokensVariables {
  readonly search?: string | null;
  readonly limit: number;
  readonly chains?: ReadonlyArray<Chain> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TrendingTokens
// ====================================================

export interface TrendingTokens_trendingTokensAggregated_oneDayPerf {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface TrendingTokens_trendingTokensAggregated_oneWeekPerf {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface TrendingTokens_trendingTokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly logo: string | null;
  readonly symbol: string;
  readonly name: string;
  readonly chainIds: ReadonlyArray<ERC20>;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly price: number;
  /**
   * On chain trading volume for this token
   */
  readonly oneDayVolume: number | null;
  /**
   * Quote graph
   */
  readonly oneDayPerf: TrendingTokens_trendingTokensAggregated_oneDayPerf | null;
  /**
   * Quote graph
   */
  readonly oneWeekPerf: TrendingTokens_trendingTokensAggregated_oneWeekPerf | null;
}

export interface TrendingTokens {
  /**
   * Get the trending list of aggregated tokens
   */
  readonly trendingTokensAggregated: ReadonlyArray<TrendingTokens_trendingTokensAggregated>;
}

export interface TrendingTokensVariables {
  readonly limit: number;
  readonly chains?: ReadonlyArray<Chain> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStablecoins
// ====================================================

export interface GetStablecoins_stablecoins {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetStablecoins {
  /**
   * List all stablecoins tokens on a given chain
   */
  readonly stablecoins: ReadonlyArray<GetStablecoins_stablecoins> | null;
}

export interface GetStablecoinsVariables {
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDefaultStablecoins
// ====================================================

export interface GetDefaultStablecoins_defaultStablecoins {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetDefaultStablecoins {
  /**
   * List all default stablecoins tokens on a given chain
   */
  readonly defaultStablecoins: ReadonlyArray<GetDefaultStablecoins_defaultStablecoins> | null;
}

export interface GetDefaultStablecoinsVariables {
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRecommendedStablecoins
// ====================================================

export interface GetRecommendedStablecoins_recommendedStablecoins {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetRecommendedStablecoins {
  /**
   * List all recommended stablecoins tokens on a given chain
   */
  readonly recommendedStablecoins: ReadonlyArray<GetRecommendedStablecoins_recommendedStablecoins> | null;
}

export interface GetRecommendedStablecoinsVariables {
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinDetailsToken
// ====================================================

export interface GetCoinDetailsToken_tokenAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly name: string;
  readonly symbol: string;
  readonly logo: string | null;
  readonly chainIds: ReadonlyArray<ERC20>;
  /**
   * Checks if the token is on my favorites
   */
  readonly isFavorite: boolean;
}

export interface GetCoinDetailsToken {
  /**
   * Get info about an aggregated token
   */
  readonly tokenAggregated: GetCoinDetailsToken_tokenAggregated | null;
}

export interface GetCoinDetailsTokenVariables {
  readonly id: AggregatedTokenId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTokensInfo
// ====================================================

export interface GetTokensInfo_tokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetTokensInfo {
  /**
   * Search for tokens
   */
  readonly tokens: ReadonlyArray<GetTokensInfo_tokens>;
}

export interface GetTokensInfoVariables {
  readonly ids?: ReadonlyArray<ChainAddress> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpotAccount
// ====================================================

export interface GetSpotAccount_mainVault_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Current spot value
   */
  readonly value: number;
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
  /**
   * Computes a performance graph, which is independent from the porfolio nominal value changes.
   * 
   *         It describes how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will be a graph of values between -1 and +∞
   */
  readonly perfGraph: DiscontinuousPointArray;
  /**
   * Tells how much has been earned.
   * 
   *         If the composition has been changed, then the changes deltas will not be taken into account
   */
  readonly pnl: number;
}

export interface GetSpotAccount_mainVault_spot_token_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetSpotAccount_mainVault_spot_token {
  readonly __typename: "TokenInfo";
  readonly category: LocalizedString;
  readonly chain: Chain | null;
  /**
   * Specificities of this token (stablecoin, ...)
   */
  readonly flags: ReadonlyArray<TokenFlags>;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  /**
   * Quote graph
   */
  readonly curves: GetSpotAccount_mainVault_spot_token_curves;
}

export interface GetSpotAccount_mainVault_spot {
  readonly __typename: "TokenQty";
  readonly token: GetSpotAccount_mainVault_spot_token;
  readonly qty: HexNumber;
}

export interface GetSpotAccount_mainVault {
  readonly __typename: "Vault";
  /**
   * Quote graph
   */
  readonly curves: GetSpotAccount_mainVault_curves;
  /**
   * The spot assets in the vault
   */
  readonly spot: ReadonlyArray<GetSpotAccount_mainVault_spot>;
}

export interface GetSpotAccount {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetSpotAccount_mainVault;
}

export interface GetSpotAccountVariables {
  readonly frame: Timeframe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTradingData
// ====================================================

export interface GetTradingData_mainVault_tokenStats_realizedPnl {
  readonly __typename: "Pnl";
  /**
   * USD value
   */
  readonly usd: number;
  /**
   * Ratio, compared to total bought amount
   */
  readonly ratio: number;
}

export interface GetTradingData_mainVault_tokenStats_unrealizedPnl {
  readonly __typename: "Pnl";
  /**
   * USD value
   */
  readonly usd: number;
  /**
   * Ratio, compared to total bought amount
   */
  readonly ratio: number;
}

export interface GetTradingData_mainVault_tokenStats {
  readonly __typename: "VaultTokenStats";
  /**
   * Realized PNL
   */
  readonly realizedPnl: GetTradingData_mainVault_tokenStats_realizedPnl;
  /**
   * Unrealized PNL
   */
  readonly unrealizedPnl: GetTradingData_mainVault_tokenStats_unrealizedPnl;
  /**
   * Average buy price
   */
  readonly averageBuyPrice: number | null;
  /**
   * Average sell price
   */
  readonly averageSellPrice: number | null;
}

export interface GetTradingData_mainVault {
  readonly __typename: "Vault";
  /**
   * Get stats about a token
   */
  readonly tokenStats: GetTradingData_mainVault_tokenStats;
}

export interface GetTradingData {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetTradingData_mainVault;
}

export interface GetTradingDataVariables {
  readonly id: ERC20;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpotAccountHoldings
// ====================================================

export interface GetSpotAccountHoldings_mainVault_spot_token_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetSpotAccountHoldings_mainVault_spot_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly category: LocalizedString;
  readonly chain: Chain | null;
  /**
   * Specificities of this token (stablecoin, ...)
   */
  readonly flags: ReadonlyArray<TokenFlags>;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  /**
   * Quote graph
   */
  readonly curves: GetSpotAccountHoldings_mainVault_spot_token_curves;
}

export interface GetSpotAccountHoldings_mainVault_spot {
  readonly __typename: "TokenQty";
  readonly token: GetSpotAccountHoldings_mainVault_spot_token;
  readonly qty: HexNumber;
}

export interface GetSpotAccountHoldings_mainVault {
  readonly __typename: "Vault";
  /**
   * The spot assets in the vault
   */
  readonly spot: ReadonlyArray<GetSpotAccountHoldings_mainVault_spot>;
}

export interface GetSpotAccountHoldings {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetSpotAccountHoldings_mainVault;
}

export interface GetSpotAccountHoldingsVariables {
  readonly frame: Timeframe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMyAssets
// ====================================================

export interface GetMyAssets_mainVault_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Current spot value
   */
  readonly value: number;
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
  /**
   * Computes a performance graph, which is independent from the porfolio nominal value changes.
   * 
   *         It describes how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will be a graph of values between -1 and +∞
   */
  readonly perfGraph: DiscontinuousPointArray;
  /**
   * Tells how much has been earned.
   * 
   *         If the composition has been changed, then the changes deltas will not be taken into account
   */
  readonly pnl: number;
}

export interface GetMyAssets_mainVault {
  readonly __typename: "Vault";
  /**
   * Quote graph
   */
  readonly curves: GetMyAssets_mainVault_curves;
}

export interface GetMyAssets {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetMyAssets_mainVault;
}

export interface GetMyAssetsVariables {
  readonly frame: Timeframe;
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVotesForFeature
// ====================================================

export interface GetVotesForFeature_getVoteFeature_responsesCount {
  readonly __typename: "GResponseCount";
  /**
   * Response
   */
  readonly response: string;
  /**
   * Count
   */
  readonly count: number;
}

export interface GetVotesForFeature_getVoteFeature_userResponses {
  readonly __typename: "GResponse";
  /**
   * Response
   */
  readonly response: string;
}

export interface GetVotesForFeature_getVoteFeature {
  readonly __typename: "GVoteFeature";
  /**
   * Feature ID
   */
  readonly id: string;
  /**
   * Feature tag
   */
  readonly tag: string;
  /**
   * Maximum number of responses
   */
  readonly maxResponses: number;
  /**
   * List of possible responses
   */
  readonly possibleResponses: ReadonlyArray<string>;
  /**
   * Get responses counts for a feature
   */
  readonly responsesCount: ReadonlyArray<GetVotesForFeature_getVoteFeature_responsesCount>;
  /**
   * Get user responses count for a feature
   */
  readonly userResponsesCount: number;
  /**
   * Get user responses for a feature
   */
  readonly userResponses: ReadonlyArray<GetVotesForFeature_getVoteFeature_userResponses>;
}

export interface GetVotesForFeature {
  /**
   * Get a feature
   */
  readonly getVoteFeature: GetVotesForFeature_getVoteFeature | null;
}

export interface GetVotesForFeatureVariables {
  readonly tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitFeatureVote
// ====================================================

export interface SubmitFeatureVote {
  /**
   * Submit a vote for a feature
   */
  readonly submitFeatureVote: boolean;
}

export interface SubmitFeatureVoteVariables {
  readonly tag: string;
  readonly response: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllLimitOrders
// ====================================================

export interface getAllLimitOrders_limitOrders_results_take_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  readonly chain: Chain | null;
}

export interface getAllLimitOrders_limitOrders_results_take {
  readonly __typename: "TokenQty";
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly qty: HexNumber;
  readonly token: getAllLimitOrders_limitOrders_results_take_token;
}

export interface getAllLimitOrders_limitOrders_results_make_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  readonly chain: Chain | null;
}

export interface getAllLimitOrders_limitOrders_results_make {
  readonly __typename: "TokenQty";
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly qty: HexNumber;
  readonly token: getAllLimitOrders_limitOrders_results_make_token;
}

export interface getAllLimitOrders_limitOrders_results {
  readonly __typename: "GLimitOrder";
  readonly id: string;
  readonly vault: VaultId;
  readonly creationDate: DateTime;
  readonly status: LimitOrderStatus;
  readonly expiryDate: DateTime | null;
  readonly take: getAllLimitOrders_limitOrders_results_take;
  readonly make: getAllLimitOrders_limitOrders_results_make;
}

export interface getAllLimitOrders_limitOrders {
  readonly __typename: "PaginatedLimitOrders";
  readonly results: ReadonlyArray<getAllLimitOrders_limitOrders_results>;
}

export interface getAllLimitOrders {
  /**
   * Get limit orders
   */
  readonly limitOrders: getAllLimitOrders_limitOrders;
}

export interface getAllLimitOrdersVariables {
  readonly filters?: GLimitOrdersFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refreshLimitOrder
// ====================================================

export interface refreshLimitOrder_refreshLimitOrders {
  readonly __typename: "PaginatedLimitOrders";
  readonly totalCount: number;
}

export interface refreshLimitOrder {
  /**
   * Refresh limit order for the given vault
   */
  readonly refreshLimitOrders: refreshLimitOrder_refreshLimitOrders;
}

export interface refreshLimitOrderVariables {
  readonly chain: Chain;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMainVault
// ====================================================

export interface GetMainVault_mainVault_spot_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly chain: Chain | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetMainVault_mainVault_spot {
  readonly __typename: "TokenQty";
  readonly token: GetMainVault_mainVault_spot_token;
  readonly qty: HexNumber;
}

export interface GetMainVault_mainVault {
  readonly __typename: "Vault";
  /**
   * Tells if the vault exists on various chains
   */
  readonly existsOn: ReadonlyArray<Chain>;
  /**
   * Address of main vault
   */
  readonly address: HexString;
  /**
   * The spot assets in the vault
   */
  readonly spot: ReadonlyArray<GetMainVault_mainVault_spot>;
}

export interface GetMainVault {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetMainVault_mainVault;
}

export interface GetMainVaultVariables {
  readonly chain: Chain;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMainVaultAllChains
// ====================================================

export interface GetMainVaultAllChains_mainVault_spot_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly chain: Chain | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetMainVaultAllChains_mainVault_spot {
  readonly __typename: "TokenQty";
  readonly token: GetMainVaultAllChains_mainVault_spot_token;
  readonly qty: HexNumber;
}

export interface GetMainVaultAllChains_mainVault {
  readonly __typename: "Vault";
  /**
   * Tells if the vault exists on various chains
   */
  readonly existsOn: ReadonlyArray<Chain>;
  /**
   * Address of main vault
   */
  readonly address: HexString;
  /**
   * The spot assets in the vault
   */
  readonly spot: ReadonlyArray<GetMainVaultAllChains_mainVault_spot>;
}

export interface GetMainVaultAllChains {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetMainVaultAllChains_mainVault;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMainVaultSummary
// ====================================================

export interface GetMainVaultSummary_mainVault {
  readonly __typename: "Vault";
  /**
   * Creation date of the vault
   */
  readonly creationDate: DateTime | null;
  /**
   * Tells if the vault exists on various chains
   */
  readonly existsOn: ReadonlyArray<Chain>;
  /**
   * Address of main vault
   */
  readonly address: HexString;
}

export interface GetMainVaultSummary {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetMainVaultSummary_mainVault;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllPendingOrders
// ====================================================

export interface GetAllPendingOrders_pendingOrders_results_StopLimitOrder_input_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly name: string;
  readonly decimals: number;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
}

export interface GetAllPendingOrders_pendingOrders_results_StopLimitOrder_input {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: GetAllPendingOrders_pendingOrders_results_StopLimitOrder_input_token;
}

export interface GetAllPendingOrders_pendingOrders_results_StopLimitOrder_output_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly name: string;
  readonly decimals: number;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
}

export interface GetAllPendingOrders_pendingOrders_results_StopLimitOrder_output {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: GetAllPendingOrders_pendingOrders_results_StopLimitOrder_output_token;
}

export interface GetAllPendingOrders_pendingOrders_results_StopLimitOrder {
  readonly __typename: "StopLimitOrder";
  readonly id: string;
  readonly input: GetAllPendingOrders_pendingOrders_results_StopLimitOrder_input;
  readonly output: GetAllPendingOrders_pendingOrders_results_StopLimitOrder_output;
  readonly protocol: string;
  /**
   * Id of the user who created the pending order
   */
  readonly userId: string;
  /**
   * Id of the vault who created the pending order
   */
  readonly vault: VaultId;
  readonly creationDate: DateTime;
  /**
   * Raw transaction to cancel the pending order
   */
  readonly cancelTx: JSON;
  /**
   * Price at which the stop limit will be executed
   */
  readonly triggerPrice: number;
  /**
   * Type of the transaction
   */
  readonly type: TransactionType;
}

export interface GetAllPendingOrders_pendingOrders_results_DcaOrder_input_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly name: string;
  readonly decimals: number;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
}

export interface GetAllPendingOrders_pendingOrders_results_DcaOrder_input {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: GetAllPendingOrders_pendingOrders_results_DcaOrder_input_token;
}

export interface GetAllPendingOrders_pendingOrders_results_DcaOrder_output_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly name: string;
  readonly decimals: number;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly chain: Chain | null;
  readonly symbol: string;
}

export interface GetAllPendingOrders_pendingOrders_results_DcaOrder_output {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: GetAllPendingOrders_pendingOrders_results_DcaOrder_output_token;
}

export interface GetAllPendingOrders_pendingOrders_results_DcaOrder {
  readonly __typename: "DcaOrder";
  readonly id: string;
  readonly input: GetAllPendingOrders_pendingOrders_results_DcaOrder_input;
  readonly output: GetAllPendingOrders_pendingOrders_results_DcaOrder_output;
  readonly protocol: string;
  /**
   * Id of the user who created the pending order
   */
  readonly userId: string;
  /**
   * Id of the vault who created the pending order
   */
  readonly vault: VaultId;
  readonly creationDate: DateTime;
  /**
   * Raw transaction to cancel the pending order
   */
  readonly cancelTx: JSON;
  /**
   * Initial start block of the dca
   */
  readonly initialStart: HexNumber;
  /**
   * Number of blocks between each dca
   */
  readonly intervalMinSize: HexNumber;
  /**
   * Dca will be executed only if the price is lower than this value
   */
  readonly lowerThanPrice: number | null;
  /**
   * Maximum number of dca to execute
   */
  readonly maxIntervals: HexNumber;
  /**
   * Dca will be executed only if the price is lower than this value
   */
  readonly greaterThanPrice: number | null;
  /**
   * Block number when the order expires
   */
  readonly expiryBlock: HexNumber | null;
  /**
   * Type of the transaction
   */
  readonly type: TransactionType;
}

export type GetAllPendingOrders_pendingOrders_results = GetAllPendingOrders_pendingOrders_results_StopLimitOrder | GetAllPendingOrders_pendingOrders_results_DcaOrder;

export interface GetAllPendingOrders_pendingOrders {
  readonly __typename: "PaginatedPendingOrders";
  readonly totalCount: number;
  readonly results: ReadonlyArray<GetAllPendingOrders_pendingOrders_results>;
}

export interface GetAllPendingOrders {
  /**
   * Get pending orders
   */
  readonly pendingOrders: GetAllPendingOrders_pendingOrders;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefreshBrinkOrders
// ====================================================

export interface RefreshBrinkOrders {
  /**
   * Refresh brink orders for the given vault
   */
  readonly refreshBrinkOrders: number;
}

export interface RefreshBrinkOrdersVariables {
  readonly chain: Chain;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindTokens
// ====================================================

export interface FindTokens_tokens_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface FindTokens_tokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Quote graph
   */
  readonly curves: FindTokens_tokens_curves;
}

export interface FindTokens {
  /**
   * Search for tokens
   */
  readonly tokens: ReadonlyArray<FindTokens_tokens>;
}

export interface FindTokensVariables {
  readonly chain?: Chain | null;
  readonly preferChain?: Chain | null;
  readonly search?: string | null;
  readonly group?: TokenGroup | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindTrendingTokens
// ====================================================

export interface FindTrendingTokens_tokens_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface FindTrendingTokens_tokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Quote graph
   */
  readonly curves: FindTrendingTokens_tokens_curves;
}

export interface FindTrendingTokens {
  /**
   * Search for tokens
   */
  readonly tokens: ReadonlyArray<FindTrendingTokens_tokens>;
}

export interface FindTrendingTokensVariables {
  readonly chain?: Chain | null;
  readonly preferChain?: Chain | null;
  readonly group?: TokenGroup | null;
  readonly ids?: ReadonlyArray<ChainAddress> | null;
  readonly limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindFavoritesTokens
// ====================================================

export interface FindFavoritesTokens_tokensAggregated_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface FindFavoritesTokens_tokensAggregated_aggregatedTokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly decimals: number;
}

export interface FindFavoritesTokens_tokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly color: string | null;
  readonly logo: string | null;
  readonly name: string;
  readonly symbol: string;
  readonly chainIds: ReadonlyArray<ERC20>;
  /**
   * Quote graph
   */
  readonly curves: FindFavoritesTokens_tokensAggregated_curves | null;
  /**
   * Tokens from different chains that have been aggregated into this one
   */
  readonly aggregatedTokens: ReadonlyArray<FindFavoritesTokens_tokensAggregated_aggregatedTokens>;
}

export interface FindFavoritesTokens {
  /**
   * Get the aggregated token list
   */
  readonly tokensAggregated: ReadonlyArray<FindFavoritesTokens_tokensAggregated>;
}

export interface FindFavoritesTokensVariables {
  readonly chains?: ReadonlyArray<Chain> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetFavorite
// ====================================================

export interface SetFavorite {
  /**
   * Add/remove a favorite token
   */
  readonly setFavorite: boolean;
}

export interface SetFavoriteVariables {
  readonly id: string;
  readonly isFavorite: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTrendingTokens
// ====================================================

export interface GetTrendingTokens_tokens_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
}

export interface GetTrendingTokens_tokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  readonly decimals: number;
  /**
   * Coingecko ranking of this token
   */
  readonly cgRanking: number | null;
  /**
   * Quote graph
   */
  readonly curves: GetTrendingTokens_tokens_curves;
}

export interface GetTrendingTokens {
  /**
   * Search for tokens
   */
  readonly tokens: ReadonlyArray<GetTrendingTokens_tokens>;
}

export interface GetTrendingTokensVariables {
  readonly timeframe: Timeframe;
  readonly limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTokensCount
// ====================================================

export interface GetTokensCount {
  /**
   * Get the aggregated token list count
   */
  readonly tokensAggregatedCount: number;
  /**
   * Get the aggregated token list count
   */
  readonly favoritesCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchTokens
// ====================================================

export interface GetSearchTokens_tokensAggregated {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  readonly chainIds: ReadonlyArray<ERC20>;
}

export interface GetSearchTokens {
  /**
   * Get the aggregated token list
   */
  readonly tokensAggregated: ReadonlyArray<GetSearchTokens_tokensAggregated>;
  /**
   * Get the aggregated token list count
   */
  readonly tokensAggregatedCount: number;
}

export interface GetSearchTokensVariables {
  readonly search?: string | null;
  readonly limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchCoin
// ====================================================

export interface SearchCoin_tokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  readonly chain: Chain | null;
  readonly decimals: number;
}

export interface SearchCoin {
  /**
   * Search for tokens
   */
  readonly tokens: ReadonlyArray<SearchCoin_tokens>;
}

export interface SearchCoinVariables {
  readonly search?: string | null;
  readonly limit: number;
  readonly chain?: Chain | null;
  readonly ids?: ReadonlyArray<ChainAddress> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserUsdBalance
// ====================================================

export interface GetUserUsdBalance_myUser {
  readonly __typename: "MassUser";
  readonly walletValue: number;
}

export interface GetUserUsdBalance {
  /**
   * Get my main vault on the given chain
   */
  readonly myUser: GetUserUsdBalance_myUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserBalances
// ====================================================

export interface GetUserBalances_myUser_walletBalances_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Specificities of this token (stablecoin, ...)
   */
  readonly flags: ReadonlyArray<TokenFlags>;
  readonly chain: Chain | null;
}

export interface GetUserBalances_myUser_walletBalances {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: GetUserBalances_myUser_walletBalances_token;
}

export interface GetUserBalances_myUser {
  readonly __typename: "MassUser";
  readonly walletBalances: ReadonlyArray<GetUserBalances_myUser_walletBalances>;
}

export interface GetUserBalances {
  /**
   * Get my main vault on the given chain
   */
  readonly myUser: GetUserBalances_myUser | null;
}

export interface GetUserBalancesVariables {
  readonly tokens?: ReadonlyArray<ERC20> | null;
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccountInfo
// ====================================================

export interface GetUserAccountInfo_myUser_walletBalances_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Specificities of this token (stablecoin, ...)
   */
  readonly flags: ReadonlyArray<TokenFlags>;
  readonly chain: Chain | null;
}

export interface GetUserAccountInfo_myUser_walletBalances {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: GetUserAccountInfo_myUser_walletBalances_token;
}

export interface GetUserAccountInfo_myUser {
  readonly __typename: "MassUser";
  readonly walletBalances: ReadonlyArray<GetUserAccountInfo_myUser_walletBalances>;
  readonly walletValue: number;
}

export interface GetUserAccountInfo_mainVault_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface GetUserAccountInfo_mainVault {
  readonly __typename: "Vault";
  /**
   * Quote graph
   */
  readonly curves: GetUserAccountInfo_mainVault_curves;
}

export interface GetUserAccountInfo {
  /**
   * Get my main vault on the given chain
   */
  readonly myUser: GetUserAccountInfo_myUser | null;
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetUserAccountInfo_mainVault;
}

export interface GetUserAccountInfoVariables {
  readonly tokens?: ReadonlyArray<ERC20> | null;
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAggregatedHoldings
// ====================================================

export interface GetAggregatedHoldings_mainVault_spot_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface GetAggregatedHoldings_mainVault_spot {
  readonly __typename: "TokenQty";
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly qty: HexNumber;
  readonly token: GetAggregatedHoldings_mainVault_spot_token;
}

export interface GetAggregatedHoldings_mainVault {
  readonly __typename: "Vault";
  /**
   * The spot assets in the vault
   */
  readonly spot: ReadonlyArray<GetAggregatedHoldings_mainVault_spot>;
}

export interface GetAggregatedHoldings {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: GetAggregatedHoldings_mainVault;
}

export interface GetAggregatedHoldingsVariables {
  readonly chain?: Chain | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchMainVaultAddress
// ====================================================

export interface FetchMainVaultAddress_mainVault {
  readonly __typename: "Vault";
  /**
   * Address of main vault
   */
  readonly address: HexString;
  /**
   * Salt to use to create the main vault
   */
  readonly salt: HexString;
}

export interface FetchMainVaultAddress {
  /**
   * Get info about a vault chain
   */
  readonly mainVault: FetchMainVaultAddress_mainVault;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTokenQuote
// ====================================================

export interface GetTokenQuote_token {
  readonly __typename: "TokenInfo";
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
}

export interface GetTokenQuote {
  /**
   * Get info about a token
   */
  readonly token: GetTokenQuote_token | null;
}

export interface GetTokenQuoteVariables {
  readonly id: ERC20;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMultiTokensQuotes
// ====================================================

export interface GetMultiTokensQuotes_tokens {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
}

export interface GetMultiTokensQuotes {
  /**
   * Search for tokens
   */
  readonly tokens: ReadonlyArray<GetMultiTokensQuotes_tokens>;
}

export interface GetMultiTokensQuotesVariables {
  readonly ids: ReadonlyArray<ChainAddress>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadTokenBalance
// ====================================================

export interface LoadTokenBalance_myUser_walletBalances {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
}

export interface LoadTokenBalance_myUser {
  readonly __typename: "MassUser";
  readonly walletBalances: ReadonlyArray<LoadTokenBalance_myUser_walletBalances>;
}

export interface LoadTokenBalance_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
}

export interface LoadTokenBalance {
  /**
   * Get my main vault on the given chain
   */
  readonly myUser: LoadTokenBalance_myUser | null;
  /**
   * Get info about a token
   */
  readonly token: LoadTokenBalance_token | null;
}

export interface LoadTokenBalanceVariables {
  readonly token: ERC20;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTxStatus
// ====================================================

export interface GetTxStatus_transaction {
  readonly __typename: "GProcessedTx";
  /**
   * ID of the transaction
   */
  readonly id: TxId;
}

export interface GetTxStatus {
  /**
   * Get a transaction by its id
   */
  readonly transaction: GetTxStatus_transaction | null;
}

export interface GetTxStatusVariables {
  readonly tx: TxId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITokenSummary
// ====================================================

export interface ITokenSummary {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITokenSummaryWithQuote
// ====================================================

export interface ITokenSummaryWithQuote {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITokenWithHistory
// ====================================================

export interface ITokenWithHistory_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface ITokenWithHistory {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Quote graph
   */
  readonly curves: ITokenWithHistory_curves;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IHoldingWithQuote
// ====================================================

export interface IHoldingWithQuote_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly chain: Chain | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
}

export interface IHoldingWithQuote {
  readonly __typename: "TokenQty";
  readonly token: IHoldingWithQuote_token;
  readonly qty: HexNumber;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IHoldingWithQuoteAndHistory
// ====================================================

export interface IHoldingWithQuoteAndHistory_token_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface IHoldingWithQuoteAndHistory_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  readonly chain: Chain | null;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Quote graph
   */
  readonly curves: IHoldingWithQuoteAndHistory_token_curves;
}

export interface IHoldingWithQuoteAndHistory {
  readonly __typename: "TokenQty";
  readonly token: IHoldingWithQuoteAndHistory_token;
  readonly qty: HexNumber;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IGraphHistoryFrame
// ====================================================

export interface IGraphHistoryFrame {
  readonly __typename: "HistoryFrame";
  /**
   * Current spot value
   */
  readonly value: number;
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
  /**
   * Computes a performance graph, which is independent from the porfolio nominal value changes.
   * 
   *         It describes how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will be a graph of values between -1 and +∞
   */
  readonly perfGraph: DiscontinuousPointArray;
  /**
   * Tells how much has been earned.
   * 
   *         If the composition has been changed, then the changes deltas will not be taken into account
   */
  readonly pnl: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IChainDataPortfolio
// ====================================================

export interface IChainDataPortfolio_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Current spot value
   */
  readonly value: number;
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
  /**
   * History, as curve values
   */
  readonly graph: PointArray;
  /**
   * Computes a performance graph, which is independent from the porfolio nominal value changes.
   * 
   *         It describes how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will be a graph of values between -1 and +∞
   */
  readonly perfGraph: DiscontinuousPointArray;
  /**
   * Tells how much has been earned.
   * 
   *         If the composition has been changed, then the changes deltas will not be taken into account
   */
  readonly pnl: number;
}

export interface IChainDataPortfolio_spot_token_curves {
  readonly __typename: "HistoryFrame";
  /**
   * Total performance...
   * 
   *         i.e. how much you would gain if you were to invest 1 unit of currency at the begining, following the same investment strategies as this asset repartition.
   * 
   *         👉 This will a number between -1 and +∞
   */
  readonly totalPerf: number;
}

export interface IChainDataPortfolio_spot_token {
  readonly __typename: "TokenInfo";
  readonly category: LocalizedString;
  readonly chain: Chain | null;
  /**
   * Specificities of this token (stablecoin, ...)
   */
  readonly flags: ReadonlyArray<TokenFlags>;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  /**
   * Quote graph
   */
  readonly curves: IChainDataPortfolio_spot_token_curves;
}

export interface IChainDataPortfolio_spot {
  readonly __typename: "TokenQty";
  readonly token: IChainDataPortfolio_spot_token;
  readonly qty: HexNumber;
}

export interface IChainDataPortfolio {
  readonly __typename: "Vault";
  /**
   * Quote graph
   */
  readonly curves: IChainDataPortfolio_curves;
  /**
   * The spot assets in the vault
   */
  readonly spot: ReadonlyArray<IChainDataPortfolio_spot>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITokenAggregatedSummary
// ====================================================

export interface ITokenAggregatedSummary {
  readonly __typename: "TokenAggregatedInfo";
  readonly id: AggregatedTokenId;
  readonly symbol: string;
  readonly name: string;
  readonly logo: string | null;
  readonly chainIds: ReadonlyArray<ERC20>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IMyUserBalances
// ====================================================

export interface IMyUserBalances_walletBalances_token {
  readonly __typename: "TokenInfo";
  readonly id: ERC20;
  /**
   * Value of 1 unit of the given token, valued in the given currency
   */
  readonly quote: number;
  /**
   * Get the Coingecko aggregated ID of this token, if any (Coingecko aggregates pegged tokens on all chains)
   */
  readonly aggregatedId: AggregatedTokenId | null;
  readonly decimals: number;
  readonly color: string | null;
  readonly logo: string | null;
  /**
   * Token status. If this is not null, then this token is not listed, and you might take a closer look at this 
   */
  readonly status: TokenStatus | null;
  readonly name: string;
  readonly symbol: string;
  readonly category: LocalizedString;
  /**
   * Specificities of this token (stablecoin, ...)
   */
  readonly flags: ReadonlyArray<TokenFlags>;
  readonly chain: Chain | null;
}

export interface IMyUserBalances_walletBalances {
  readonly __typename: "TokenQty";
  readonly qty: HexNumber;
  /**
   * Quantity, as a number (i.e. with decimals taken into account)
   */
  readonly qtyNum: number;
  readonly token: IMyUserBalances_walletBalances_token;
}

export interface IMyUserBalances {
  readonly __typename: "MassUser";
  readonly walletBalances: ReadonlyArray<IMyUserBalances_walletBalances>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Chain {
  arbi = "arbi",
  avax = "avax",
  base = "base",
  bsc = "bsc",
  eth = "eth",
  opti = "opti",
  poly = "poly",
}

export enum LimitOrderStatus {
  cancelled = "cancelled",
  expired = "expired",
  filled = "filled",
  invalid = "invalid",
  partially_filled = "partially_filled",
  pending = "pending",
}

export enum MagicAuthTypes {
  apple = "apple",
  email = "email",
  google = "google",
  phone = "phone",
  twitter = "twitter",
}

export enum Timeframe {
  all = "all",
  p1d = "p1d",
  p1m = "p1m",
  p1w = "p1w",
  p1y = "p1y",
  p5y = "p5y",
  p6m = "p6m",
}

export enum TokenActivityType {
  buy = "buy",
  sell = "sell",
}

export enum TokenFlags {
  defaultStablecoin = "defaultStablecoin",
  recommendedStablecoin = "recommendedStablecoin",
  stablecoin = "stablecoin",
  tradableCoin = "tradableCoin",
}

export enum TokenGroup {
  mostAdded = "mostAdded",
  trending = "trending",
}

export enum TokenOrder {
  marketCap = "marketCap",
  perf24h = "perf24h",
  rank = "rank",
}

export enum TokenStatus {
  blacklisted = "blacklisted",
  deflationary = "deflationary",
  nodecimals = "nodecimals",
  noname = "noname",
  rebase = "rebase",
  unknown = "unknown",
}

export enum TransactionType {
  bridge = "bridge",
  dcaOrder = "dcaOrder",
  deposit = "deposit",
  limitOrder = "limitOrder",
  limitOrderCancel = "limitOrderCancel",
  multibuy = "multibuy",
  multisell = "multisell",
  stopLimitOrder = "stopLimitOrder",
  swap = "swap",
  unknown = "unknown",
  vaultCreation = "vaultCreation",
  withdraw = "withdraw",
}

export interface GLimitOrdersFilters {
  readonly vault?: VaultId | null;
  readonly makerAsset?: ERC20 | null;
  readonly takerAsset?: ERC20 | null;
}

export interface MagicAuth {
  readonly type: MagicAuthTypes;
  readonly email?: string | null;
  readonly phone?: string | null;
}

export interface PaginationArgs {
  readonly offset: number;
  readonly limit: number;
}

export interface TokenOrderFilter {
  readonly sortBy?: TokenOrder | null;
  readonly descending?: boolean | null;
}

export interface TxFilter {
  readonly type?: TransactionType | null;
  readonly token?: ERC20 | null;
  readonly inputToken?: ERC20 | null;
  readonly outputToken?: ERC20 | null;
  readonly aggregatedToken?: AggregatedTokenId | null;
  readonly inputAggregatedToken?: AggregatedTokenId | null;
  readonly outputAggregatedToken?: AggregatedTokenId | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
