import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { DEFAULT_CHAIN } from '@constants';
import { GetSpotAccountHoldings, GetSpotAccountHoldingsVariables, ITokenSummary, Timeframe, Chain } from '@gql';
import { refreshMainVaultDataAtom, refreshMainVaultData } from '@hooks/useMainVault';
import { getTokenInfo } from '@hooks/useTokensInfo';
import { GET_CURRENT_ACCOUNT_HOLDINGS } from '@queries/account.queries';
import { getUserAggregatedHoldings } from '@queries/user.queries';
import { useCreateOrCallVault } from '@tetris/tx';
import { useDca, DcaTimeframes } from '@tetris/useDca';
import {
  Loader,
  chainOf,
  useTokenQuote,
  holdingQuote,
  TokenBudget,
  parseNum,
  ICoin,
  convertAmt,
  withoutChain,
  Budget,
} from '@utils';
import { useWallet } from '@wallet-hooks';

import { DcaUI } from './DcaUI';
import { supportedPairs } from './constants';
import { useChangeCoin } from '../../../hooks/useChangeCoin';
import { usePriceImpact } from '../../../hooks/usePriceImpact';
import { useTransactionFlow } from '../../../hooks/useTransactionFlow';
import { DcaProps } from '../../../types';

const getSupportedChain = (chain: Chain | nil): Chain => (chain === Chain.arbi ? Chain.arbi : DEFAULT_CHAIN);

export const Dca = ({ prefillInputTokenId, prefillOutputTokenId }: DcaProps) => {
  const wallet$ = useWallet(true);
  const [timeframe, setTimeframe] = useState<DcaTimeframes>(DcaTimeframes.Days);
  const [every, setEvery] = useState<number>(1);
  const [over, setOver] = useState<number>(30);
  const { currentChain, resetScroll } = useTransactionFlow();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const refreshWhenChanges = useRecoilValue(refreshMainVaultDataAtom);
  const currentChain$ = Loader.useWrap(currentChain);
  const authorizedCoins = currentChain$.match
    .notOk(() => [] as ChainAddress[])
    .ok(chain => {
      const supportedChain = getSupportedChain(chain);
      return [...supportedPairs[supportedChain][0], ...supportedPairs[supportedChain][1]].map(
        address => `${supportedChain}:${address}`,
      ) as ChainAddress[];
    });
  const changeSourceCoin = useChangeCoin(false, { onlyOwned: true });
  const changeTargetCoin = useChangeCoin(false, { onlyCoins: authorizedCoins });

  const { t } = useTranslation();
  // TODO(Hadrien) : Adjust fees to input per cycle amount => 4$ per cycle converted into fees %
  // TODO(Hadrien) : Validate loading/error states
  // TODO(Hadrien) : Protocol fees should probably come from the API
  const [inputBudget$, setInputBudget] = Loader.useWrap(prefillInputTokenId)
    // if we have a specified input, then skip the balance getting
    .map(x => (x ? Loader.skipped : true))
    // wait current chain
    .combine(currentChain$, (_, chain) => chain)
    .map(getUserAggregatedHoldings)
    // fetch the highest balance token
    .map<ITokenSummary | nil>(x => {
    const tokenWithBalance = x.mainVault.spot
      .map(balance => ({
        ...balance,
        value: balance.qtyNum * balance.token.quote,
      }))
      .sort((a, b) => b.value - a.value);

    return tokenWithBalance && tokenWithBalance.length ? tokenWithBalance[0]?.token : null;
  })
    // in case we have a prefilled token, then get its details
    .mapNotLoaded('skipped', () => null)
    .combine(currentChain$, (x, chain) => {
      if (x) return x;
      const supportedChain = getSupportedChain(chain);
      let prefill: string;
      if (prefillInputTokenId) {
        const prefillAddress = withoutChain(prefillInputTokenId);
        prefill =
          supportedPairs[supportedChain][0].find(p => p.includes(prefillAddress)) ||
          supportedPairs[supportedChain][1].find(p => p.includes(prefillAddress)) ||
          supportedPairs[supportedChain][0][0];
      } else {
        prefill = supportedPairs[supportedChain][0][0];
      }
      return getTokenInfo(`${supportedChain}:${prefill}` as ChainAddress);
    })
    .map(x => x || Loader.error('No token found'))
    // build a budget out of it
    .map<TokenBudget>(token => ({ token, amtBase: 0n }))
    .asState<TokenBudget | nil>();

  const inputTokenId = inputBudget$.match.notOk(() => '').ok(budget => budget?.token?.id || '') as ChainAddress;

  // build the output token state
  const [outputToken$, setOutputToken] = Loader.array([prefillOutputTokenId, currentChain$] as const)
    .map(([outputTokId, chain]) => {
      const supportedChain = getSupportedChain(chain);
      let prefill: string;
      if (outputTokId) {
        const prefillAddress = withoutChain(outputTokId);
        prefill =
          supportedPairs[supportedChain][1].find(p => p.includes(prefillAddress)) ||
          supportedPairs[supportedChain][0].find(p => p.includes(prefillAddress)) ||
          supportedPairs[supportedChain][0][0];
      } else {
        prefill = supportedPairs[supportedChain][1][0];
      }
      return getTokenInfo(`${supportedChain}:${prefill}` as ChainAddress);
    })
    .asState<ICoin>();
  const outputTokenId = outputToken$.match.notOk(() => '').ok(budget => budget?.id || '') as ChainAddress;

  // get quotes of budgets
  const inputQuote$ = useTokenQuote(inputTokenId);
  const outputQuote$ = useTokenQuote(outputTokenId);
  const priceImpact$ = usePriceImpact([inputTokenId, outputTokenId, inputBudget$] as const);
  const marketPrice$ = Loader.array([inputQuote$, outputQuote$] as const).map(([inp, out]) => {
    if (!out || !inp) {
      return Loader.skipped;
    }
    return inp / out;
  });

  const accountHoldings$ = wallet$
    .query<GetSpotAccountHoldings, GetSpotAccountHoldingsVariables>(
    [refreshWhenChanges],
    GET_CURRENT_ACCOUNT_HOLDINGS,
    () => ({
      frame: Timeframe.p1d,
    }),
  )
    .map(({ mainVault }) => mainVault.spot.map(({ token, qty }) => ({ token, qty })) || [])
    .map(holdings => holdings.sort((a, b) => holdingQuote(b) - holdingQuote(a)));

  const inputBalance$ = accountHoldings$.combine(inputBudget$, (holdings, inp) => {
    const q = holdings.find(tok => tok.token.id === inp?.token?.id)?.qty;
    return parseNum(q) ?? 0n;
  });

  const inputBudgetWithQuote$ = Loader.array([inputBudget$, inputQuote$] as const).map(([inp, quote]) => {
    if (!inp) return null;
    return { ...inp, quote: quote ?? 0 };
  });

  const [lowerRate, setLowerRate] = outputToken$
    .map(_output => ({ token: _output, amtBase: 0n } as Budget<ICoin>))
    .asState();
  const [higherRate, setHigherRate] = outputToken$
    .map(_output => ({ token: _output, amtBase: 0n } as Budget<ICoin>))
    .asState();

  // ---------------------- PREPARE TRANSACTION ----------------------
  const chain$ = inputBudget$.map(x => getSupportedChain(chainOf(x?.token?.id)));

  const dca = useDca({
    input: inputBudgetWithQuote$,
    output: outputToken$,
    chain: chain$,
    timeframe,
    interval: every,
    maxIntervals: over,
    lowerRate,
    higherRate,
  });

  const { sendTx, operation } = useCreateOrCallVault(chain$, [dca], {
    failedToast: {
      title: t('Screens.Dca.toastTxFailedTitle'),
      content: t('Screens.Dca.toastTxFailedContent'),
    },
  });

  const resetBudget = Loader.array([inputBudget$, chain$] as const).makeCallback(async ([_input, _chain]) => {
    if (_input) setInputBudget({ ..._input, amtBase: 0n });
    const supportedChain = getSupportedChain(_chain);
    const prefill = supportedPairs[supportedChain][1][0];
    const defaultOutput = await getTokenInfo(`${supportedChain}:${prefill}` as ChainAddress);
    if (defaultOutput) setOutputToken(defaultOutput);
    setTimeframe(DcaTimeframes.Days);
  });

  operation.onOk(() => {
    try {
      resetBudget();
      refreshMainVaultData();
    } catch (e) {
      // TODO(Hadrien) : Handle error
    }
  });

  return (
    <DcaUI
      inputRef={inputRef}
      inputBudgetWithQuote={inputBudgetWithQuote$}
      outputToken={outputToken$}
      marketPrice={marketPrice$}
      priceImpact={priceImpact$}
      lowerRate={lowerRate}
      setLowerRate={setLowerRate}
      higherRate={higherRate}
      setHigherRate={setHigherRate}
      timeframe={timeframe}
      every={every}
      over={over}
      inputAvailable={inputBalance$}
      setInputBudget={setInputBudget}
      setOutputToken={setOutputToken}
      setTimeframe={setTimeframe}
      setEvery={setEvery}
      setOver={setOver}
      onSourceCoinSelect={() =>
        changeSourceCoin(async coin => {
          if (!coin) return;
          resetScroll();
          setInputBudget(prev => ({
            token: coin,
            amtBase: convertAmt(prev?.amtBase || 0n, prev?.token?.decimals || coin.decimals, coin.decimals),
          }));
          setTimeout(() => inputRef.current?.focus(), 100);
        })
      }
      onTargetCoinSelect={() =>
        changeTargetCoin(async coin => {
          if (!coin) return;
          resetScroll();
          setOutputToken(coin);
        })
      }
      sendTx={sendTx}
      isOperationLoading={operation.isLoading}
    />
  );
};
