import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useMainVault, refreshMainVaultData } from '@hooks/useMainVault';
import { useTokenInfo, useTokensInfo } from '@hooks/useTokensInfo';
import { useTxManagerAlerts } from '@hooks/useTxManagerAlerts';
import { AggregatorRequest } from '@tetris/dexes/dex-aggregator-types';
import { useMultiSwap } from '@tetris/swaps';
import { useCreateOrCallVault } from '@tetris/tx';
import { SendTxButton } from '@transactions/components/SendTxButton';
import TransactionRecap, { MultipleTxData } from '@transactions/components/TransactionRecap';
import IncentiveMessage from '@transactions/components/ui/IncentiveMessage';
import { BudgetAllocation } from '@transactions/components/ui/MultiPercentInputEntry';
import { PercentInputEntryProps } from '@transactions/components/ui/PercentInputEntry';
import { useChangeCoin } from '@transactions/hooks/useChangeCoin';
import { useHighestHolding } from '@transactions/hooks/useHighestHolding';
import { useLimitOrdersList } from '@transactions/hooks/useLimitOrdersList';
import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { TransactionFlowType, UnfairLevel } from '@transactions/types';
import { getUnfairLevelForPriceDiff, computePriceDiff } from '@transactions/utils';
import { Alert, AlertLink } from '@ui-kit/atoms/Alert';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { TxType, TxSpeed } from '@ui-kit/organisms/SingleTxReviewContent';
import {
  ICoin,
  Loader,
  NATIVE_TOKENS,
  chainOf,
  encodeAddress,
  getDefaultTokenForChain,
  getTransactionDetailsUrl,
  makeChainAddress,
  parseAddress,
  parseNum,
  safeMult,
  toNumber,
  useTokenQuote,
  useTokensQuotes,
  withoutChain,
  wrapToken,
} from '@utils';
import { useWallet } from '@wallet';

import MultiBuyUI from './MultiBuyUI';
import { MultiBuyAction, MultiBuyState, defaultMultiBuyState, multiBuyReducer } from './multiBuy.reducer';
import { PERCENT_PRECISION, allocateRemainings, applyPercent } from './multiBuy.utils';
import { getTransactionRecap } from '../../swap/swapFlow.utils';
import { getTargetBudgetEstimate } from '../multiSell/multiSell.utils';

const ZERO_PERCENT = { value: 0n, precision: PERCENT_PRECISION };

export default function MultiBuyFlow() {
  const { t } = useTranslation();
  const { lastTransaction } = useTxManagerAlerts();
  const { currentChain, setCurrentChain, settings, setCurrentFlow, resetScroll } = useTransactionFlow();
  const [refresh, setRefresh] = useState(false);
  const wallet$ = useWallet(false);
  const login = wallet$.makeCallback(({ login: l }) => l());

  const nativeToken$ = useTokenInfo(
    currentChain ? NATIVE_TOKENS[currentChain] : getDefaultTokenForChain(currentChain),
    true,
  );

  const defaultTokenId$ = useHighestHolding(currentChain)
    .map(holding => holding?.token.id)
    .mapNotLoaded('skipped', [currentChain], () => getDefaultTokenForChain(currentChain))
    .map(_tokenId => _tokenId || getDefaultTokenForChain(currentChain));

  const limitOrders$ = useLimitOrdersList();

  const [state$, dispatch] = defaultTokenId$
    .map<MultiBuyState>(id => ({
    ...defaultMultiBuyState,
    inputTokenId: id,
  }))
    .noFlickering()
    .asReducer<MultiBuyAction>(multiBuyReducer);

  const isInputTokenUsedInLimitOrders$ = Loader.array([limitOrders$, state$] as const).map(([_limitOrders, _state]) =>
    _limitOrders.limitOrders.results.some(limitOrder => _state.inputTokenId === limitOrder.make.token.id),
  );

  defaultTokenId$.onOk(function handleChainChange(_defaultTokenId) {
    if (_defaultTokenId) {
      (async () => setCurrentChain(chainOf(_defaultTokenId)))().catch(e => e);
      dispatch({
        type: 'SET_DEFAULT',
        payload: {
          inputTokenId: _defaultTokenId,
        },
      });
    }
  });

  const holdings$ = useMainVault(false, false)
    .map(vault => {
      return vault?.spot || [];
    })
    .mapNotLoaded('skipped', () => []);

  const inputTokenId$ = state$.map(s => s.inputTokenId);
  const inputTokenInfo$ = useTokenInfo(inputTokenId$).noFlickering();
  const inputTokenQuote$ = useTokenQuote(inputTokenId$).noFlickering();
  const inputBudget$ = Loader.array([
    inputTokenInfo$,
    inputTokenQuote$,
    state$.map(s => s.inputTokenAmount),
  ] as const).map(([tokenInfo, quote, inputAmt]) => ({
    token: tokenInfo,
    amtBase: inputAmt,
    quote,
  }));

  const inputHoldings$ = Loader.array([inputTokenInfo$, holdings$] as const).map(([tokenInfo, holdings]) => {
    const holding = holdings.find(h => h.token.id === tokenInfo.id);
    return holding ? parseNum(holding.qty) : 0n;
  });

  const insufficientBalance$ = Loader.array([inputBudget$, inputHoldings$] as const).map(([budget, holdings]) => {
    return budget.amtBase > holdings;
  });

  const inputHint$ = Loader.array([inputBudget$, insufficientBalance$] as const).map(([budget, insufficientBalance]) =>
    insufficientBalance
      ? {
        errorMessage: t('Transactions.Recap.insufficientBalance'),
      }
      : {
        usdValue: toNumber(safeMult(budget.amtBase, budget.quote), budget.token.decimals),
      },
  );

  const targetTokensArray$ = state$.map(s => Array.from(s.targetTokenIds));
  const targetTokensInfos$ = useTokensInfo(targetTokensArray$).noFlickering();
  const targetTokensQuotes$ = useTokensQuotes(targetTokensArray$).noFlickering();
  const targetDistribution$ = Loader.array([
    targetTokensInfos$,
    targetTokensQuotes$,
    inputBudget$,
    state$.map(s => s.targetTokenDistribution),
  ] as const).map(([tokensInfo, quotes, inputBudget, distribution]) => {
    return tokensInfo
      .map<BudgetAllocation | undefined>(tokenInfo =>
      distribution[tokenInfo.id]
        ? {
          budget: getTargetBudgetEstimate(
            [
              {
                ...inputBudget,
                amtBase: applyPercent(inputBudget.amtBase, distribution[tokenInfo.id]?.percent || ZERO_PERCENT),
              },
            ],
            { ...tokenInfo, quote: quotes.get(tokenInfo.id) },
          ),
          percent: distribution[tokenInfo.id]?.percent || 0n,
          locked: distribution[tokenInfo.id]?.isLocked || false,
        }
        : undefined,
    )
      .filter(Boolean) as BudgetAllocation[];
  });

  const targetHoldings$ = Loader.array([targetTokensInfos$, holdings$] as const).map(([tokensInfo, holdings]) => {
    return tokensInfo.reduce<Record<ChainAddress, bigint>>((acc, tokenInfo) => {
      const holding = holdings.find(h => h.token.id === tokenInfo.id);
      if (holding) {
        acc[tokenInfo.id] = parseNum(holding.qty);
      }
      return acc;
    }, {});
  });

  const selectedCoins = Loader.array([state$, targetTokensInfos$] as const)
    .map<[ChainAddress, ICoin][]>(([s, _targetTokensInfos]) =>
    Array.from(s.targetTokenIds).map(id => {
      return [id, _targetTokensInfos.find(_t => _t.id === id) as ICoin];
    }),
  )
    .match.notOk(() => new Map<ChainAddress, ICoin>())
    .ok(v => new Map<ChainAddress, ICoin>(v));

  const selectMultiCoin = useChangeCoin(true, {
    currentSelectedCoins: selectedCoins,
    disabledCoins: state$
      .map(s => s.inputTokenId)
      .match.notOk<{ [key: ChainAddress]: string }>(() => ({}))
      .ok<{ [key: ChainAddress]: string }>(v => (v ? { [v]: '' } : {})),
  });

  const selectCoin = useChangeCoin(false, {
    disabledCoins: targetTokensArray$
      .map(s => s.reduce((acc, key) => ({ ...acc, [key]: '' }), {} as { [key: ChainAddress]: string }))
      .unwrapOr<any>({}),
    onlyOwned: true,
  });

  const vault$ = useMainVault(false, true).mapNotLoaded('skipped', () => null);
  const vaultAddress$ = Loader.array([vault$, inputTokenInfo$] as const).map(([vault, targetTokenInfo]) => {
    if (!vault || !targetTokenInfo) {
      return undefined;
    }
    return encodeAddress(chainOf(targetTokenInfo.id), vault.address);
  });
  const chain$ = inputTokenInfo$.map(x => chainOf(x?.id));

  const inputBudgetWithMax$ = Loader.array([state$.map(s => s.isMax), inputBudget$, inputHoldings$] as const).map(
    ([isMax, budget, holdings]) => {
      return {
        ...budget,
        amtBase: isMax ? holdings : budget.amtBase,
      };
    },
  );

  const swapRequests$: Loader<AggregatorRequest[]> = Loader.array([
    targetDistribution$,
    inputBudgetWithMax$,
    vaultAddress$,
  ] as const)
    .map(([targetDistribution, inputBudget, _vaultAddress]) => {
      return targetDistribution
        .filter(a => a.percent.value > 0n)
        .map(allocation => {
          return {
            spendToken: inputBudget.token,
            buyToken: allocation.budget.token,
            slippage: settings.slippage,
            spendQty: applyPercent(inputBudget.amtBase, allocation.percent),
            // we need it to bypass chain token validation
            vaultAddress: _vaultAddress ?? `${chainOf(inputBudget.token.id)}:0x0`,
          };
        });
    })
    .combine(inputBudgetWithMax$, allocateRemainings)
    .combine(state$, (swapRequests, s) => {
      if (s.isSelectionConfirmed) return swapRequests;
      return Loader.skipped;
    });
  const preparedSwaps$ = useMultiSwap(swapRequests$, refresh);

  const { sendTx: send$, operation } = useCreateOrCallVault(chain$, preparedSwaps$);

  operation.onOk([dispatch], () => {
    dispatch({
      type: 'RESET_FLOW',
    });
    refreshMainVaultData();
  });

  operation.onError([dispatch], error => {
    const sentTx = lastTransaction?.hash;

    const failedLinks: AlertLink[] = [];

    const sendReportLink: AlertLink = {
      label: t('TransactionDetails.error.sendReport'),
      onClick: () => Sentry.captureException(error),
    };

    failedLinks.push(sendReportLink);

    if (sentTx && currentChain) {
      const explorerLink: AlertLink = {
        label: t('Transactions.ToastActions.explorer'),
        isExternal: true,
        url: getTransactionDetailsUrl(makeChainAddress(currentChain, sentTx)),
      };
      failedLinks.push(explorerLink);
    }

    dispatch({
      type: 'SET_ALERT',
      payload: {
        variant: 'error',
        title: t('Transactions.errors.multibuy.title'),
        content: t('Transactions.errors.multibuy.description', {
          token: inputBudget$.match.notOk(() => '').ok(budget => budget.token.symbol),
        }),
        links: failedLinks,
      },
    });
  });

  const WarningMessage = Loader.array([preparedSwaps$, send$] as const)
    .noFlickering()
    .match.loadingOrSkipped(() => null)
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        <IncentiveMessage
          type="error"
          title={t('Transactions.IncentiveMessage.insufficientLiquidityTitle')}
          description={t('Transactions.IncentiveMessage.insufficientLiquidityDescription')}
        />;
      }
      return null;
    })
    .ok(() => null);

  const sendTxButton = Loader.array([
    inputBudget$,
    targetDistribution$,
    preparedSwaps$,
    send$.map(s => s.sendNext),
    state$,
    isInputTokenUsedInLimitOrders$,
  ] as const)
    .match.skipped(() => null)
    .loading(() => (
      <Skeleton asOverlay>
        <Button size="l" label={t('Transactions.MultiSwap.confirmMultiSwap')} fullWidth disabled />
      </Skeleton>
    ))
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        return <Button size="l" label={t('Transactions.Recap.insufficientLiquidity')} fullWidth disabled />;
      }
      return <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />;
    })
    .ok(([inputBudget, targetDistribution, _swaps, _onConfirm, _state, _isInputTokenUsedInLimitOrders]) => {
      if (!_swaps) return null;

      if (_state?.alert)
        return (
          <Button
            size="l"
            label={t('Transactions.errors.multibuy.retry')}
            fullWidth
            onClick={async () => {
              dispatch({
                type: 'SET_ALERT',
                payload: null,
              });
              await _onConfirm();
            }}
          />
        );

      const unfairLevel = _swaps
        ? targetDistribution.reduce((acc, a) => {
          const swapResponse = _swaps.find(
            s =>
              s.rawResponse.buyTokenAddress.toLowerCase() === parseAddress(a.budget.token.id).address.toLowerCase(),
          );
          if (!swapResponse) return acc;
          return Math.max(
            acc,
            getUnfairLevelForPriceDiff(
              computePriceDiff(Number(swapResponse.rawResponse.price), inputBudget.quote, a.budget.quote),
            ),
          );
        }, 0)
        : 0;

      return (
        <SendTxButton
          sendTx={_onConfirm}
          isLoading={operation.isLoading}
          label={match(unfairLevel)
            .with(UnfairLevel.MEDIUM, () => t('Transactions.Swap.swapAnyway'))
            .otherwise(() => t('Transactions.MultiSwap.confirmMultiSwap'))}
          dataCy="AmountScreen_cta"
          hasMultipleTxs
          variant={_isInputTokenUsedInLimitOrders ? 'warning' : null}
          txDataList={targetDistribution.map(a => ({
            variant: TxType.swap,
            inputToken: inputBudget,
            outputToken: a.budget,
            fees: { total: 0, paraswap: 0, nested: 0, network: 0 },
            txSpeed: TxSpeed.normal,
            onSelectTxSpeed: () => {},
            onOpenSlippageSettings: () => {},
            rate: 0.03,
          }))}
          txSpeed={TxSpeed.normal}
          onSelectTxSpeed={() => {}}
          unfairLevel={unfairLevel}
        />
      );
    });

  const targetHints: Record<ChainAddress, PercentInputEntryProps['hint']> = targetDistribution$.match
    .notOk(() => ({}))
    .ok(distribution => {
      return distribution.reduce<Record<ChainAddress, PercentInputEntryProps['hint']>>((acc, { budget }) => {
        acc[budget.token.id] = { budget };
        return acc;
      }, {} as Record<ChainAddress, PercentInputEntryProps['hint']>);
    });

  const isConfirmed = state$
    .map(s => s.isSelectionConfirmed)
    .match.notOk(() => false)
    .ok(v => v);

  const hasInputBudget = inputBudget$.match.notOk(() => false).ok(b => b.amtBase > 0n);
  const hasTargetDistribution = targetDistribution$.match.notOk(() => false).ok(d => d.length > 0);
  const insufficientBalance = insufficientBalance$.match.notOk(() => false).ok(v => v);

  const recapData$ = Loader.array([inputBudget$, targetDistribution$, preparedSwaps$, nativeToken$] as const).map(
    ([_inputBudget, _targetDistribution, _swaps, _nativeToken]) => {
      return _swaps
        .map(_swap => {
          const targetAllocation = _targetDistribution.find(
            allocation =>
              withoutChain(wrapToken(allocation.budget.token.id)) === _swap.rawResponse.buyTokenAddress.toLowerCase(),
          );
          if (!targetAllocation) return null;
          return {
            sourceSymbol: _inputBudget.token.symbol,
            targetSymbol: targetAllocation.budget.token.symbol,
            data: getTransactionRecap(
              { ..._inputBudget, amtBase: applyPercent(_inputBudget.amtBase, targetAllocation.percent) },
              targetAllocation?.budget,
              _inputBudget.quote,
              targetAllocation?.budget.quote,
              {
                price: Number(_swap.rawResponse.price),
                dex: _swap.rawResponse.aggregator,
              },
              settings.slippage,
            ),
          };
        })
        .filter(Boolean) as MultipleTxData;
    },
  );

  const targetDistributionWithUnfair$ = Loader.array([
    inputBudget$,
    targetDistribution$,
    preparedSwaps$.mapNotLoaded('loading', () => []).mapNotLoaded('skipped', () => []),
  ] as const).map(([_inputBudget, _targetDistribution, _swaps]) => {
    return _targetDistribution.map(allocation => {
      const swapResponse = _swaps.find(
        s =>
          s.rawResponse.buyTokenAddress.toLowerCase() ===
          parseAddress(allocation.budget.token.id).address.toLowerCase(),
      );
      if (!swapResponse || !_inputBudget) return allocation;
      return {
        ...allocation,
        isUnfair:
          getUnfairLevelForPriceDiff(
            computePriceDiff(Number(swapResponse.rawResponse.price), _inputBudget.quote, allocation.budget.quote),
          ) > UnfairLevel.LOW,
      };
    });
  });

  return (
    <div className="flex flex-col gap-6 overflow-y-auto hide-scrollbars">
      <MultiBuyUI
        inputBudget={inputBudget$}
        inputHoldings={inputHoldings$}
        targetAllocations={targetDistributionWithUnfair$}
        onInputBudgetChange={(budget, isMax) => {
          dispatch({ type: 'SET_INPUT_TOKEN_AMOUNT', payload: { value: budget.amtBase, isMax } });
          dispatch({
            type: 'SET_ALERT',
            payload: null,
          });
        }}
        onTargetAllocationChange={({ coin, percent, isLocked }) => {
          dispatch({ type: 'SET_TARGET_TOKEN_ALLOCATION', payload: { tokenId: coin.id, percent, isLocked } });
          dispatch({
            type: 'SET_ALERT',
            payload: null,
          });
        }}
        onEditSelection={async () => {
          await selectMultiCoin(
            s => s && resetScroll() && dispatch({ type: 'SET_TARGET_TOKEN_IDS', payload: [...s.keys()] }),
          );
        }}
        onRemove={token => dispatch({ type: 'REMOVE_TARGET_TOKEN_IDS', payload: [token.id] })}
        onSelectInputCoin={async () => {
          await selectCoin(
            s => s && resetScroll() && dispatch({ type: 'SET_INPUT_TOKEN_ID', payload: s.id as ChainAddress }),
          );
          dispatch({
            type: 'SET_ALERT',
            payload: null,
          });
        }}
        targetHints={targetHints}
        targetHoldings={targetHoldings$}
        inputHint={inputHint$}
        isConfirmed={isConfirmed}
        onBackToSelection={() => dispatch({ type: 'SET_IS_SELECTION_CONFIRMED', payload: false })}
        onSwitch={() => setCurrentFlow(TransactionFlowType.MULTISELL)}
        isOperationLoading={operation.isLoading}
        isInputTokenUsedInLimitOrders={isInputTokenUsedInLimitOrders$}
      />
      {WarningMessage}
      {Loader.array([state$, inputBudget$, isInputTokenUsedInLimitOrders$] as const)
        .match.notOk(() => <Divider />)
        .ok(([_state, _inputBudget, _isInputTokenUsedInLimitOrders]) => {
          const displayRecap = _state.inputTokenId && isConfirmed;

          const warningAlert = {
            variant: 'warning' as const,
            title: t('Transactions.errors.limitOrderWarning.title'),
            content: t('Transactions.errors.limitOrderWarning.description', {
              token: _inputBudget?.token?.symbol,
            }),
          };

          if (!displayRecap && _isInputTokenUsedInLimitOrders)
            return (
              <Alert {...warningAlert} variant="warning" progressBarValuePercent={100} progressBarPosition="top" />
            );

          return displayRecap ? (
            <div className="h-fit">
              <TransactionRecap
                multipleTxs
                txData={recapData$}
                onCountdownComplete={() => setRefresh(r => !r)}
                resetDep={_state.inputTokenId}
                alert={_state.alert}
                alertClassName="!px-4"
                warningAlertClassName="!px-4"
                warningAlert={_isInputTokenUsedInLimitOrders ? warningAlert : undefined}
                countDownClassName={_isInputTokenUsedInLimitOrders ? 'bg-warning' : undefined}
              />
            </div>
          ) : (
            <Divider />
          );
        })}
      {isConfirmed
        ? sendTxButton
        : wallet$.match
          .notOk(() => (
            <Button label={t('Common.connectWallet')} onClick={login} variant="surface-accent" size="l" fullWidth />
          ))
          .ok(v =>
            v.isAuthed ? (
              <Button
                label={t('Transactions.MultiSwap.confirmSelection')}
                onClick={() => dispatch({ type: 'SET_IS_SELECTION_CONFIRMED', payload: true })}
                fullWidth
                size="l"
                disabled={!hasInputBudget || !hasTargetDistribution || insufficientBalance}
                variant={isInputTokenUsedInLimitOrders$.match
                  .notOk(() => undefined)
                  .ok(value => (value ? 'warning' : undefined))}
              />
            ) : (
              <Button label={t('Common.connectWallet')} onClick={login} variant="surface-accent" size="l" fullWidth />
            ),
          )}
    </div>
  );
}
