import cls from 'classnames';
import {
  InputHTMLAttributes,
  forwardRef,
  ImgHTMLAttributes,
  ReactElement,
  cloneElement,
  ChangeEvent,
  ReactNode,
} from 'react';
import { match } from 'ts-pattern';
import { XOR } from 'ts-xor';

import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
// eslint-disable-next-line import/no-cycle
import { ChipGroup, ItemChipType } from '@ui-kit/organisms/ChipGroup';
import { Cls, OneOf, SVGIcon } from '@utils';

export enum TextInputSize {
  S,
  M,
  L,
  XL,
  _2XL,
}

type LargeTextInput = {
  size: TextInputSize.L | TextInputSize.XL | TextInputSize._2XL;
  TrailingVisual?: OneOf<[{ Icon: SVGIcon }, { chips: ItemChipType[] }, { button: ReactNode; hasDivider?: boolean }]>;
};

type SmallTextInput = {
  size?: TextInputSize.S | TextInputSize.M;
  TrailingVisual?: OneOf<[{ Icon: SVGIcon }, { chips: never }, { button: ReactNode; hasDivider?: boolean }]>;
};

export type ITextInput = {
  /**
   * set a space for supporting text under the input
   */
  indication?: string;
  hasError?: boolean;
  inputType?: string;
  LeadingVisual?: ReactElement<ImgHTMLAttributes<HTMLImageElement | SVGElement>>;
  onTrailingVisualClick?: VoidFunction;
  onLeadingVisualClick?: VoidFunction;
  containerClassName?: string;
  trailingVisualClassName?: string;
  isLoading?: boolean;
  freezeLoading?: boolean;
  trailingVisualContainerClassName?: string;
} & Cls &
XOR<LargeTextInput, SmallTextInput> &
InputHTMLAttributes<HTMLInputElement>;

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  (
    {
      onChange,
      placeholder,
      indication,
      size = TextInputSize.L,
      hasError,
      inputType = 'text',
      disabled,
      LeadingVisual,
      TrailingVisual,
      onTrailingVisualClick,
      onLeadingVisualClick,
      className,
      containerClassName,
      trailingVisualClassName,
      trailingVisualContainerClassName,
      isLoading,
      freezeLoading,
      ...rest
    },
    ref,
  ) => {
    const isSmall = size === TextInputSize.S || size === TextInputSize.M;

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    };

    const aggregatedClassName = cls(
      className,
      'w-full truncate focus:outline-none',
      'p-4 select-none',
      match(size)
        .with(TextInputSize.S, () => 'py-1.5 h-8')
        .with(TextInputSize.M, () => 'py-2.5')
        .with(TextInputSize.L, () => 'py-3')
        .with(TextInputSize.XL, () => 'py-4')
        .with(TextInputSize._2XL, () => 'py-4 h-[72px] font-youth-medium font-medium !text-3xl caret-accent')
        .run(),
      isSmall ? 'text-base' : 'text-lg',
      // eslint-disable-next-line no-nested-ternary
      LeadingVisual ? (isSmall ? 'pl-10' : 'pl-11') : '',
      // eslint-disable-next-line no-nested-ternary
      TrailingVisual || indication ? (isSmall ? 'pr-10' : 'pr-11') : '',
      TrailingVisual && indication && '!pr-24',
      'rounded-2xl bg-surface-variant-muted border transition-colors',
      'focus:outline-0 focus:outline-offset-0',
      hasError
        ? 'border-danger caret-danger'
        : 'border-surface-variant-muted focus:border focus:border-outline caret-accent',
      disabled
        ? 'placeholder:text-font-muted-disabled'
        : 'placeholder:text-font-variant hover:bg-surface-variant-muted-hover-font',
      (TrailingVisual || LeadingVisual) && 'relative',
    );

    return (
      <div className={cls('flex flex-col gap-1', containerClassName)}>
        <div className={cls('relative', disabled ? 'text-font-disabled' : 'text-font')}>
          {isLoading ? (
            <Skeleton
              className={cls(
                match(size)
                  .with(TextInputSize.S, () => 'h-[34px]')
                  .with(TextInputSize.M, () => 'h-[42px]')
                  .with(TextInputSize.L, () => 'h-[50px]')
                  .with(TextInputSize.XL, () => 'h-[58px]')
                  .with(TextInputSize._2XL, () => 'h-[72px]')
                  .run(),
                aggregatedClassName,
              )}
              noAnimation={freezeLoading}
            />
          ) : (
            <input
              ref={ref}
              disabled={disabled}
              type={inputType}
              className={aggregatedClassName}
              onChange={handleChange}
              placeholder={placeholder}
              {...rest}
            />
          )}
          {LeadingVisual && !isLoading && (
            <button
              type="button"
              className={cls(
                'absolute h-full inset-y-0 flex items-center justify-center',
                'focus:outline-0 focus:outline-offset-0 !border-none !border-transparent p-0',
                { 'cursor-default': !onLeadingVisualClick },
                isSmall ? 'left-5' : 'left-6',
              )}
            >
              {cloneElement(LeadingVisual, {
                ...LeadingVisual.props,
                className: cls(
                  isSmall ? 'w-3.5 h-3.5' : 'w-4 h-4',
                  'fill-current translate-x-[-50%]',
                  LeadingVisual.props.className,
                ),
              })}
            </button>
          )}
          {!isLoading && (
            <div
              className={cls('flex gap-3 items-center inset-y-0 absolute right-4', trailingVisualContainerClassName)}
            >
              {indication && (
                <div
                  className={cls(
                    isSmall ? 'text-sm' : 'text-base',
                    'flex items-center',
                    // eslint-disable-next-line no-nested-ternary
                    disabled ? 'text-font-muted-disabled' : hasError ? 'text-danger' : 'text-font-variant',
                  )}
                >
                  {indication}
                </div>
              )}
              {TrailingVisual?.Icon ? (
                <TrailingVisual.Icon
                  onClick={onTrailingVisualClick}
                  className={cls(
                    'fill-current',
                    isSmall ? 'w-3.5 h-3.5' : 'w-4 h-4',
                    disabled ? 'text-font-muted-disabled' : 'text-font-variant',
                    onTrailingVisualClick ? 'cursor-pointer' : 'cursor-default',
                    trailingVisualClassName,
                  )}
                />
              ) : null}
              {TrailingVisual?.chips ? <ChipGroup items={TrailingVisual?.chips} /> : null}
              {TrailingVisual?.button ? (
                <div className="flex gap-4 items-center h-full">
                  {TrailingVisual.hasDivider ? <Divider direction="vertical" className="!h-full" /> : null}
                  {TrailingVisual?.button}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
