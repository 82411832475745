import { GetBlockReturnType } from 'viem';

import {
  Loadable,
  Loader,
  TokenBudget,
  BudgetWithQuote,
  withoutChain,
  wrapToken,
  getPublicClient,
  ICoin,
  toNumber,
} from '@utils';

import { Chain } from '../gql';

export const MIN_SOLVER_FEE = 4;

export interface RawDca {
  type: 'dca';
  script: string;
}

const oneHourInSeconds = 60 * 60;
const oneDayInSeconds = 24 * oneHourInSeconds;
const oneWeekInSeconds = 7 * oneDayInSeconds;
const oneMonthInSeconds = 30 * oneDayInSeconds;

export enum DcaTimeframes {
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}
export const DcaTimeframesToSeconds = {
  [DcaTimeframes.Hours]: oneHourInSeconds,
  [DcaTimeframes.Days]: oneDayInSeconds,
  [DcaTimeframes.Weeks]: oneWeekInSeconds,
  [DcaTimeframes.Months]: oneMonthInSeconds,
};
export type DcaConfig = {
  input: Loadable<nil | BudgetWithQuote<ICoin>>;
  output: Loadable<ICoin>;
  chain: Loader<Chain>;
  timeframe: DcaTimeframes; // unit of the frequency
  interval?: number; // number of cycle of this timeframe
  maxIntervals?: number; // number of cycle of this interval*timeframe
  lowerRate?: Loadable<TokenBudget>; // lower price range
  higherRate?: Loadable<TokenBudget>; // higher price range
  refresh?: number;
};

export const useBlock = (chain: Loadable<Chain>, refreshBlock = 0) => {
  return Loader.useWrap(chain).map([refreshBlock], _chain => getPublicClient(_chain).getBlock());
};
const usePreviousBlock = (
  chain: Loader<Chain>,
  refreshBlock: number,
  block: Loader<GetBlockReturnType>,
  window = 1n,
) => {
  return Loader.array([block, chain] as const).map([refreshBlock], ([_block, _chain]) =>
    getPublicClient(_chain).getBlock({ blockNumber: BigInt(_block?.number) - window }),
  );
};
export const useBlockDuration = (chain: Loadable<Chain>, refreshBlock = 0, window = 5n): Loader<number> => {
  const chain$ = Loader.useWrap(chain);
  const block = useBlock(chain$, refreshBlock);
  const previousBlock = usePreviousBlock(chain$, refreshBlock, block, window);

  return Loader.array([block, previousBlock] as const).map(([b, pb]) => {
    return toNumber(b.timestamp - pb.timestamp, 0) / toNumber(window, 0); // unix timestamp in seconds
  });
};
export function useDca({
  input,
  output,
  chain,
  timeframe,
  interval,
  maxIntervals,
  lowerRate,
  higherRate,
  refresh = 1,
}: DcaConfig): Loader<RawDca> {
  const blockDuration = useBlockDuration(chain, refresh);
  const block = useBlock(chain, refresh);

  return Loader.array([
    input,
    output,
    timeframe,
    interval,
    maxIntervals,
    lowerRate,
    higherRate,
    blockDuration,
    block,
  ] as const).map(
    ([inp, out, _timeframe, _interval, _maxIntervals, _greaterThan, _lowerThan, _blockDuration, _block]) => {
      if (!inp || !inp.token || !inp.amtBase || !inp.quote) return Loader.error('You must select an input');
      if (!_interval) return Loader.error('You must select a frequency');
      if (!_maxIntervals) return Loader.error('You must select a max number of cycles');
      if (!_blockDuration) return Loader.error('Cannot estimate block duration');

      const greaterThanPrice = _greaterThan?.amtBase
        ? toNumber(_greaterThan?.amtBase, _greaterThan.token.decimals).toString()
        : '0.0';
      const lowerThanPrice = _lowerThan?.amtBase
        ? toNumber(_lowerThan?.amtBase, _lowerThan.token.decimals).toString()
        : '0.0';

      let blockInterval: number;
      const intervalInSeconds = DcaTimeframesToSeconds[_timeframe] * _interval;
      if (interval) {
        blockInterval = Math.floor(intervalInSeconds / _blockDuration);
      } else {
        throw new Error('You must select a frequency');
      }
      // TODO(Hadrien) : implement no expiry = 0
      const expiryBlock = _block.number + BigInt(Math.ceil((intervalInSeconds * _maxIntervals) / _blockDuration));

      if (!inp || !out) {
        return {
          type: 'dca',
          script: '',
        } satisfies RawDca;
      }
      const amountUsd = toNumber(inp.amtBase, inp.token.decimals) * inp.quote;
      const minFee = (MIN_SOLVER_FEE * 100) / amountUsd;
      console.log('fee brink', amountUsd, minFee);
      const preparedScript = `brink.dca(${inp.amtBase}u ${withoutChain(wrapToken(inp.token.id))}, ${withoutChain(
        wrapToken(out.id),
      )}, ${greaterThanPrice}, ${lowerThanPrice}, ${minFee}, ${blockInterval}, ${_maxIntervals}, ${expiryBlock});`;
      // console.log('preparedScript', preparedScript);
      return { type: 'dca', script: preparedScript } satisfies RawDca;
    },
  );
}

// TODO(Hadrien) : How to cancel a DCA ?
