import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { CopyChip } from '@components/CopyChip';
import { NetworkSelector } from '@components/NetworkSelector';
import { QrCode } from '@components/QrCode';
import { EntryCoin, EntryCoinProps } from '@components/entries/EntryCoin';
import { EntryList } from '@components/entries/EntryList';
import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { Chain, GetUserBalances_myUser_walletBalances as Balance } from '@gql';
import useToggle from '@hooks/useToggle';
import {
  LoginIcon,
  SearchIcon,
  QrCodeIcon,
  CloseIcon,
  EraseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ProfileIcon,
} from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import { HeadingValueVariation } from '@ui-kit/atoms/HeadingValueVariation';
import { Headline } from '@ui-kit/atoms/Headline';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Chip } from '@ui-kit/molecules/Chip';
import { LabelItem } from '@ui-kit/organisms/LabelItem';
import { TextInput, TextInputSize } from '@ui-kit/organisms/TextInput';
import { Loadable, Loader, shortenAddress, withoutChain } from '@utils';
import { allNetworks } from '@utils/networks';
import { WalletData } from '@wallet';

const SMALL_BALANCE = 0.1;

export type MyAccountUIProps = {
  wallet: Loadable<WalletData>;
  onLogout: VoidFunction;
  totalBalance: Loadable<number>;
  balanceVariation: Loadable<number>;
  coins: Loadable<Balance[]>;
  searchFilter?: string;
  setSearchFilter: (search: string) => void;
  chainFilter?: Chain;
  setChainFilter: (search?: Chain) => void;
  onCoinClick: (coin: EntryCoinProps) => void;
  onGoBack?: () => void;
  profilePicture?: Loadable<string>;
  onDepositClick: (coin: Balance['token']) => void;
};

const hiddenCoinsSection = 'hidden-coins';
const unknownCoinsSection = 'unknown-coins';

export function MyAccountUI({
  wallet,
  onLogout,
  totalBalance,
  balanceVariation,
  onCoinClick,
  onGoBack,
  profilePicture,
  coins,
  searchFilter,
  setSearchFilter,
  chainFilter,
  setChainFilter,
  onDepositClick,
}: MyAccountUIProps) {
  const { t } = useTranslation();
  const [isQrVisible, toggleIsQrVisible] = useToggle();
  const [displayHiddenCoins, toggleDisplayHiddenCoins] = useToggle();
  const [displayUnknownCoins, toggleDisplayUnknownCoins] = useToggle();

  const toggleHiddenCoins = () => {
    toggleDisplayHiddenCoins();
    setTimeout(() => {
      document.getElementById(hiddenCoinsSection)?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };
  const toggleUnknownCoins = () => {
    toggleDisplayUnknownCoins();
    setTimeout(() => {
      document.getElementById(unknownCoinsSection)?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const wallet$ = Loader.useWrap(wallet);
  const address = wallet$.match.notOk(() => null).ok(x => withoutChain(x.address));
  const totalBalance$ = Loader.useWrap(totalBalance);
  const balanceVariation$ = Loader.useWrap(balanceVariation);
  const profilePicture$ = Loader.useWrap(profilePicture);
  const coins$ = Loader.useWrap(coins)
    .noFlickering()
    .map([searchFilter, chainFilter], _coins => {
      const groupedCoins = _coins.reduce(
        (acc, coin: Balance) => {
          if (coin.token.quote * coin.qtyNum >= SMALL_BALANCE && coin.token.aggregatedId) {
            acc.defaultCoins.push(coin);
          } else if (coin.token.aggregatedId && coin.token.quote * coin.qtyNum < SMALL_BALANCE) {
            acc.hiddenCoins.push(coin);
          } else if (!coin.token.aggregatedId) {
            acc.unknownCoins.push(coin);
          }
          return acc;
        },
        { defaultCoins: [] as Balance[], hiddenCoins: [] as Balance[], unknownCoins: [] as Balance[] },
      );

      return { isWalletEmpty: _coins.length === 0, ...groupedCoins };
    });

  const heading = Loader.array([totalBalance$, balanceVariation$] as const)
    .match.notOk(status => <Skeleton className="h-12 w-[170px]" noAnimation={status === 'error'} />)
    .ok(([balance, variation]) => (
      <HeadingValueVariation
        variationClassName="hidden" // TODO: hide variation for now, until we have a better way to calculate it
        timeframeClassName="font-youth-medium"
        alignment="center"
        value={{ value: balance, variation }}
      />
    ));

  return (
    <div className="flex flex-col w-full gap-6">
      <SideContentHeader
        title={t('MyAccount.wallet.title')}
        onGoBack={onGoBack}
        alignment="left"
        rightIcons={[
          // <Button key="settings" Icon={SettingsIcon} />,
          <Button key="logout" Icon={LoginIcon} onClick={onLogout} />,
        ]}
      />
      <div className="flex flex-col relative">
        <Headline
          className={cls('w-full pb-4', isQrVisible ? 'gap-4' : 'gap-3')}
          alignment="center"
          overline={
            <>
              <div className="flex gap-2 items-center">
                <CopyChip
                  label={shortenAddress(address)}
                  value={address}
                  leadingItem={profilePicture$.match
                    .notOk(() => ({ Icon: <Skeleton className="w-6 h-6" isRound /> }))
                    .ok(src => (src ? { image: src } : { Icon: <ProfileIcon className="w-6 h-6" /> }))}
                />
                <Button
                  size="s"
                  Icon={isQrVisible ? CloseIcon : QrCodeIcon}
                  variant="surface"
                  onClick={toggleIsQrVisible}
                />
              </div>
              {address && isQrVisible ? <QrCode className="w-20 h-20" value={address} /> : null}
            </>
          }
          heading={heading}
        />
        <div className="flex gap-2 sticky -top-4 bg-background-variant z-10 pt-4 pb-4">
          <TextInput
            size={TextInputSize.S}
            LeadingVisual={<SearchIcon />}
            placeholder={t('MyAccount.wallet.searchPlaceholder')}
            value={searchFilter}
            TrailingVisual={searchFilter ? { Icon: EraseIcon } : undefined}
            onTrailingVisualClick={() => setSearchFilter('')}
            onChange={event => setSearchFilter(event.target.value)}
            containerClassName="w-full"
          />
          <NetworkSelector
            allowClearSelection
            network={chainFilter}
            onSelectNetwork={setChainFilter}
            alignment="bottomRight"
          />
        </div>
        <div>
          {Loader.array([coins$, totalBalance$] as const)
            .match.notOk(status => (
              <div className="flex flex-col gap-2">
                <Skeleton className="w-full h-[72px]" noAnimation={status === 'error'} />
                <Skeleton className="w-full h-[72px]" noAnimation={status === 'error'} />
                <Skeleton className="w-full h-[72px]" noAnimation={status === 'error'} />
              </div>
            ))
            .ok(([{ defaultCoins, hiddenCoins, unknownCoins, isWalletEmpty }]) => {
              const hasHiddenCoins = hiddenCoins.length > 0;
              const hasUnknownCoins = unknownCoins.length > 0;
              const enabledChains = allNetworks.filter(n => !n.disabled).map(n => n.chain);

              const _defaultCoins = defaultCoins.filter(
                ({ token }) => token.chain && enabledChains.includes(token.chain),
              );
              const _hiddenCoins = hiddenCoins.filter(
                ({ token }) => token.chain && enabledChains.includes(token.chain),
              );
              const _unknownCoins = unknownCoins.filter(
                ({ token }) => token.chain && enabledChains.includes(token.chain),
              );

              return (
                <div className="flex flex-col gap-4">
                  <EntryList<EntryCoinProps>
                    variant="small-spaced"
                    entries={_defaultCoins.map(coin => ({
                      coin: coin.token,
                      quantity: coin.qtyNum,
                      usdValue: coin.token.quote * coin.qtyNum,
                      onDepositClick: () => onDepositClick(coin.token),
                    }))}
                    emptyElement={
                      <div className="text-base text-center h-[72px] justify-center items-center flex select-none">
                        {isWalletEmpty ? t('MyAccount.wallet.emptyWallet') : t('MyAccount.wallet.noResult')}
                      </div>
                    }
                    onClickEntry={onCoinClick}
                    render={EntryCoin}
                  />
                  {hasHiddenCoins ? (
                    <div className="flex flex-col gap-4" id={hiddenCoinsSection}>
                      <LabelItem
                        label={t('MyAccount.wallet.hidden', { count: _hiddenCoins.length })}
                        className="select-none"
                        itemTooltipContent={t('MyAccount.wallet.hiddenTooltip')}
                        item={
                          <Chip
                            onClick={toggleHiddenCoins}
                            label={displayHiddenCoins ? t('MyAccount.wallet.hide') : t('MyAccount.wallet.show')}
                            trailingItem={{ Icon: displayHiddenCoins ? <ChevronUpIcon /> : <ChevronDownIcon /> }}
                          />
                        }
                      />
                      {displayHiddenCoins ? (
                        <EntryList<EntryCoinProps>
                          variant="small-spaced"
                          entries={_hiddenCoins.map(coin => ({
                            coin: coin.token,
                            quantity: coin.qtyNum,
                            usdValue: coin.token.quote * coin.qtyNum,
                            onDepositClick: () => onDepositClick(coin.token),
                          }))}
                          onClickEntry={onCoinClick}
                          render={EntryCoin}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {hasUnknownCoins ? (
                    <div className="flex flex-col gap-4" id={unknownCoinsSection}>
                      <LabelItem
                        label={t('MyAccount.wallet.unknown', { count: _unknownCoins.length })}
                        className="select-none"
                        itemTooltipContent={t('MyAccount.wallet.unknownTooltip')}
                        item={
                          <Chip
                            onClick={toggleUnknownCoins}
                            label={displayUnknownCoins ? t('MyAccount.wallet.hide') : t('MyAccount.wallet.show')}
                            trailingItem={{ Icon: displayUnknownCoins ? <ChevronUpIcon /> : <ChevronDownIcon /> }}
                          />
                        }
                      />
                      {displayUnknownCoins ? (
                        <EntryList<EntryCoinProps>
                          variant="small-spaced"
                          entries={_unknownCoins.map(coin => ({
                            coin: coin.token,
                            quantity: coin.qtyNum,
                            usdValue: coin.token.quote * coin.qtyNum,
                            onDepositClick: () => onDepositClick(coin.token),
                          }))}
                          onClickEntry={onCoinClick}
                          render={EntryCoin}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
