import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { usePrepareSendTransaction, useSendTransaction, useWaitForTransaction } from 'wagmi';

import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { GetAllPendingOrders_pendingOrders_results_DcaOrder as DcaOrder } from '@gql';
import { useBlock, useBlockDuration } from '@tetris/useDca';
import { ArrowRightIcon, RotateArrowIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import { Pill } from '@ui-kit/atoms/Pill';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import { Float, toNumber, chainOf, Loader, safeMult, parseNum } from '@utils';
import { networksByChain } from '@utils/networks';
import { formatNumber } from '@utils/numbers/NumberFormat';

export type DcaOrderDetailsUIProps = {
  dcaOrder: DcaOrder;
  onGoBack?: VoidFunction;
};

function getExecutedCyclesCount(dcaOrder: DcaOrder, blockTime: number, lastBlockTime: number): number {
  const creationTimestampInS = new Date(dcaOrder.creationDate).getTime() / 1000;
  const interval = toNumber(dcaOrder.intervalMinSize, 0) * blockTime;

  return Math.min(toNumber(dcaOrder.maxIntervals, 0), Math.floor((lastBlockTime - creationTimestampInS) / interval));
}

export function DcaOrderDetailsUI({ dcaOrder, onGoBack }: DcaOrderDetailsUIProps) {
  const { t } = useTranslation();

  const input = {
    token: dcaOrder.input.token,
    qty: dcaOrder.input.qty,
    quote: dcaOrder.input.token.quote,
    usdValue: dcaOrder.input.qtyNum * dcaOrder.input.token.quote,
  };
  const output = {
    token: dcaOrder.output.token,
    qty: dcaOrder.output.qty,
    quote: dcaOrder.output.token.quote,
    usdValue: dcaOrder.output.qtyNum * dcaOrder.output.token.quote,
  };
  const network = networksByChain[chainOf(dcaOrder.vault)];

  const statusPill = (
    <Pill theme={{ type: 'default', variant: 'soft' }} label={t('Common.TransactionStatus.pending')} />
  );

  const type = {
    Icon: RotateArrowIcon,
    label: t('TransactionDetails.type.dcaOrder'),
  };

  const blockTime$ = useBlockDuration(chainOf(input.token.id));
  const block$ = useBlock(chainOf(input.token.id));

  const totalDoneExections$ = Loader.array([blockTime$, block$] as const).map(([blockTime, block]) => {
    if (!blockTime || !block) {
      return 0;
    }

    return getExecutedCyclesCount(dcaOrder, blockTime, toNumber(block.timestamp, 0));
  });

  const qtyPerCycle = toNumber(input.qty, input.token.decimals);
  const totalQty = qtyPerCycle * toNumber(dcaOrder.maxIntervals, 0);

  const duration$ = blockTime$.map(blockTime => {
    if (!blockTime) {
      return 0;
    }

    return toNumber(safeMult(parseNum(dcaOrder.maxIntervals) * parseNum(dcaOrder.intervalMinSize), blockTime), 0);
  });

  const frequency$ = Loader.array([blockTime$] as const).map(([blockTime]) => {
    if (!blockTime) {
      return 0;
    }

    return toNumber(dcaOrder.intervalMinSize, 0) * blockTime;
  });

  const endDate$ = duration$.map(blockTime => {
    return new Date(new Date(dcaOrder.creationDate).getTime() + blockTime * 1000);
  });

  const fields = [
    {
      label: t('TransactionDetails.fields.type'),
      item: (
        <div className="flex gap-1 items-center text-font font-medium">
          <type.Icon className="fill-current w-3.5 h-3.5" />
          <span>{type.label}</span>
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.fromTo'),
      item: (
        <div className="flex gap-1 items-center">
          {input.token.symbol}
          <ArrowRightIcon className="w-3 h-3 text-font-variant" />
          {output.token.symbol}
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.inputQty'),
      item: (
        <>
          <Float.Custom
            key={input.token.symbol}
            value={toNumber(input.qty, input.token.decimals)}
            type="qty"
            unit={input.token.symbol}
            takeAbsoluteValue
          />
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.totalQty'),
      item: (
        <>
          <Float.Custom
            key={input.token.symbol}
            value={totalQty}
            type="qty"
            unit={input.token.symbol}
            takeAbsoluteValue
          />
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.frequencyAndDuration'),
      item: (
        <>
          {t('TransactionDetails.fields.frequencyAndDurationEntry', {
            frequency: formatNumber(frequency$.unwrapOr(0), 'duration'),
            duration: formatNumber(duration$.unwrapOr(0), 'duration'),
          })}
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.cyclesDone'),
      item: (
        <>
          {t('TransactionDetails.fields.cyclesDoneEntry', {
            cyclesDone: totalDoneExections$.unwrapOr(0),
            totalCycles: toNumber(dcaOrder.maxIntervals, 0),
          })}
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.estimatedEndDate'),
      item: endDate$.unwrapOr(null) ? format(endDate$.unwrapOr(new Date()), 'dd MMM. yyyy - HH:hh') : null,
    },
    {
      label: t('TransactionDetails.fields.massFees'),
      itemTooltipContent: t('TransactionDetails.fields.massFees'),
      item: <Pill label={t('TransactionDetails.free')} theme={{ type: 'accent', variant: 'heavy' }} />,
    },
    {
      label: t('TransactionDetails.fields.network'),
      item: <Pill LeadingIcon={network.MonochromeIcon} label={network.name} />,
    },
    { label: t('TransactionDetails.fields.transactionState'), item: statusPill },
  ].map(field => ({ ...field, className: 'py-3.5' }));

  const { config } = usePrepareSendTransaction(dcaOrder.cancelTx as any);
  const { data, sendTransaction } = useSendTransaction(config);

  const { isLoading } = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <div className="h-full flex flex-col w-full gap-6 pb-8">
      <SideContentHeader title={t('TransactionDetails.title')} onGoBack={onGoBack} alignment="left" />
      <div className="overflow-y-auto hide-scrollbars flex flex-col gap-6 h-full">
        <LabelItemList
          contentClassname="!gap-0"
          labelItems={fields.map(field => ({ ...field, className: '!py-4 text-base' }))}
        />
        <Button
          label="Cancel Order"
          size="l"
          fullWidth
          variant="danger-muted"
          onClick={sendTransaction}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
