import cls from 'classnames';
import { HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react';
import { match } from 'ts-pattern';

import { SuccessIcon, InfoIcon, WarningIcon, ErrorIcon, CloseIcon, MassIcon, ExternalLinkIcon } from '@ui-kit/Icons';
import { Elt } from '@utils';

import { IProgressBar, ProgressBar } from './ProgressBar';

export type AlertVariant = 'success' | 'info' | 'warning' | 'error' | 'muted';

export type AlertLink = {
  url?: string;
  label: string;
  onClick?: VoidFunction;
  target?: HTMLAttributeAnchorTarget;
  isExternal?: boolean;
};

export interface IAlert {
  className?: string;
  variant?: AlertVariant;
  title?: string;
  content?: Elt;
  hideIcon?: boolean;
  isFixed?: boolean;
  onClose?: VoidFunction;
  progressBarValuePercent?: IProgressBar['valuePercent'];
  progressBarPosition?: 'top' | 'bottom';
  theme?: 'default' | 'brand';
  bottom?: ReactNode;
  links?: AlertLink[];
}

export const Alert = ({
  className,
  variant = 'success',
  title,
  content,
  hideIcon,
  isFixed,
  onClose,
  progressBarValuePercent,
  progressBarPosition = 'bottom',
  theme = 'default',
  bottom,
  links,
}: IAlert) => {
  const hasContent = !!content;
  const showIcon = !hideIcon && title;
  const closeButton = useMemo(
    () =>
      onClose && (
        <CloseIcon onClick={onClose} className="w-3.5 h-3.5 fill-current flex-shrink-0 text-font cursor-pointer" />
      ),
    [onClose],
  );

  const variantClassName = match(variant)
    .with('success', () => 'text-success')
    .with('info', () => 'text-info')
    .with('warning', () => 'text-warning')
    .with('error', () => 'text-danger')
    .with('muted', () => 'text-font-variant')
    .exhaustive();

  const Icon = match(variant)
    .with('success', () => SuccessIcon)
    .with('info', () => InfoIcon)
    .with('warning', () => WarningIcon)
    .with('error', () => ErrorIcon)
    .with('muted', () => InfoIcon)
    .exhaustive();

  const hasLinks = links && links.length > 0;
  const dot = <div className="bg-font-variant rounded-full h-1 w-1 flex justify-center items-center" />;

  const progressBarClassName = match(progressBarPosition)
    .with('top', () => 'top-0')
    .with('bottom', () => 'bottom-0')
    .exhaustive();

  return (
    <div
      className={cls(
        'flex flex-col gap-3 rounded-xl p-3 relative font-sans whitespace-normal break-words',
        (typeof progressBarValuePercent === 'number' || theme === 'brand') && 'overflow-hidden',
        isFixed && 'backdrop-blur-2xl max-w-[320px]',
        {
          'bg-surface-elevated': isFixed && theme === 'default',
          'bg-surface-muted': theme === 'default',
          'bg-background': theme === 'brand',
        },
        theme === 'default' ? variantClassName : 'text-font',
        className,
      )}
    >
      {title && (
        <div className="flex justify-between items-center h-full">
          <div className="flex flex-row items-center gap-1">
            {showIcon && theme === 'default' && (
              <Icon className={cls('w-4 h-4 fill-current flex-shrink-0 my-auto', variantClassName)} />
            )}
            {title && (
              <span className={cls('font-medium', theme === 'default' ? 'text-lg' : 'text-xl leading-[26px]')}>
                {title}
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">{closeButton}</div>
        </div>
      )}
      {hasContent && <p className="text-font text-base">{content}</p>}
      {hasLinks && (
        <div className="flex gap-2 items-baseline self-stretch">
          {links.map((link, index) => {
            const commonClassName = 'text-accent text-lg font-medium hover:text-accent-hover-font-on-accent';

            return (
              <>
                {link.url ? (
                  <a
                    key={link.label}
                    href={link.url}
                    target={link.target || '_blank'}
                    className={commonClassName}
                    rel="noreferrer"
                  >
                    {link?.label}
                  </a>
                ) : (
                  <span
                    key={link.label}
                    onClick={link.onClick}
                    // eslint-disable-next-line max-len
                    className={cls(commonClassName, 'cursor-pointer')}
                  >
                    {link.label}
                  </span>
                )}
                {link.isExternal && (
                  <ExternalLinkIcon className="w-3.5 h-3.5 fill-current flex-shrink-0 text-accent flex my-auto" />
                )}
                {index < links.length - 1 && dot}
              </>
            );
          })}
        </div>
      )}
      {bottom}
      {typeof progressBarValuePercent === 'number' && (
        <ProgressBar
          theme={variant}
          className={cls('absolute inset-x-0', progressBarClassName)}
          valuePercent={progressBarValuePercent}
        />
      )}
      {theme === 'brand' && (
        <MassIcon
          className="absolute blur-[6px] opacity-12 h-[442px]
        w-[792px] top-[-180px] right-[-350px] pointer-events-none"
        />
      )}
    </div>
  );
};
