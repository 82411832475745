import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';

import { NetworkSelector } from '@components/NetworkSelector';
import { SearchInput } from '@components/SearchInput';
import { SearchRow } from '@components/search/SearchRow';
import { Chain } from '@gql';
import { mainRoutes } from '@routes/mainRoutes';
import { Button } from '@ui-kit/atoms/Button';
import { TextInputSize } from '@ui-kit/organisms/TextInput';
import { Loader } from '@utils';

import { ListHeader } from './ListHeader';
import { useSearchState } from './useSearchState';
import { useSearchTokens } from './useSearchTokens';
import { useTrendingTokens } from './useTrendingTokens';

export function SearchTokensInput({ onCancel }: { onCancel: VoidFunction }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [chainFilter, setChainFilter] = useState<Chain>();
  const searchTokenResults = useSearchTokens({ search: searchValue, chain: chainFilter }, [searchValue, chainFilter]);
  const trendingTokens = useTrendingTokens({ chain: chainFilter }, [chainFilter]);
  const searchResults$ = Loader.useWrap(searchTokenResults);
  const trendingTokens$ = Loader.useWrap(trendingTokens);
  const { setIsSearchOpen } = useSearchState();

  useEffect(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleCancel = () => {
    setIsSearchOpen(false);
    onCancel();
  };

  const navigate = useNavigate();

  const searchResultsList = searchResults$.map(list =>
    (list || []).map(token => ({
      render: <SearchRow token={token} />,
      onClick: () => {
        onCancel();
        navigate(
          generatePath(mainRoutes.coinDetails.path, {
            tokenId: token.id.split(':')[1],
          }),
        );
      },
    })),
  );

  const trendingTokensList = trendingTokens$.map(list =>
    (list || []).map(token => ({
      render: <SearchRow token={token} />,
      onClick: () => {
        handleCancel();
        navigate(
          generatePath(mainRoutes.coinDetails.path, {
            tokenId: token.id.split(':')[1],
          }),
        );
      },
    })),
  );

  return (
    <SearchInput
      size={TextInputSize._2XL}
      textInputClassName="!bg-transparent !py-0 !h-10 !border-none !pl-16"
      listVariant="small-spaced"
      itemClassName="!p-0 rounded-lg h-12"
      dropdownClassName="h-[calc(100vh-112px)] rounded-2xl top-[calc(100%+24px)] bg-surface p-4"
      header={
        <div className="flex items-center justify-between w-full">
          <span className="font-youth-medium text-title-small text-font">
            {searchValue
              ? t('Components.Search.resultsFor', { value: searchValue })
              : t('Components.Search.trendingSearch')}
          </span>
          <NetworkSelector onSelectNetwork={setChainFilter} network={chainFilter} />
        </div>
      }
      value={searchValue}
      onClose={handleCancel}
      onChange={setSearchValue}
      displayedLists={searchValue ? 'results' : 'trending'}
      results={{
        list: searchResultsList,
        header: <ListHeader />,
      }}
      trendingResults={{ list: trendingTokensList, header: <ListHeader /> }}
      showDropdownResults
      isDropdownOpen
      isFullWidth
      TrailingVisual={{
        button: <Button variant="surface" label={t('Common.cancel')} onClick={handleCancel} />,
        hasDivider: true,
      }}
    />
  );
}
