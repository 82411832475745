import { gql } from '@apollo/client';

export const GET_ALL_PENDING_ORDERS = gql`
  query GetAllPendingOrders {
    pendingOrders(filter: { isNotExpired: true }) {
      __typename
      totalCount
      results {
        id
        input {
          qty
          qtyNum
          token {
            id
            name
            decimals
            quote
            aggregatedId
            chain
            symbol
          }
        }
        output {
          qty
          qtyNum
          token {
            id
            name
            decimals
            quote
            aggregatedId
            chain
            symbol
          }
        }
        protocol
        userId
        vault
        creationDate
        cancelTx
        ... on StopLimitOrder {
          triggerPrice
        }
        ... on DcaOrder {
          initialStart
          intervalMinSize
          lowerThanPrice
          maxIntervals
          greaterThanPrice
          expiryBlock
        }
        type
      }
    }
  }
`;

export const REFRESH_PENDING_ORDERS = gql`
  mutation RefreshBrinkOrders($chain: Chain!) {
    refreshBrinkOrders(chain: $chain)
  }
`;
