import { gql } from '@apollo/client';

import { Loadable, Loader, TokenBudget } from '@utils';

import { PriceImpactQuery, PriceImpactQueryVariables } from '../../../gql';

type UsePriceImpact = readonly [
  inputTokenId: Loadable<ChainAddress | nil>,
  outputTokenId: Loadable<ChainAddress | nil>,
  budget: Loadable<TokenBudget | nil>,
];

export function usePriceImpact(params: UsePriceImpact): Loader<number> {
  return Loader.array(params)
    .map(([inputTokenId, outputTokenId, budget]) =>
      !inputTokenId || !outputTokenId || !budget?.amtBase || budget?.amtBase === 0n
        ? Loader.skipped
        : [inputTokenId, outputTokenId, budget.amtBase.toString()],
    )
    .query<PriceImpactQuery, PriceImpactQueryVariables>(QueryPriceImpact, _params => ({
    inputToken: _params[0] as ChainAddress,
    outputToken: _params[1] as ChainAddress,
    inputAmount: _params[2],
  }))
    .map(data => data.getPriceImpact);
}

const QueryPriceImpact = gql`
  query PriceImpactQuery($inputToken: ERC20!, $inputAmount: String!, $outputToken: ERC20!) {
    getPriceImpact(inputToken: $inputToken, inputAmount: $inputAmount, outputToken: $outputToken)
  }
`;
