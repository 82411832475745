import cls from 'classnames';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';

export type AnimationAlert = {
  variant: 'success' | 'error' | 'submitted';
};

export const animationAlert = atom<AnimationAlert | null>({
  key: 'animationAlert',
  default: null,
});

export default function TxSubmittedAnimation() {
  const animationRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [animation, setAnimation] = useRecoilState(animationAlert);

  useEffect(() => {
    if (animation) {
      focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  const focus = () => {
    if (animationRef.current) {
      animationRef.current.focus();
      // wait 3 seconds before unfocusing
      setTimeout(() => {
        unfocus();
        setAnimation(null);
      }, 3000);
    }
  };

  const unfocus = () => {
    if (animationRef.current) {
      animationRef.current.blur();
    }
  };

  return (
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      ref={animationRef}
      className={cls(
        'absolute bottom-0 left-0 right-0 overflow-hidden',
        'flex flex-col items-center z-30 justify-between',
        'w-full h-0 bg-accent rounded-3xl',
        'transition-all duration-500 ease-in-out focus:h-full',
      )}
    >
      <div />
      <div className="font-youth-medium font-black text-4xl text-font-on-accent mt-6">
        {t('Components.TxSubmittedAnimation.successful')}
      </div>
      <div className="font-youth-medium font-black text-lg text-font-on-accent mb-6 cursor-pointer" onClick={unfocus}>
        {t('Components.TxSubmittedAnimation.dismiss')}
      </div>
    </div>
  );
}
