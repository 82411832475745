import { Reducer } from 'react';

import { IAlert } from '@ui-kit/atoms/Alert';

import { Allocation, Distribution, Percent, splitDistributionRatios, updateDistribution } from './multiBuy.utils';

export type MultiBuyState = {
  inputTokenId: ChainAddress | nil;
  inputTokenAmount: bigint;
  targetTokenIds: Set<ChainAddress>;
  targetTokenDistribution: { [key: ChainAddress]: { percent: Percent; isLocked: boolean } };
  isSelectionConfirmed: boolean;
  isMax: boolean;
  alert: IAlert | null;
};

export const defaultMultiBuyState: MultiBuyState = {
  inputTokenId: null,
  inputTokenAmount: 0n,
  targetTokenIds: new Set(),
  targetTokenDistribution: {},
  isSelectionConfirmed: false,
  isMax: false,
  alert: null,
};

export type MultiBuyAction =
  | { type: 'SET_INPUT_TOKEN_ID'; payload: ChainAddress }
  | { type: 'SET_INPUT_TOKEN_AMOUNT'; payload: { value: bigint; isMax?: boolean } }
  | { type: 'SET_TARGET_TOKEN_IDS'; payload: ChainAddress[] }
  | { type: 'REMOVE_TARGET_TOKEN_IDS'; payload: ChainAddress[] }
  | { type: 'SET_TARGET_TOKEN_ALLOCATION'; payload: Allocation & { tokenId: ChainAddress } }
  | { type: 'SET_IS_SELECTION_CONFIRMED'; payload: boolean }
  | { type: 'SET_DEFAULT'; payload: { inputTokenId: ChainAddress } }
  | { type: 'RESET_FLOW' }
  | { type: 'SET_ALERT'; payload: IAlert | null };

export const multiBuyReducer: Reducer<MultiBuyState, MultiBuyAction> = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_TOKEN_ID':
      return {
        ...state,
        inputTokenId: action.payload,
      };
    case 'SET_INPUT_TOKEN_AMOUNT':
      return {
        ...state,
        inputTokenAmount: action.payload.value,
        isMax: !!action.payload.isMax,
      };
    case 'SET_TARGET_TOKEN_IDS': {
      const addedTokens = action.payload.filter(tokenId => !state.targetTokenIds.has(tokenId));
      const removedTokens = [...state.targetTokenIds].filter(tokenId => !action.payload.includes(tokenId));
      const newDistribution: Distribution = {
        ...state.targetTokenDistribution,
        ...Object.fromEntries(addedTokens.map(tokenId => [tokenId, { value: 0, isLocked: false }])),
      };
      removedTokens.forEach(tokenId => delete newDistribution[tokenId as ChainAddress]);
      const newTargetTokenIds = new Set(action.payload);
      return {
        ...state,
        targetTokenIds: newTargetTokenIds,
        targetTokenDistribution: splitDistributionRatios(newDistribution),
      };
    }
    case 'REMOVE_TARGET_TOKEN_IDS': {
      const newDistribution = { ...state.targetTokenDistribution };
      action.payload.forEach(tokenId => delete newDistribution[tokenId]);
      const newTargetTokenIds = new Set(state.targetTokenIds);
      action.payload.forEach(tokenId => newTargetTokenIds.delete(tokenId));
      return {
        ...state,
        targetTokenIds: newTargetTokenIds,
        targetTokenDistribution: splitDistributionRatios(newDistribution),
      };
    }
    case 'SET_TARGET_TOKEN_ALLOCATION': {
      return {
        ...state,
        targetTokenDistribution: updateDistribution(state.targetTokenDistribution, action.payload),
      };
    }
    case 'SET_IS_SELECTION_CONFIRMED':
      return {
        ...state,
        isSelectionConfirmed: action.payload,
      };
    case 'SET_DEFAULT':
      return {
        ...state,
        targetTokenIds: new Set(),
        targetTokenDistribution: {},
        inputTokenId: action.payload.inputTokenId,
        isSelectionConfirmed: false,
      };
    case 'RESET_FLOW':
      return {
        ...defaultMultiBuyState,
        inputTokenId: state.inputTokenId,
      };
    case 'SET_ALERT':
      return { ...state, alert: action.payload };
    default:
      return state;
  }
};
