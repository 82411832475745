import { useTranslation } from 'react-i18next';
import { usePrepareSendTransaction, useSendTransaction, useWaitForTransaction } from 'wagmi';

import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { GetAllPendingOrders_pendingOrders_results_StopLimitOrder as StopLossOrder } from '@gql';
import { ArrowRightIcon, LimitBuyIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import { Pill } from '@ui-kit/atoms/Pill';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import { Float, toNumber, chainOf } from '@utils';
import { networksByChain } from '@utils/networks';
import { formatNumber } from '@utils/numbers/NumberFormat';

export type stopLossOrderDetailsProps = {
  stoplossOrder: StopLossOrder;
  onGoBack?: VoidFunction;
};

export function StopLossDetailsUi({ stoplossOrder, onGoBack }: stopLossOrderDetailsProps) {
  const { t } = useTranslation();

  const input = {
    token: stoplossOrder.input.token,
    qty: stoplossOrder.input.qty,
    quote: stoplossOrder.input.token.quote,
    usdValue: stoplossOrder.input.qtyNum * stoplossOrder.input.token.quote,
    qtyNum: stoplossOrder.input.qtyNum,
  };
  const output = {
    token: stoplossOrder.output.token,
    qty: stoplossOrder.output.qty,
    quote: stoplossOrder.output.token.quote,
    usdValue: stoplossOrder.output.qtyNum * stoplossOrder.output.token.quote,
    qtyNum: stoplossOrder.output.qtyNum,
  };
  const network = networksByChain[chainOf(stoplossOrder.vault)];

  const limitPrice = input.qtyNum / output.qtyNum;

  const statusPill = (
    <Pill theme={{ type: 'default', variant: 'soft' }} label={t('Common.TransactionStatus.pending')} />
  );

  const type = {
    Icon: LimitBuyIcon,
    label: t('TransactionDetails.type.stopLoss'),
  };

  const fields = [
    {
      label: t('TransactionDetails.fields.type'),
      item: (
        <div className="flex gap-1 items-center text-font font-medium">
          <type.Icon className="fill-current w-3.5 h-3.5" />
          <span>{type.label}</span>
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.fromTo'),
      item: (
        <div className="flex gap-1 items-center">
          {input.token.symbol}
          <ArrowRightIcon className="w-3 h-3 text-font-variant" />
          {output.token.symbol}
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.inputQty'),
      item: (
        <>
          <Float.Custom
            key={input.token.symbol}
            value={toNumber(input.qty, input.token.decimals)}
            type="qty"
            unit={input.token.symbol}
            takeAbsoluteValue
          />
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.triggerPrice'),
      item: (
        <>
          {t('TransactionDetails.fields.limitPriceEntry', {
            price: formatNumber(stoplossOrder.triggerPrice, 'qty'),
            inputSymbol: input.token.symbol,
            outputSymbol: output.token.symbol,
          })}
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.limitPrice'),
      item: (
        <>
          {t('TransactionDetails.fields.limitPriceEntry', {
            price: formatNumber(limitPrice, 'qty'),
            inputSymbol: input.token.symbol,
            outputSymbol: output.token.symbol,
          })}
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.outputQty'),
      item: (
        <>
          <Float.Custom
            key={input.token.symbol}
            value={toNumber(output.qty, output.token.decimals)}
            type="qty"
            unit={output.token.symbol}
            takeAbsoluteValue
          />
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.massFees'),
      itemTooltipContent: t('TransactionDetails.fields.massFees'),
      item: <Pill label={t('TransactionDetails.free')} theme={{ type: 'accent', variant: 'heavy' }} />,
    },
    {
      label: t('TransactionDetails.fields.network'),
      item: <Pill LeadingIcon={network.MonochromeIcon} label={network.name} />,
    },
    { label: t('TransactionDetails.fields.transactionState'), item: statusPill },
  ].map(field => ({ ...field, className: 'py-3.5' }));

  const { config } = usePrepareSendTransaction(stoplossOrder.cancelTx as any);
  const { data, sendTransaction } = useSendTransaction(config);

  const { isLoading } = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <div className="h-full flex flex-col w-full gap-6 pb-8">
      <SideContentHeader title={t('TransactionDetails.title')} onGoBack={onGoBack} alignment="left" />
      <div className="overflow-y-auto hide-scrollbars flex flex-col gap-6 h-full">
        <LabelItemList
          contentClassname="!gap-0"
          labelItems={fields.map(field => ({ ...field, className: '!py-4 text-base' }))}
        />
        <Button
          label="Cancel Order"
          size="l"
          fullWidth
          variant="danger-muted"
          onClick={sendTransaction}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
