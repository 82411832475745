import { useCallback, useEffect, useMemo } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';
import { setRecoil } from 'recoil-nexus';

import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { ModalContentType, useModal } from '@components/modal/Modal';
import { Chain, GetUserBalances } from '@gql';
import { GET_USER_BALANCES } from '@queries/user.queries';
import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { TransactionFlowType } from '@transactions/types';
import { WalletIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import { Entry } from '@ui-kit/organisms/Entry';
import { Loader, NATIVE_TOKENS, chainOf, holdingQuote } from '@utils';
import { allNetworks } from '@utils/networks';
import { formatNumber } from '@utils/numbers/NumberFormat';
import { useWallet } from '@wallet';

import { useMainVaultSummary } from './useMainVault';

export const OnboardingAtom = atom<boolean>({
  default: false,
  key: 'OnboardingAtom',
});

function NetworkDeployEntry({
  balance,
  icon: Icon,
  onDeploy,
  networkName,
}: {
  networkName: string;
  balance?: number;
  icon: any;
  onDeploy: VoidFunction;
}) {
  return (
    <Entry isCard leadingItem={<Icon />}>
      <div className="flex flex-row !justify-between">
        <div className="flex flex-col gap-1">
          <div className="text-lg">{networkName}</div>
          <div className="text-sm text-font-variant flex flex-row items-center gap-1">
            <WalletIcon />
            {formatNumber(balance || 0, 'price')}
          </div>
        </div>
        <Button label="Deploy" onClick={onDeploy} />
      </div>
    </Entry>
  );
}

function OnBoardingModal({ onDeploy, modalProps }: { onDeploy: (chain: Chain) => void } & ModalContentType) {
  const { t } = useTranslation();
  const balances = Loader.query<GetUserBalances>(GET_USER_BALANCES)
    .map(({ myUser }) =>
      (myUser?.walletBalances ?? []).reduce((acc, x) => {
        if (!acc[chainOf(x.token.id)]) {
          acc[chainOf(x.token.id)] = 0;
        }
        acc[chainOf(x.token.id)]! += holdingQuote(x);
        return acc;
      }, {} as Partial<Record<Chain, number>>),
    )
    .unwrapOr({
      [Chain.arbi]: 0,
      [Chain.opti]: 0,
    } as Partial<Record<Chain, number>>);

  return (
    <>
      <SideContentHeader title="Welcome to Mass" />
      <p
        className="leading-8 list-disc list-inside"
        dangerouslySetInnerHTML={{ __html: t('OnBoardingModal.welcome', { interpolation: { escapeValue: false } }) }}
      />
      <div className="w-full flex flex-col gap-2 items-center">
        {allNetworks
          .filter(n => !n.disabled)
          .map(({ chain, Icon, name }) => (
            <NetworkDeployEntry
              key={chain}
              icon={Icon}
              balance={balances[chain]}
              onDeploy={() => {
                modalProps?.closeModal?.();
                onDeploy(chain);
              }}
              networkName={name}
            />
          ))}
        <span role="button" tabIndex={0} className="text-accent mt-3" onClick={modalProps?.closeModal}>
          {t('OnBoardingModal.skipForNow')}
        </span>
      </div>
    </>
  );
}

export const useHasDeployed = () => {
  return useMainVaultSummary()
    .noFlickering()
    .map(x => x.existsOn.length > 0)
    .unwrapOr(true);
};

export function useOnboarding(autoStart: boolean = false) {
  const { setCurrentChain, setCurrentFlow } = useTransactionFlow();
  const [onBoarding, setOnboarding] = useRecoilState(OnboardingAtom);

  const isAuthed = useWallet()
    .noFlickering()
    .map(x => x.isAuthed)
    .unwrapOr(false);
  const hasDeployed = useHasDeployed();

  const startDeployFlow = useCallback(
    (chain?: Chain) => {
      setOnboarding(true);
      if (chain) {
        setCurrentChain(chain);
      }
      flushSync(() => {
        setCurrentFlow(TransactionFlowType.DEPOSIT, {
          // purge prefill with default to reset it
          prefillInputTokenId: chain ? NATIVE_TOKENS[chain] || undefined : undefined,
        });
        setCurrentFlow(TransactionFlowType.DEPOSIT, {}, true);
      });

      if (window) {
        setTimeout(() => {
          window.onclick = () => {
            setRecoil(OnboardingAtom, false);
            window.onclick = null;
          };
        }, 200);
      }
    },
    [setCurrentChain, setCurrentFlow, setOnboarding],
  );

  const quitOnboarding = useCallback(() => {
    setOnboarding(false);
    window.onclick = null;
  }, [setOnboarding]);

  const content = useMemo(
    () => ({
      content: <OnBoardingModal onDeploy={startDeployFlow} />,
      containerClassName: '!w-[560px]',
      preventBackdropClose: true,
    }),
    [startDeployFlow],
  );
  const { open: openOnboardingModal } = useModal(content);

  useEffect(() => {
    if (autoStart && isAuthed && !hasDeployed) {
      openOnboardingModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed, hasDeployed, autoStart]);

  return {
    openOnboardingModal,
    startDeployFlow,
    quitOnboarding,
    isOnboarding: onBoarding,
  };
}
