import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { defaultTokens } from '@constants';
import { GetUserBalances, GetUserBalancesVariables } from '@gql';
import { useMainVault, useMainVaultSummary, refreshMainVaultDataAtom, refreshMainVaultData } from '@hooks/useMainVault';
import { getTokenInfo } from '@hooks/useTokensInfo';
import { useTxManagerAlerts } from '@hooks/useTxManagerAlerts';
import { GET_USER_BALANCES } from '@queries/user.queries';
import { useCreateOrCallVault } from '@tetris/tx';
import { useWithdraw } from '@tetris/withdraw';
import { SendTxButton } from '@transactions/components/SendTxButton';
import { TxSpeed } from '@transactions/components/TransactionSpeed';
import { useLimitOrdersList } from '@transactions/hooks/useLimitOrdersList';
import { Alert, AlertLink, IAlert } from '@ui-kit/atoms/Alert';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { TxType } from '@ui-kit/organisms/SingleTxReviewContent';
import {
  Loader,
  holdingQuote,
  chainOf,
  useTokenQuote,
  parseNum,
  TokenBudget,
  isTokenBudget,
  isCoin,
  NATIVE_TOKENS,
  convertAmt,
  ICoin,
  Budget,
  getTransactionDetailsUrl,
  makeChainAddress,
} from '@utils';
import { useWallet } from '@wallet-hooks';

import { WithdrawUI } from './WithdrawUI';
import { useChangeCoin } from '../../../hooks/useChangeCoin';
import { useHighestHolding } from '../../../hooks/useHighestHolding';
import { useTransactionFlow } from '../../../hooks/useTransactionFlow';
import { TransactionFlowType, WithdrawFlowProps } from '../../../types';

export const WithdrawFlow: React.FC<WithdrawFlowProps> = ({ prefillInputTokenId, prefillInputAmount }) => {
  const { t } = useTranslation();
  const { setCurrentFlow, currentChain, setCurrentChain, resetScroll } = useTransactionFlow();
  const [lastSourceBudget, setLastSourceBudget] = useState<Budget<ICoin> | null>(null);
  const refreshWhenChanges = useRecoilValue(refreshMainVaultDataAtom);
  const [alert, setAlert] = useState<IAlert | null>(null);
  const [warningAlert, setWarningAlert] = useState<IAlert | null>(null);
  const { lastTransaction } = useTxManagerAlerts();

  const wallet$ = useWallet(true);
  const vaultSummary$ = useMainVaultSummary();

  const limitOrders$ = useLimitOrdersList();

  const highestHolding$ = useHighestHolding(currentChain);
  // TODO(Yann) clean this code.
  // It's done like this to avoid an eslint warning (same on SwapFlow)
  // ticket: https://linear.app/mass/issue/MASS-938/impr-switch-network
  highestHolding$.onOk(holding => {
    (async () => {
      if (holding?.token.chain) {
        await setCurrentChain(holding?.token.chain);
      }
    })().catch(e => e);
  });

  const [inputTokenId, setInputTokenId] = useState<ChainAddress | undefined>(prefillInputTokenId);
  const [inputBudget$, setInputBudget$] = Loader.array([wallet$, highestHolding$, inputTokenId] as const)
    .map(([_wallet, _highestHolding, _inputTokenId]) =>
      getTokenInfo(
        _inputTokenId ||
          _highestHolding?.token?.id ||
          (currentChain ? NATIVE_TOKENS[currentChain] : defaultTokens[chainOf(_wallet.address)]),
      ),
    )
    .map(x => x || Loader.error('No token found'))
    .map<TokenBudget>(token => ({
    token,
    amtBase: lastSourceBudget
      ? convertAmt(lastSourceBudget.amtBase, lastSourceBudget.token.decimals, token.decimals)
      : prefillInputAmount || 0n,
  }))
    .asState<TokenBudget>();

  const isInputTokenUsedInLimitOrders$ = Loader.array([limitOrders$, inputBudget$] as const).map(
    ([_limitOrders, _inputBudget]) =>
      _limitOrders.limitOrders.results.some(limitOrder => _inputBudget.token.id === limitOrder.make.token.id),
  );

  inputBudget$.onOk(b => setLastSourceBudget(b));

  isInputTokenUsedInLimitOrders$.onOk(value => {
    if (value) {
      setWarningAlert({
        variant: 'warning' as const,
        title: t('Transactions.errors.limitOrderWarning.title'),
        content: t('Transactions.errors.limitOrderWarning.description', {
          token: inputBudget$.match.notOk(() => '').ok(budget => budget.token.symbol),
        }),
      });
    } else {
      setWarningAlert(null);
    }
  });

  const vaultHoldings$ = useMainVault(true, true)
    .map(mainVault => mainVault?.spot || [])
    .map(holdings => [...holdings].sort((a, b) => holdingQuote(b) - holdingQuote(a)));

  const inputToken$ = inputBudget$.map(x => x.token);
  const inputQuote$ = useTokenQuote(inputBudget$.map(x => x.token.id));

  const inputBudgetWithQuote$ = Loader.array([inputBudget$, inputQuote$] as const).map(([budget, quote]) => ({
    ...budget,
    quote,
  }));

  const vaultAddress$ = Loader.array([vaultSummary$, inputBudget$] as const).map(([_vault, _budget]) => {
    if (!isCoin(_budget.token)) {
      return Loader.error('Transactions.TransactionReview.withdraw.onlyTokens');
    }
    const chain = chainOf(_budget.token.id);
    if (!_vault.existsOn.includes(chain)) {
      return null;
    }
    return `${chain}:${_vault.address}` satisfies ChainAddress;
  });

  const chain$ = vaultAddress$.map(x => chainOf(x));

  const budgetHoldings$ = Loader.array([vaultHoldings$, inputToken$] as const).map(([_hds, _inputToken]) => {
    const tok = _hds.find(x => x.token.id === _inputToken.id);
    return tok
      ? {
        amtBase: parseNum(tok?.qty),
        quote: tok.token?.quote ?? 0,
        token: tok.token,
      }
      : null;
  });

  const balanceQty$ = inputToken$
    .query<GetUserBalances, GetUserBalancesVariables>([refreshWhenChanges], GET_USER_BALANCES, x => ({
    chain: chainOf(x.id),
    tokens: [x.id],
  }))
    .map(({ myUser }) => ({
      value: parseNum(myUser?.walletBalances[0]?.qty) ?? 0n,
      decimals: myUser?.walletBalances[0]?.token?.decimals ?? 0,
    }));

  const changeCoin = useChangeCoin(false, { onlyOwned: true });

  // // ==================================================
  // // =================== PREPARE TX ===================
  // // ==================================================
  const withdraw = useWithdraw(inputBudget$);

  const { sendTx, operation } = useCreateOrCallVault(chain$, [withdraw]);

  operation.onOk(() => {
    resetInputBudget();
  });

  operation.onError(error => {
    const sentTx = lastTransaction?.hash;

    const failedLinks: AlertLink[] = [];

    const sendReportLink: AlertLink = {
      label: t('TransactionDetails.error.sendReport'),
      onClick: () => Sentry.captureException(error),
    };

    failedLinks.push(sendReportLink);

    if (sentTx && currentChain) {
      const explorerLink: AlertLink = {
        label: t('Transactions.ToastActions.explorer'),
        isExternal: true,
        url: getTransactionDetailsUrl(makeChainAddress(currentChain, sentTx)),
      };
      failedLinks.push(explorerLink);
    }

    setAlert({
      variant: 'error',
      title: t('Transactions.errors.withdraw.title'),
      content: t('Transactions.errors.withdraw.description', {
        token: inputBudget$.match.notOk(() => '').ok(budget => budget.token.symbol),
      }),
      links: failedLinks,
    });
  });

  const resetInputBudget = inputBudget$.makeCallback(input => {
    if (input) {
      setInputBudget$({ ...input, amtBase: 0n });
      refreshMainVaultData();
    }
  });

  const onSwitchToDeposit = inputBudget$.match
    .notOk(() => undefined)
    .ok(
      _inputBudget => () =>
        setCurrentFlow(TransactionFlowType.DEPOSIT, {
          prefillInputTokenId: _inputBudget.token.id,
          prefillInputAmount: _inputBudget.amtBase,
        }),
    );

  const sendTxButton = Loader.array([
    inputBudgetWithQuote$,
    budgetHoldings$,
    sendTx.map(x => x.sendNext),
    isInputTokenUsedInLimitOrders$,
  ] as const)
    .noFlickering()
    .match.loadingOrSkipped(() => (
      <Button size="l" label={t('Transactions.Withdraw.confirmWithdraw')} fullWidth disabled />
    ))
    .error(() => <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />)
    .ok(([budget, bHol, _sendTx, _isInputTokenUsedInLimitOrders]) => {
      //
      if (alert)
        return (
          <Button
            size="l"
            label={t('Transactions.errors.withdraw.retry')}
            fullWidth
            onClick={async () => {
              setAlert(null);
              await _sendTx();
            }}
          />
        );

      const notEnoughBalance = !bHol?.amtBase || budget.amtBase > bHol.amtBase;

      if (notEnoughBalance) {
        return <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />;
      }

      const onContinue = () => {
        if (budget.token && budget.amtBase) {
          _sendTx();
        }
      };
      return (
        <SendTxButton
          sendTx={onContinue}
          isLoading={operation.isLoading}
          disabled={!budget.amtBase || !budget.token || notEnoughBalance}
          label={t('Transactions.Withdraw.confirmWithdraw')}
          dataCy="AmountScreen_cta"
          variant={_isInputTokenUsedInLimitOrders ? 'warning' : null}
          txData={{
            variant: TxType.withdraw,
            inputToken: budget,
            outputToken: budget,
            fees: { total: 0, paraswap: 0, nested: 0, network: 0 },
            txSpeed: TxSpeed.normal,
            onSelectTxSpeed: () => {},
            onOpenSlippageSettings: () => {},
          }}
        />
      );
    });

  const onSourceChange = (a: Budget<ICoin>, isMax?: boolean) => {
    if (isTokenBudget(a)) {
      setAlert(null);
      const maxAmt = budgetHoldings$.match.notOk(() => 0n).ok(h => h?.amtBase);

      setInputBudget$({ ...a, amtBase: (isMax && maxAmt) || a.amtBase });
    }
  };

  return (
    <>
      <WithdrawUI
        vaultAddress={vaultAddress$}
        balanceQty={balanceQty$}
        sourceBudget={inputBudgetWithQuote$}
        sourceBalance={budgetHoldings$.map(b => b?.amtBase || 0n)}
        onSourceChange={onSourceChange}
        onSourceCoinSelect={() => changeCoin(token => token && resetScroll() && setInputTokenId(token.id))}
        onSwitchToDeposit={onSwitchToDeposit}
        disabled={operation.isLoading}
        isInputTokenUsedInLimitOrders={isInputTokenUsedInLimitOrders$}
      />
      <Divider className="my-6" />
      {warningAlert && (
        <div className="relative bg-surface-muted flex flex-col gap-6 rounded-2xl py-6 px-4 overflow-hidden mb-6">
          <Alert {...warningAlert} className="!bg-transparent !p-0" />
        </div>
      )}
      {alert && (
        <div className="relative bg-surface-muted flex flex-col gap-6 rounded-2xl py-6 px-4 overflow-hidden mb-6">
          <Alert {...alert} className="!bg-transparent !p-0" />
        </div>
      )}
      {sendTxButton}
    </>
  );
};
