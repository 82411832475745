import { getAllLimitOrders } from '@gql';
import { GET_ALL_LIMIT_ORDERS } from '@queries/limit-orders.queries';
import { Loader } from '@utils';
import { useWallet } from '@wallet';

export const useLimitOrdersList = () => {
  const wallet$ = useWallet(false);

  const authed$ = wallet$.map(w => w.isAuthed);

  const isConnected = authed$
    .noFlickering()
    .match.notOk(() => false)
    .ok(authed => authed);

  const limitOrders$ = Loader.query<getAllLimitOrders>(GET_ALL_LIMIT_ORDERS, {
    skip: !isConnected,
    refetchWhenChanges: [isConnected],
  });

  return limitOrders$;
};
