import cls from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Expiration, ExpireSelect } from '@transactions/components/ExpireSelect';
import { TriggerPriceInputEntry, getValueVariation } from '@transactions/components/ui/TriggerPriceInputEntry';
import { RotateArrowIcon, DoubleArrowHorizontalIcon, ResetIcon } from '@ui-kit/Icons';
import { BudgetWithQuote, ICoin, Loader, Loadable } from '@utils';
import { DivWithTooltip } from '@utils/DivWithTooltip';
import { formatNumber } from '@utils/numbers/NumberFormat';

import { StopLossConditionReducer } from './stopLossConditions.reducer';

const MAX_LIMIT_TO_TRIGGER_RATIO = 0.997; // 0.3%
const VARIATION_TOLERANCE = 0.001;

export type StopLossConditionsProps = {
  inputBudget: Loadable<BudgetWithQuote<ICoin>>;
  outputBudget: Loadable<BudgetWithQuote<ICoin>>;
  marketPrice: Loadable<number>;
  onTriggerPriceChange: (v: number) => void;
  onLimitPriceChange: (v: number) => void;
  onExpireChange: (e: Expiration) => void;
  onError: (e?: string) => void;
};

function StopLossConditions({
  inputBudget,
  outputBudget,
  marketPrice,
  onTriggerPriceChange,
  onLimitPriceChange,
  onExpireChange,
  onError,
}: StopLossConditionsProps) {
  const { t } = useTranslation();

  const inputBudget$ = Loader.useWrap(inputBudget);
  const outputBudget$ = Loader.useWrap(outputBudget);
  const marketPrice$ = Loader.useWrap(marketPrice);

  const [state$, dispatch] = Loader.array([inputBudget$, outputBudget$, marketPrice$] as const)
    .map(([_inputBudget, _outputBudget, _marketPrice]) => ({
      marketPrice: _marketPrice,
      triggerPrice: _marketPrice,
      triggerPriceStr: formatNumber(_marketPrice, 'qty'),
      limitPrice: _marketPrice * MAX_LIMIT_TO_TRIGGER_RATIO,
      limitPriceStr: formatNumber(_marketPrice * MAX_LIMIT_TO_TRIGGER_RATIO, 'qty'),
      expire: Expiration.Never,
      triggerVariation: getValueVariation(_marketPrice, _marketPrice, VARIATION_TOLERANCE),
      limitVariation: getValueVariation(_marketPrice * MAX_LIMIT_TO_TRIGGER_RATIO, _marketPrice, VARIATION_TOLERANCE),
      unit: {
        in: _inputBudget?.token.symbol,
        out: _outputBudget?.token.symbol,
      },
      isInverted: false,
      bindValues: true,
    }))
    .asReducer(StopLossConditionReducer);

  state$.map(s => s.triggerPrice).onOk(p => onTriggerPriceChange(p));
  state$.map(s => s.limitPrice).onOk(p => onLimitPriceChange(p));
  state$.map(s => s.expire).onOk(e => onExpireChange(e));

  const triggerVariation = state$
    .map(s => getValueVariation(s.triggerPrice, s.marketPrice, VARIATION_TOLERANCE))
    .unwrapOr(getValueVariation(0, 0, VARIATION_TOLERANCE));

  const limitVariation = state$
    .map(s => getValueVariation(s.limitPrice, s.marketPrice, VARIATION_TOLERANCE))
    .unwrapOr(getValueVariation(0, 0, VARIATION_TOLERANCE));

  const disableTrigger = Loader.array([inputBudget$, outputBudget$])
    .map(([inp, out]) => !inp || !out)
    .unwrapOr(true);

  const unit = state$.map(s => `${s.unit.out} / ${s.unit.in}`).unwrapOr(undefined);

  const triggerPriceError$ = state$.map(s =>
    s.triggerPrice > s.marketPrice ? t('Transactions.StopLoss.errors.triggerOverMarket') : undefined,
  );
  const limitPriceError$ = state$.map(s => {
    if (s.limitPrice <= s.triggerPrice * MAX_LIMIT_TO_TRIGGER_RATIO) {
      return undefined;
    }
    return s.isInverted
      ? t('Transactions.StopLoss.errors.invertedLimitOverTrigger', {
        amount: formatNumber(1 / (s.triggerPrice * MAX_LIMIT_TO_TRIGGER_RATIO), 'qty'),
        unit,
        interpolation: { escapeValue: false },
      })
      : t('Transactions.StopLoss.errors.limitOverTrigger', {
        amount: formatNumber(s.triggerPrice * MAX_LIMIT_TO_TRIGGER_RATIO, 'qty'),
        unit,
        interpolation: { escapeValue: false },
      });
  });

  Loader.array([triggerPriceError$, limitPriceError$] as const).onOk(([triggerPriceError, limitPriceError]) => {
    onError(triggerPriceError || limitPriceError);
  });

  const triggerPriceError = triggerPriceError$.unwrapOr(undefined);
  const limitPriceError = limitPriceError$.unwrapOr(undefined);
  const isSynced = state$.map(s => s.bindValues).unwrapOr(false);
  console.log(isSynced);
  const triggerPriceActions = useMemo(
    () => [
      <DivWithTooltip
        as="span"
        onClick={() => dispatch({ type: 'SET_TRIGGER_TO_MARKET' })}
        key="setToMarket"
        tooltipContent={t('Transactions.StopLoss.setToMarket')}
        placement="top"
        tooltipClassName="!bg-background-variant"
      >
        {!disableTrigger && <ResetIcon className="h-3.5 w-3.5 my-auto text-accent" />}
      </DivWithTooltip>,
      <DivWithTooltip
        as="span"
        onClick={() => dispatch({ type: 'SWAP_UNIT' })}
        key="switch"
        tooltipContent={t('Transactions.StopLoss.switchRate')}
      >
        {!disableTrigger && <DoubleArrowHorizontalIcon className="h-3.5 w-3.5 my-auto text-accent" />}
      </DivWithTooltip>,
    ],
    [disableTrigger, dispatch, t],
  );

  const limitPriceActions = useMemo(
    () => [
      <DivWithTooltip
        as="span"
        onClick={() => dispatch({ type: 'TOGGLE_BIND_VALUES' })}
        key="setToMarket"
        tooltipContent={isSynced ? t('Transactions.StopLoss.syncedPrice') : t('Transactions.StopLoss.syncPrice')}
        placement="top"
      >
        {!disableTrigger && (
          <RotateArrowIcon className={cls('h-3.5 w-3.5 my-auto', isSynced ? 'text-font-disabled' : 'text-accent')} />
        )}
      </DivWithTooltip>,
      <DivWithTooltip
        as="span"
        onClick={() => dispatch({ type: 'SWAP_UNIT' })}
        key="switch"
        tooltipContent={t('Transactions.StopLoss.switchRate')}
      >
        {!disableTrigger && <DoubleArrowHorizontalIcon className="h-3.5 w-3.5 my-auto text-accent" />}
      </DivWithTooltip>,
    ],
    [disableTrigger, dispatch, t, isSynced],
  );

  return (
    <>
      <TriggerPriceInputEntry
        disabled={disableTrigger}
        valueVariation={triggerVariation}
        value={state$.map(s => s.triggerPriceStr).unwrapOr(t('Transactions.StopLoss.selectOutputFirst'))}
        resetInput={() => {}}
        onChange={p => dispatch({ type: 'SET_TRIGGER_PRICE', payload: p })}
        error={triggerPriceError}
        label="Trigger Price"
        inputTextClass="text-font"
        unit={unit}
        actions={triggerPriceActions}
        tooltipContent={t('Transactions.StopLoss.triggerPriceTooltip')}
      />
      <div className="flex flex-row gap-1">
        <TriggerPriceInputEntry
          disabled={disableTrigger}
          valueVariation={limitVariation}
          value={state$.map(s => s.limitPriceStr).unwrapOr(t('Transactions.StopLoss.selectOutputFirst'))}
          resetInput={() => {}}
          onChange={p => dispatch({ type: 'SET_LIMIT_PRICE', payload: p })}
          error={limitPriceError}
          label="Limit Price"
          inputTextClass="text-font"
          unit={unit}
          actions={limitPriceActions}
          className="flex-[2]"
          tooltipContent={t('Transactions.StopLoss.limitPriceTooltip')}
        />
        <ExpireSelect
          className="flex-[1] border-red"
          expiration={state$.map(s => s.expire).unwrapOr(Expiration.Never)}
          onExpireChange={e => dispatch({ type: 'SET_EXPIRE', payload: e })}
        />
      </div>
    </>
  );
}

export default StopLossConditions;
