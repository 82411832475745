import cls from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { EntryList, EntryVariant } from '@components/entries/EntryList';
import { useClickOutside } from '@hooks/useClickOutside';
import { EraseIcon, SearchIcon } from '@ui-kit/Icons';
import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry, EntryType } from '@ui-kit/organisms/Entry';
import { ITextInput, TextInput, TextInputSize } from '@ui-kit/organisms/TextInput';
import { Cls } from '@utils';
import { Loadable, Loader } from '@utils/loader';

export type ISearchInput = {
  showResetButton?: boolean;
  showDropdownResults?: boolean;
  textInputClassName?: string;
  onChange?: (v: string) => void;
  trendingResults?: {
    header: ReactNode;
    list: Loadable<{ render: ReactNode; onClick?: VoidFunction }[] | nil>;
  };
  results?: {
    header?: ReactNode;
    footer?: ReactNode;
    list: Loadable<{ render: ReactNode; onClick?: VoidFunction }[] | nil>;
  };
  isFullWidth?: boolean;
  listClassName?: string;
  itemClassName?: string;
  listVariant?: EntryVariant;
  TrailingVisual?: { button: ReactNode; hasDivider?: boolean };
  dropdownClassName?: string;
  isDropdownOpen?: boolean;
  displayedLists?: 'both' | 'results' | 'trending';
  header?: ReactNode;
  onClose?: VoidFunction;
} & Pick<ITextInput, 'placeholder' | 'value' | 'size'> &
Cls;

// TODO (Jerem): to be refactored
export const SearchInput = ({
  placeholder,
  value,
  size = TextInputSize.M,
  showResetButton,
  results,
  trendingResults,
  className,
  onChange,
  showDropdownResults = false,
  isFullWidth = false,
  TrailingVisual,
  textInputClassName,
  listClassName,
  listVariant = 'menu',
  itemClassName,
  dropdownClassName,
  isDropdownOpen = false,
  displayedLists = 'both',
  header,
  onClose,
}: ISearchInput) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const refDropdown = useRef(null);
  const [outsideClicked, setOutsideClicked] = useState(false);
  const [insideDropdownClicked, setInsideDropdownClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const trendingResults$ = Loader.wrap(trendingResults?.list);
  const searchEntries$ = Loader.wrap(results?.list);

  // TODO (Jerem): refacto
  useEffect(() => {
    if (outsideClicked && onClose) {
      onClose();
    }
  }, [onClose, outsideClicked]);

  useClickOutside(ref, () => setOutsideClicked(true));
  useClickOutside(refDropdown, () => setInsideDropdownClicked(true));

  enum EntryStatus {
    Loading = 'loading',
    Error = 'error',
    NoResults = 'ok',
  }

  const statusEntry = (entryStatus: EntryStatus) => {
    const label = match(entryStatus)
      .with(EntryStatus.Loading, () => t('UIKit.TokenSearchInput.loading'))
      .with(EntryStatus.Error, () => t('UIKit.TokenSearchInput.error'))
      .with(EntryStatus.NoResults, () => t('UIKit.TokenSearchInput.noResult'))
      .exhaustive();

    return {
      id: '-',
      noMinHeight: true,
      disabled: true,
      content: {
        top: <span className="text-base text-font-variant">{label}</span>,
      },
    };
  };

  const trendingTokens: EntryType[] = trendingResults$.match
    .loadingOrSkipped(() => loader)
    .error(() => [statusEntry(EntryStatus.Error)])
    .ok(x => {
      if (!showDropdownResults) return [];
      const hasResults = !!x?.length;
      return hasResults
        ? x.map(({ render, onClick }, i) => ({
          id: `${i}`,
          noMinHeight: true,
          content: {
            top: render,
          },
          className: itemClassName,
          onClick,
        }))
        : [statusEntry(EntryStatus.NoResults)];
    });

  const entries: EntryType[] = searchEntries$.match
    .loadingOrSkipped(() => loader)
    .error(() => [statusEntry(EntryStatus.Error)])
    .ok(x => {
      if (!showDropdownResults) return [];
      const hasResults = !!x?.length;
      return hasResults
        ? x.map(({ render, onClick }, i) => ({
          id: `${i}`,
          noMinHeight: true,
          content: {
            top: render,
          },
          className: itemClassName,
          onClick,
        }))
        : [statusEntry(EntryStatus.NoResults)];
    });

  const showSearchResults =
    showDropdownResults && value && (!outsideClicked || isFocused) && displayedLists !== 'trending';
  const showTrendings =
    trendingResults &&
    (isFocused || isDropdownOpen || (insideDropdownClicked && !outsideClicked)) &&
    displayedLists !== 'results';

  return (
    <div
      ref={ref}
      className={cls('relative', isFullWidth && '!w-full', className)}
      onWheel={e => {
        e.stopPropagation();
      }}
    >
      <TextInput
        LeadingVisual={<SearchIcon className={cls('text-font flex-shrink-0', TextInputSize._2XL && 'w-8 h-8')} />}
        containerClassName={cls(isFullWidth && '!w-full')}
        TrailingVisual={showResetButton ? { Icon: EraseIcon } : TrailingVisual}
        trailingVisualClassName="text-font-variant flex-shrink-0"
        onTrailingVisualClick={() => onChange?.('')}
        trailingVisualContainerClassName="!right-0"
        className={textInputClassName}
        value={value}
        autoFocus
        onChange={e => {
          setOutsideClicked(false);
          onChange?.(e.target.value);
        }}
        placeholder={placeholder || t('UIKit.SearchInput.placeholder')}
        size={size}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <div
        ref={refDropdown}
        className={cls(
          'absolute z-20 top-[calc(100%+16px)] w-full min-h-[48px] flex flex-col gap-7',
          dropdownClassName,
        )}
      >
        {header}
        <div className="overflow-y-auto hide-scrollbars">
          {showSearchResults && (
            <EntryList<EntryType>
              variant={listVariant}
              render={Entry}
              entries={entries}
              header={results?.header}
              footer={results?.footer}
              noMaxWidth
              className={cls(showSearchResults && showTrendings ? 'rounded-b-none' : undefined, listClassName)}
            />
          )}
          {showSearchResults && showTrendings && <Divider />}
          {showTrendings && (
            <EntryList<EntryType>
              className={cls(showSearchResults && showTrendings ? 'rounded-t-none pt-10' : undefined, listClassName)}
              variant={listVariant}
              render={Entry}
              entries={trendingTokens}
              header={trendingResults?.header}
              noMaxWidth
            />
          )}
        </div>
      </div>
    </div>
  );
};

// TODO (Jerem): move to the same folder as SearchRow
function SearchRowLoader() {
  return (
    <div className="grid grid-cols-search items-center w-full">
      <div className="flex items-center gap-2">
        <Skeleton className="h-6 w-6 rounded-full" />
        <Skeleton className="h-5 w-16" />
      </div>
      <div className="flex items-center gap-1">
        <Skeleton className="h-[18px] w-[18px] rounded-full" />
        <Skeleton className="h-[18px] w-[18px] rounded-full" />
        <Skeleton className="h-[18px] w-[18px] rounded-full" />
      </div>
      <Skeleton className="h-5 w-16" />
      <Skeleton className="h-5 w-16" />
      <Skeleton className="h-5 w-16" />
      <Skeleton className="h-5 w-16" />
    </div>
  );
}

const loader = [...Array(10).keys()].map(key => ({
  id: key.toString(),
  noMinHeight: true,
  disabled: true,
  className: 'rounded bg-surface-variant-disabled h-12',
  children: <SearchRowLoader />,
}));
