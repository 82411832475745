import { useTranslation } from 'react-i18next';

import { Dropdown, DropdownEntryType } from '@components/Dropdown';
import { NetworkSelector } from '@components/NetworkSelector';
import { makeCenterSideContent } from '@components/SideContent';
import { Chain } from '@gql';
import { Features, isFeatureActive } from '@hooks/useFeatureFlags';
import { useHasDeployed, useOnboarding } from '@hooks/useOnboarding';
import {
  BridgeIcon,
  SettingsIcon,
  SwapCoinIcon,
  DepositIcon,
  WithdrawIcon,
  LimitSellIcon,
  RotateArrowIcon,
  LimitBuyIcon,
} from '@ui-kit/Icons';
import { Logo } from '@ui-kit/atoms/Logo';
import { EntryType } from '@ui-kit/organisms/Entry';
import { SVGIcon } from '@utils';

import { TransactionFlowProps, TransactionFlowType } from '../../types';
import { SettingsContainer } from '../SettingsContainer';

export function TransactionsHeader({
  currentFlow,
  setCurrentFlow,
  currentChain,
  setCurrentChain,
  hideNetwork = false,
}: {
  currentFlow: TransactionFlowType | nil;
  currentChain?: Chain | nil;
  setCurrentChain: (chain?: Chain) => void;
  setCurrentFlow: <T extends TransactionFlowType>(type: T, _props?: TransactionFlowProps[T]) => void;
  hideNetwork?: boolean;
}) {
  const { t } = useTranslation();
  const hasDeployed = useHasDeployed();
  const { isOnboarding } = useOnboarding();

  const onSelectEntries = (entry: EntryType[]) => {
    switch (entry[0].id) {
      case TransactionFlowType.SWAP:
        return setCurrentFlow(entry[0].id as TransactionFlowType.SWAP);
      case TransactionFlowType.DEPOSIT:
        return setCurrentFlow(entry[0].id as TransactionFlowType.DEPOSIT);
      case TransactionFlowType.WITHDRAW:
        return setCurrentFlow(entry[0].id as TransactionFlowType.WITHDRAW);
      case TransactionFlowType.BRIDGE:
        return setCurrentFlow(entry[0].id as TransactionFlowType.BRIDGE);
      case TransactionFlowType.MULTIBUY:
        return setCurrentFlow(entry[0].id as TransactionFlowType.MULTIBUY);
      case TransactionFlowType.MULTISELL:
        return setCurrentFlow(entry[0].id as TransactionFlowType.MULTISELL);
      case TransactionFlowType.DCA:
        if (isFeatureActive(Features.dca)) return setCurrentFlow(entry[0].id as TransactionFlowType.MULTISELL);
        return setCurrentFlow(entry[0].id as TransactionFlowType.SWAP);
      case TransactionFlowType.STOP_LOSS:
        return setCurrentFlow(entry[0].id as TransactionFlowType.STOP_LOSS);
      default:
        return setCurrentFlow(entry[0].id as TransactionFlowType);
    }
  };

  const dropdownContent: DropdownEntryType[] = [
    {
      id: TransactionFlowType.SWAP,
      selectLabel: t('MyAccount.wallet.actions.swap'),
      leadingItemSelected: <SwapCoinIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={SwapCoinIcon}
            label={t('MyAccount.wallet.actions.swap')}
            description={t('MyAccount.wallet.descriptions.swap')}
          />
        ),
      },
      wrapContent: true,
    },
    {
      id: TransactionFlowType.DEPOSIT,
      selectLabel: hasDeployed ? t('MyAccount.wallet.actions.deposit') : t('MyAccount.wallet.actions.deploy'),
      leadingItemSelected: <DepositIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={DepositIcon}
            label={hasDeployed ? t('MyAccount.wallet.actions.deposit') : t('MyAccount.wallet.actions.deploy')}
            description={t('MyAccount.wallet.descriptions.deposit')}
          />
        ),
      },
      wrapContent: true,
    },
    {
      id: TransactionFlowType.WITHDRAW,
      selectLabel: t('MyAccount.wallet.actions.withdraw'),
      leadingItemSelected: <WithdrawIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={WithdrawIcon}
            label={t('MyAccount.wallet.actions.withdraw')}
            description={t('MyAccount.wallet.descriptions.withdraw')}
          />
        ),
      },
      wrapContent: true,
    },
    {
      id: TransactionFlowType.LIMIT,
      selectLabel: t('MyAccount.wallet.actions.limit'),
      leadingItemSelected: <LimitSellIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={LimitSellIcon}
            label={t('MyAccount.wallet.actions.limit')}
            description={t('MyAccount.wallet.descriptions.limit')}
          />
        ),
      },
      wrapContent: true,
    },
    isFeatureActive(Features.stoploss) && {
      id: TransactionFlowType.STOP_LOSS,
      selectLabel: t('MyAccount.wallet.actions.stopLoss'),
      leadingItemSelected: <LimitBuyIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={LimitBuyIcon}
            label={t('MyAccount.wallet.actions.stopLoss')}
            description={t('MyAccount.wallet.descriptions.stopLoss')}
          />
        ),
      },
      wrapContent: true,
    },
    {
      id: TransactionFlowType.MULTIBUY,
      selectLabel: t('MyAccount.wallet.actions.multibuy'),
      leadingItemSelected: <SwapCoinIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={SwapCoinIcon}
            label={t('MyAccount.wallet.actions.multibuy')}
            description={t('MyAccount.wallet.descriptions.multibuy')}
          />
        ),
      },
      wrapContent: true,
    },
    {
      id: TransactionFlowType.MULTISELL,
      selectLabel: t('MyAccount.wallet.actions.multisell'),
      leadingItemSelected: <SwapCoinIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={SwapCoinIcon}
            label={t('MyAccount.wallet.actions.multisell')}
            description={t('MyAccount.wallet.descriptions.multisell')}
          />
        ),
      },
      wrapContent: true,
    },
    {
      id: TransactionFlowType.BRIDGE,
      selectLabel: t('MyAccount.wallet.actions.bridge'),
      leadingItemSelected: <BridgeIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={BridgeIcon}
            label={t('MyAccount.wallet.actions.bridge')}
            description={t('MyAccount.wallet.descriptions.bridge')}
          />
        ),
      },
      wrapContent: true,
    },
    isFeatureActive(Features.dca) && {
      id: TransactionFlowType.DCA,
      selectLabel: t('MyAccount.wallet.actions.dca'),
      leadingItemSelected: <RotateArrowIcon className="h-4 w-4" />,
      content: {
        top: (
          <TransactionMenuItem
            icon={RotateArrowIcon}
            label={t('MyAccount.wallet.actions.dca')}
            description={t('MyAccount.wallet.descriptions.dca')}
          />
        ),
      },
      wrapContent: true,
    },
  ].filter(Boolean) as DropdownEntryType[];

  return (
    <div className="flex justify-between mb-6 shrink-0">
      {currentFlow && (
        <>
          <div className="flex gap-2">
            <Dropdown
              id="transaction-flow-selector"
              entries={dropdownContent}
              defaultSelectedEntriesIds={[currentFlow]}
              onSelectEntries={onSelectEntries}
              isPortal
              isDisabled={isOnboarding}
            />
            {!hideNetwork && <NetworkSelector network={currentChain} onSelectNetwork={setCurrentChain} isPortal />}
          </div>
          <div
            className="flex items-center justify-center bg-surface-muted rounded-full w-8 cursor-pointer"
            onClick={async () => makeCenterSideContent(SettingsContainer).open()}
          >
            <Logo src={SettingsIcon} />
          </div>
        </>
      )}
    </div>
  );
}

function TransactionMenuItem({ icon, label, description }: { icon: SVGIcon; label: string; description: string }) {
  return (
    <div className="flex flex-col gap-4 w-60">
      <div className="flex gap-1">
        <Logo src={icon} />
        <div className="text-base font-medium text-white">{label}</div>
      </div>
      <div className="text-base font-medium text-font-variant">{description}</div>
    </div>
  );
}
