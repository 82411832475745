import cls from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { formatInput } from '@components/bigInput/bigInputUtils';
import { DoubleArrowHorizontalIcon, ResetIcon } from '@ui-kit/Icons';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Tooltip } from '@ui-kit/molecules/Tooltip';
import { Entry } from '@ui-kit/organisms/Entry';
import { Loadable, Loader, TokenBudget } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

export interface TriggerPriceInputProps {
  input: Loadable<TokenBudget | nil>;
  output: Loadable<TokenBudget | nil>;
  ioPrice: Loadable<number>; // input quote / ouptut quote
  setTargetPrice: Dispatch<SetStateAction<number>>;
  triggerPriceError: string;
  setTriggerPriceError: Dispatch<SetStateAction<string>>;
  isLoading?: boolean;
}

const MARGIN_PERCENTAGE = 0.001;

export function TriggerPriceInput({
  input,
  output,
  ioPrice,
  setTargetPrice,
  triggerPriceError,
  setTriggerPriceError,
  isLoading,
}: TriggerPriceInputProps) {
  const { t } = useTranslation();
  const [unitInputPerOutput, setUnitInputPerOutput] = useState(false);
  const [strValue, setValueStr] = useState(t('Screens.LimitBuySell.selectOutputFirst'));

  const input$ = Loader.useWrap(input);
  const ioPrice$ = Loader.useWrap(ioPrice);
  const output$ = Loader.useWrap(output);

  const updateTriggerPrice = (ioP: number) => {
    if (!ioP) {
      setOutput(0);
      setValueStr(t('Screens.LimitBuySell.selectOutputFirst'));
      return;
    }
    const newIoPrice = unitInputPerOutput ? 1 / ioP : ioP;
    setOutput(newIoPrice);
    setValueStr(formatNumber(newIoPrice, 'qty'));
  };

  ioPrice$
    .map(x => x)
    .onOk(x => {
      updateTriggerPrice(x);
    });

  const setOutput = (newval: number) => {
    if (!newval) {
      setTargetPrice(() => 0);
      return;
    }
    const newOutput = unitInputPerOutput ? 1 / newval : newval;
    setTargetPrice(() => newOutput);
  };

  const setInvalid = (message: string | null) => {
    setTriggerPriceError(message || '');
  };

  const resetInput = () => {
    setOutput(0);
    setInvalid(null);
  };

  const symbols = Loader.array([input$, output$] as const)
    .map(([inp, out]) => {
      return {
        output: out?.token?.symbol,
        input: inp?.token?.symbol,
      };
    })
    .match.notOk(() => null)
    .ok(x => x);

  const hasInputAndOutput = !!(symbols && symbols.input && symbols.output);

  const setToMarket = Loader.array([ioPrice$, unitInputPerOutput] as const).makeCallback([ioPrice], ([_ioPrice]) => {
    if (!_ioPrice) return;
    updateTriggerPrice(_ioPrice);
    setInvalid(null);
  });

  const valueVariation = Loader.array([ioPrice$, strValue] as const)
    .match.notOk(() => {
      return {
        value: '',
        class: 'text-font-variant-disabled',
        textClass: 'text-font-variant-disabled',
      };
    })
    .ok(([_ioPrice]) => {
      if (!_ioPrice)
        return {
          value: '(0.00%)',
          class: 'text-font-variant-disabled',
          textClass: 'text-font-variant-disabled',
        };
      const triggerPriceNumber = parseFloat(strValue.replace(/,/g, ''));
      const triggerPriceToConsider = unitInputPerOutput ? 1 / triggerPriceNumber : triggerPriceNumber;
      const variationPercentage = triggerPriceToConsider / _ioPrice - 1;

      return match(variationPercentage)
        .when(
          x => x > 0 + MARGIN_PERCENTAGE,
          () => ({
            value: `+${formatNumber(variationPercentage * 100, 'percentage')}`,
            class: 'text-success',
            textClass: 'text-font-variant',
          }),
        )
        .when(
          x => x < 0 - MARGIN_PERCENTAGE,
          () => ({
            value: `${formatNumber(variationPercentage * 100, 'percentage')}`,
            class: 'text-danger',
            textClass: 'text-font-variant',
          }),
        )
        .otherwise(() => ({
          value: '±0.00%',
          class: 'text-font-variant',
          textClass: 'text-font-variant',
        }));
    });

  const triggerIconClasses = ioPrice$.match
    .notOk(() => 'text-font-disabled')
    .ok(_ioPrice => {
      if (!_ioPrice) return 'text-font-disabled';
      return 'text-accent';
    });

  const inputTextClass = ioPrice$.match
    .notOk(() => 'text-font-disabled text-sm')
    .ok(_ioPrice => {
      if (!_ioPrice) return 'text-font-disabled text-sm';
      return 'text-font text-[18px] leading-[26px]';
    });

  const onSwitch = () => {
    setUnitInputPerOutput(prev => !prev);
    const triggerPriceNumber = parseFloat(strValue.replace(/,/g, ''));
    const newTriggerPrice = 1 / triggerPriceNumber;
    setValueStr(formatNumber(newTriggerPrice, 'qty'));
  };

  // when the text field changes, then we must update the output
  const checkForm = ioPrice$.makeCallback([strValue], _ioPrice => {
    if (!strValue) {
      resetInput();
      return;
    }

    const triggerPriceNumber = parseFloat(strValue.replace(/,/g, ''));

    if (!Number.isFinite(triggerPriceNumber) || triggerPriceNumber <= 0 || _ioPrice <= 0) {
      setInvalid(t('Screens.LimitBuySell.invalidPrice'));
      return;
    }

    setOutput(triggerPriceNumber);

    const triggerPriceToConsider = unitInputPerOutput ? 1 / triggerPriceNumber : triggerPriceNumber;
    const variationPercentage = triggerPriceToConsider / _ioPrice - 1;

    if (variationPercentage < 0 - MARGIN_PERCENTAGE) {
      setInvalid(t('Screens.LimitBuySell.priceUnderMarket'));
      return;
    }

    setInvalid(null);
  });

  const TriggerPriceInputEntry = (
    <Entry
      isCard
      isActive={false}
      disabled={!hasInputAndOutput}
      className={cls(triggerPriceError ? 'border-danger' : '!border-none', 'border h-full overflow-visible')}
      content={{
        className: 'gap-2',
        top: (
          <div className="flex justify-between text-sm font-medium select-none">
            <div className="gap-1 flex">
              <span className={valueVariation?.textClass}>{t('Screens.LimitBuySell.limitPrice')}</span>
              <span className={valueVariation?.class}>{valueVariation?.value}</span>
            </div>
            <div className="flex gap-2">
              <Tooltip
                tooltipContent={
                  <div className="py-1 px-2 rounded gap-2 bg-surface-elevated-hover-font">
                    {t('Screens.LimitBuySell.setToMarket')}
                  </div>
                }
                isAlert={false}
              >
                <ResetIcon className={cls(triggerIconClasses, 'h-3.5 w-3.5')} onClick={setToMarket} />
              </Tooltip>

              <Tooltip
                tooltipContent={
                  <div className="py-1 px-2 rounded gap-2 bg-surface-elevated-hover-font">
                    {t('Screens.LimitBuySell.switchRate')}
                  </div>
                }
                isAlert={false}
              >
                <DoubleArrowHorizontalIcon className={cls(triggerIconClasses, 'h-3.5 w-3.5')} onClick={onSwitch} />
              </Tooltip>
            </div>
          </div>
        ),
        bottom: (
          <div className="relative w-full font-youth-medium flex" data-cy="TriggerPriceInput">
            <input
              value={strValue}
              disabled={!hasInputAndOutput || isLoading}
              placeholder="0"
              className={cls(
                inputTextClass,
                'cursor-caret',
                triggerPriceError ? 'caret-danger' : 'caret-accent',
                'placeholder:text-font-variant',
                'outline-none focus:outline-none bg-transparent',
                'w-full my-auto',
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const text = e.target.value;
                const formattedText = formatInput(text);
                setValueStr(formattedText);
                if (!formattedText) {
                  resetInput();
                }
              }}
              onBlur={() => checkForm()}
            />
            <div className="flex gap-1 my-auto ml-1 select-none" onClick={onSwitch}>
              <span className="font-youth-medium font-medium text-sm text-font">
                {hasInputAndOutput && unitInputPerOutput && `${symbols.input} ${t('Common.per')} ${symbols.output}`}
                {hasInputAndOutput && !unitInputPerOutput && `${symbols.output} ${t('Common.per')} ${symbols.input}`}
              </span>
            </div>
          </div>
        ),
      }}
    />
  );

  return (
    Loader.array([input$, output$] as const)
      // TODO HADRIEN fix skeleton not workng if z-index not setted
      .match.notOk(status => (
        <Skeleton noAnimation={status === 'error'} className="z-0 !min-w-0 w-full !block">
          {TriggerPriceInputEntry}
        </Skeleton>
      ))
      .ok(() =>
        isLoading ? (
          <Skeleton className="z-0 !min-w-0 w-full !block">{TriggerPriceInputEntry}</Skeleton>
        ) : (
          TriggerPriceInputEntry
        ),
      )
  );
}
